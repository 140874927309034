import React from 'react';
import { connect } from 'react-redux';

//Actions
import { tokenValidate } from './actions/tokenValidate';

//Components
import { authorizations } from './authorizations';
import Login from '../../components/Login/index';
import history from '../../history';

export default function Authorize(ComposedComponent, requiredAuth) {
    class BaseAuthorizedComponent extends React.Component {
        constructor(props) {
            super(props);
            this.tokenCheck = this.tokenCheck.bind(this);
        }

        tokenCheck() {
            this.props.tokenVerify(this.props.token);
        }

        componentDidMount() {
            this.tokenCheck();
        }

        render() {
            const { token, userInfo } = this.props;
            const componentId = this.props.match.params.componentId ? this.props.match.params.componentId : 'home';
            let isAuthorized = null;
            if(userInfo.authorizations && 
                authorizations[componentId] &&
                (authorizations[componentId].policy === '' ||
                authorizations[componentId].policy === 'OR')) {
                isAuthorized = authorizations[componentId].auth.some(
                    x => {
                        return userInfo.authorizations.includes(x);
                    });
            } else if(userInfo.authorizations && 
                authorizations[componentId] &&
                authorizations[componentId].policy === 'AND') {
                isAuthorized = authorizations[componentId].auth.every(
                    x => {
                        return userInfo.authorizations.includes(x);
                    });
            }
            if(token !== '' && userInfo !== {} && !authorizations[componentId]) {
                history.push('/home');
            }
            if(token === '' || userInfo === {}) {
                return <Login />;
            }
            return !isAuthorized ? 
                null :
                <ComposedComponent {...this.props} />;
        }
    }

    const mapStateToProps = state => {
        return {
            token: state.auth.token,
            userInfo: state.auth.userInfo,
            authorizations: state.auth.authorizations
        }
    }

    const mapDispatchToProps = dispatch => {
        const tokenVerify = token => {
            dispatch(tokenValidate(token));
        };

        return {
            tokenVerify
        }
    }

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(BaseAuthorizedComponent);
}