import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import { errorHandling } from '../../../../../utils/Errors/actions/index';

//Success functions
import { submitDataSuccess } from '../actions/submitData';

//Actions
import {
    MY_DIARY_SUBMIT_FORM_DATA
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

export const getRows = (action$, state$) =>
    action$.pipe(
        ofType(MY_DIARY_SUBMIT_FORM_DATA),
        mergeMap(action => {
            /** VERSIONE CORRETTA SCOMMENTARE QUANDO RISOLTO PROBLEMA AL BACK END */
            const apiUrl = `${ state$.value.apiUrl.services }/presence/my-diary?dateFrom=${ action.formData.dateFrom }&dateTo=${ action.formData.dateTo }`;

            /** ACCROCCHIO DA ELIMINARE QUANDO RISOLTO PROBLEMA AL BACK END */
            // const apiUrl = `${ state$.value.apiUrl.services }/presence/diary?dateFrom=${ action.formData.dateFrom }&dateTo=${ action.formData.dateTo }&onlyAlert=${ false }`;

            const rows = ajax
                .getJSON(
                    apiUrl,
                    { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        /** VERSIONE CORRETTA SCOMMENTARE QUANDO RISOLTO PROBLEMA AL BACK END */
                        const parsedData = {
                            diaries: [...data.diaries.map((x, index) => {
                                return {
                                    key: index,
                                    ...x
                                }
                            })],
                            user: data.user
                        }
                        return submitDataSuccess(parsedData);

                        /** ACCROCCHIO DA ELIMINARE QUANDO RISOLTO PROBLEMA AL BACK END */
                        // const parsedData = {
                        //     diaries: [...data.find(resultElement => resultElement.user.email === action.username).diaries.map((x, index) => {
                        //         return {
                        //             key: index,
                        //             ...x
                        //         };
                        //     })],
                        //     user: data.find(resultElement => resultElement.user.email === action.username).user
                        // };
                        // return submitDataSuccess(parsedData);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return rows;
        })
    );

export default combineEpics(
    getRows
)