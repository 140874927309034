import React from 'react';
import { connect } from 'react-redux';

import '@ant-design/compatible/assets/index.css';

//Libraries components
import { Form, Input, Button, Row, Col, Select, DatePicker, Spin, Alert } from 'antd';

//Icons

//Style

//Actions
import { getAccounts } from './actions/getAccounts';
import { updateFormField } from './actions/fieldUpdate';
import { accountPricingFormSubmit } from './actions/accountPricingFormSubmit';
import { getCostList } from './actions/getCostList';

//Components

//Misc imports

class AccountPricingForm extends React.Component {
    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.checkKey = this.checkKey.bind(this);
    }

    componentDidMount() {
        const token = this.props.token;
        this.props.getAccountList(token);
        this.props.getAccountCostList(token);
    }

    handleFormSubmit(formData, token) {
        this.props.submitData(formData, token);
    }

    checkKey(rule, value) {
        if(!value) {
            return Promise.reject('La chiave ERP è obbligatoria');
        }
        if(value >= 0) {
            return Promise.resolve();
        } else {
            return Promise.reject('La chiave ERP non può essere un valore negativo');
        }
    }

    render() {
        const { accounts, isStillLoading } = this.props;

        const formLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };

        return (
            <React.Fragment>
                { isStillLoading === true ? 
                    <Spin tip="Loading..." className="rm-loader-management">
                        <Alert
                            type="info"
                            className="rm-message-info"
                        />
                    </Spin> : 
                null }
                { isStillLoading === false ? 
                    <Form 
                        {...formLayout}
                        className='rm-form'
                        name='account-pricing-form'
                        onFinish={ values => {
                            const formData = {
                                account: values.account,
                                ERPkey: values.ERPkey,
                                requiredDate: values.requiredDate ? values.requiredDate.format('DD-MM-YYYY') : null,
                                dateUpdate: values.dateUpdate ? values.dateUpdate.format('DD-MM-YYYY') : null,
                                cost: values.cost,
                                description: values.desc
                            };
                            Object.keys(values).forEach((key, index) => {
                                return this.props.updateField(key, 
                                    values[Object.keys(values)[index]] === undefined || 
                                    values[Object.keys(values)[index]] === null ? '' : 
                                    values[Object.keys(values)[index]]);
                            })
                            this.handleFormSubmit(formData, this.props.token);
                        }}>
                        <Row
                            type='flex'
                            justify='start'
                            align='middle'
                            gutter={ 20 }>
                            <Col
                                xs={ 24 }
                                sm={ 24 }
                                md={ 24 }
                                lg={ 8 }
                                xl={ 8 }
                                align='left'>
                                <Form.Item label='Account'
                                name='account'
                                rules={[{ required: true, message: 'Il campo Account è obbligatorio' }]}>
                                    <Select 
                                        allowClear={ true }
                                        showSearch
                                        filterOption={ (inputValue, option) => {
                                            //Necessario per estendere il supporto a quello schifo di ie, altrimenti sostituire indexOf con includes
                                            return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase())  !== -1;
                                        } }
                                        dropdownMatchSelectWidth={ 
                                            window.matchMedia('screen and (min-width: 1200px)').matches ? 
                                            900 : 
                                            window.matchMedia('screen and (min-width: 992px)').matches ? 750 :
                                            true }>
                                        { accounts.map((x, index) => {
                                            return (
                                                <Select.Option value={ x.value } key={ x.key }>
                                                    { x.label }
                                                </Select.Option> );
                                        }) }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                xs={ 24 }
                                sm={ 24 }
                                md={ 24 }
                                lg={ 8 }
                                xl={ 8 }
                                align='left'>
                                <Form.Item label='Chiave ERP'
                                name='ERPkey'
                                rules={[{ 
                                    required: true,
                                    validator: this.checkKey }]}>
                                    <Input type='number' />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={ 24 }
                                sm={ 24 }
                                md={ 24 }
                                lg={ 8 }
                                xl={ 8 }
                                align='left'>
                                <Form.Item label='Costo'
                                name='cost'
                                rules={[{ required: true, message: 'Il costo è obbligatorio' }]}>
                                    <Input type='number' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row
                            type='flex'
                            justify='start'
                            align='middle'
                            gutter={ 20 }>
                            <Col
                                xs={ 24 }
                                sm={ 24 }
                                md={ 24 }
                                lg={ 16 }
                                xl={ 16 }
                                align='left'>
                                <Form.Item label='Descrizione'
                                name='desc'>
                                    <Input.TextArea rows={ 4 } />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={ 24 }
                                sm={ 24 }
                                md={ 24 }
                                lg={ 4 }
                                xl={ 4 }
                                align='left'>
                                <Form.Item label='Data'
                                name='requiredDate'
                                rules={[{ required: true, message: 'La data è obbligatoria' }]}>
                                    <DatePicker format='DD/MM/YYYY' allowClear={ true } placeholder='gg/mm/aaaa' />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={ 24 }
                                sm={ 24 }
                                md={ 24 }
                                lg={ 4 }
                                xl={ 4 }
                                align='left'>
                                <Form.Item label='Data update'
                                name='dateUpdate'>
                                    <DatePicker format='DD/MM/YYYY' allowClear={ true } placeholder='gg/mm/aaaa' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row
                            type='flex'
                            justify='start'
                            align='middle'
                            gutter={ 20 }>
                            <Col
                                span={ 24 }
                                align='left'>
                                    <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                    <span>Aggiungi</span>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form> :
                    null }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        account: state.accountPricingForm.account,
        ERPkey: state.accountPricingForm.ERPkey,
        requiredDate: state.accountPricingForm.requiredDate,
        dateUpdate: state.accountPricingForm.dateUpdate,
        cost: state.accountPricingForm.cost,
        desc: state.accountPricingForm.desc,
        accounts: state.accountPricingForm.accounts,
        costList: state.accountPricingForm.costList,
        token: state.auth.token,
        isStillLoading: state.accountPricingForm.isStillLoading
    };
}

const mapDispatchToProps = dispatch => {
    const getAccountList = token => {
        dispatch(getAccounts(token));
    };

    const updateField = (key, value) => {
        dispatch(updateFormField(key, value));
    };

    const submitData = (formData, token) => {
        dispatch(accountPricingFormSubmit(formData, token));
    };

    const getAccountCostList = token => {
        dispatch(getCostList(token));
    };

    return {
        getAccountList,
        updateField,
        submitData,
        getAccountCostList
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPricingForm)