import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import { errorHandling } from '../../../../../../../utils/Errors/actions/index';

//Success functions
import { submitFormDataSuccess } from '../actions/submitFormData';
import { currentParamsSuccess } from '../actions/currentParams';

//Actions
import {
    TIME_REPORT_SUBMIT_SETTINGS_FORM_DATA,
    TIME_REPORT_GET_CURRENT_PARAM
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

export const submitForm = (action$, state$) =>
    action$.pipe(
        ofType(TIME_REPORT_SUBMIT_SETTINGS_FORM_DATA),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/util/parameter/presences_alert_delta_minutes`;

            const results = ajax
                .post(apiUrl, {
                    value: action.formData.tollerance
                },
                { 
                    "Content-type": "application/json",
                    "Authorization": "Bearer " + action.token
                })
                .pipe(
                    map(data => {
                        toast.success("Operazione effettuata con successo");
                        return submitFormDataSuccess(action.token);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return results;
        })
    );

export const getCurrentParam = (action$, state$) =>
    action$.pipe(
        ofType(TIME_REPORT_GET_CURRENT_PARAM),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/util/parameter`;

            const param = ajax
                .getJSON(apiUrl,
                    { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        return currentParamsSuccess(data);
                    }),
                    catchError(error => of(errorHandling(error)))
                );

            return param;
        })
    );

export default combineEpics(
    submitForm,
    getCurrentParam
)