import { combineReducers } from 'redux';

//Actions
import {
    ACCOUNT_COST_LIST_TABLE_ROW_UPDATE
} from '../actions/actionTypes';
import {
    ACCORDION_CANCEL_UPDATE
} from '../components/EditAccordionForm/actions/actionTypes';
import {
    PRICING_ACCOUNT_COSTS_GET_COSTS
} from '../../AccountPricingForm/actions/actionTypes';

let initialState = {
    isEditFormOpen: false,
    selectedRow: null
};

const isEditFormOpen = (state = initialState.isEditFormOpen, action) => {
    switch(action.type) {
        case ACCOUNT_COST_LIST_TABLE_ROW_UPDATE:
            return true;
        case ACCORDION_CANCEL_UPDATE:
            return false;
        case PRICING_ACCOUNT_COSTS_GET_COSTS:
            return false;
        default:
            return state;
    }
};

const selectedRow = (state = initialState.selectedRow, action) => {
    switch(action.type) {
        case ACCOUNT_COST_LIST_TABLE_ROW_UPDATE:
            return action.row;
        case ACCORDION_CANCEL_UPDATE:
            return null;
        case PRICING_ACCOUNT_COSTS_GET_COSTS:
            return null;
        default:
            return state;
    }
};

const accountPricingAccordion = combineReducers({
    isEditFormOpen,
    selectedRow
});

export default accountPricingAccordion;