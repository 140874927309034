import React from 'react';
import { connect } from 'react-redux';

//Libraries components

//Icons

//Style

//Actions
import { selectedRow } from './actions/selectedRow';

//Components
import { columns } from './columns';
import DashboardTable from '../../../../../../utils/DashboardTable/index';

//Misc imports

class TimeReportTable extends React.Component {
    constructor(props) {
        super(props);

        this.selectedRow = this.selectedRow.bind(this);
    }

    selectedRow(record) {
        this.props.updateSelectedRow(record);
    }

    render() {
        const { results } = this.props;
        const parsedData = [...results.map(x => {
            return {
                key: x.key,
                totalRows: x.diaries.length,
                alertedRows: x.diaries.filter(diary => { return diary.alert === true }).length,
                userEmail: x.user.email,
                userFullName: x.user.fullName,
                userName: x.user.userName,
                diaries: x.diaries
            }
        })];

        return (
            <DashboardTable 
                data={ parsedData } 
                columns={ columns }
                rowClick={ this.selectedRow }
                tableHeight={ '45vh' }
                pagination={{ 
                    hideOnSinglePage: true,
                    pageSize: 50 
                }} />
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedRow: state.timeReportTable.selectedRow
    }
};

const mapDispatchToProps = dispatch => {
    const updateSelectedRow = row => {
        dispatch(selectedRow(row));
    };

    return {
        updateSelectedRow
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TimeReportTable);