import {
    TIME_REPORT_SUBMIT_SETTINGS_FORM_DATA,
    TIME_REPORT_GET_CURRENT_PARAM
} from './actionTypes';

export function submitFormData(formData, token) {
    return {
        type: TIME_REPORT_SUBMIT_SETTINGS_FORM_DATA,
        formData,
        token
    };
};

export function submitFormDataSuccess(token) {
    return {
        type: TIME_REPORT_GET_CURRENT_PARAM,
        token
    }
};