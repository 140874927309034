import { combineReducers } from 'redux';

import {
    TIME_REPORT_SET_SELECTED_ROW
} from '../actions/actionTypes';

let initialState = {
    selectedRow: null
};

export const selectedRow = (state = initialState.selectedRow, action) => {
    switch(action.type) {
        case TIME_REPORT_SET_SELECTED_ROW:
            return action.row;
        default:
            return state;
    }
};

const timeReportTable = combineReducers({
    selectedRow
});

export default timeReportTable;