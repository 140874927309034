import {
    COST_CALC_ADD_ROW_FORM_SUBMIT
} from './actionTypes';

export function formSubmit(formData, rows) {
    return {
        type: COST_CALC_ADD_ROW_FORM_SUBMIT,
        formData,
        rows
    };
}