import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import { errorHandling } from '../../../../../../../../../utils/Errors/actions/index';

//Success functions
import { insertUpdatedRow } from '../actions/accountPricingFormSubmit';
import { accountPricingFormSubmitSuccess } from '../actions/accountPricingFormSubmit';

//Actions
import {
    ACCORDION_SUBMIT_FORM_DATA,
    ACCORDION_INSERT_UPDATED_ROW
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

const deleteRow = (action$, state$) =>
    action$.pipe(
        ofType(ACCORDION_SUBMIT_FORM_DATA),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/account/${ action.formData.accountKey }/cost/${ action.formData.id }`;

            const deletedRow = ajax({
                url: apiUrl,
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + action.token
                }
            })
            .pipe(
                map(() => {
                    return insertUpdatedRow(action.formData, action.token);
                }),
                catchError(error => { 
                    toast.error("Errore inaspettato");
                    return of(errorHandling(error)) })
            );

            return deletedRow;
        })
    );

export const insertRow = (action$, state$) =>
    action$.pipe(
        ofType(ACCORDION_INSERT_UPDATED_ROW),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/account/${ action.formData.accountKey }/cost`;

            const addedRow = ajax({
                url: apiUrl,
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + action.token
                },
                body: {
                    cost: action.formData.cost,
                    costDate: action.formData.date,
                    description: action.formData.description,
                    erpKey: action.formData.erpKey,
                    updateDate: action.formData.dateUpdate
                }
            })
            .pipe(
                map(() => {
                    toast.success("Operazione effettuata con successo");
                    return accountPricingFormSubmitSuccess(action.token);
                }),
                catchError(error => {
                    toast.error("Errore inaspettato");
                    return of(errorHandling(error)) })
            );

            return addedRow;
        })
    );

export default combineEpics(
    deleteRow,
    insertRow
);