import {
    MY_DIARY_SUBMIT_FORM_DATA,
    MY_DIARY_SUBMIT_FORM_DATA_SUCCESS
} from './actionTypes';

export function submitData(formData, token, username) {
    return {
        type: MY_DIARY_SUBMIT_FORM_DATA,
        formData,
        token,
        username
    }
}

export function submitDataSuccess(results) {
    return {
        type: MY_DIARY_SUBMIT_FORM_DATA_SUCCESS,
        results
    }
}