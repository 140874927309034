import { combineReducers } from 'redux';

import {
    COST_CALC_GET_INITIAL_DATA_SUCCESS,
    COST_CALC_GET_RATINGS_SUCCESS
} from '../actions/actionTypes';

const initialState = {
    userList: [],
    userRates: []
};

const userList = (state = initialState.userList, action) => {
    switch(action.type) {
        case COST_CALC_GET_INITIAL_DATA_SUCCESS:
            return action.data;
        default:
            return state;
    }
};

const userRates = (state = initialState.userRates, action) => {
    switch(action.type) {
        case COST_CALC_GET_RATINGS_SUCCESS:
            return action.data;
        default:
            return state;
    }
};

const costCalc = combineReducers({
    userList,
    userRates
});

export default costCalc;