import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import { errorHandling } from '../../../../../../../utils/Errors/actions/index';

//Success functions
import { submitFormSuccess } from '../actions/updateFormField';
import { userListSuccess } from '../actions/userList';

//Actions
import {
    PRICING_FORM_SUBMIT,
    PRICING_GET_USER_LIST
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

export const submitForm = (action$, state$) =>
    action$.pipe(
        ofType(PRICING_FORM_SUBMIT),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/user/rate`;
            const updatedInfo = ajax({
                url: apiUrl,
                method: 'PUT' ,
                headers: { 'Authorization': 'Bearer ' + action.token }, 
                body: {
                    currencyCode: 'EUR',
                    dateFrom: action.formData.from,
                    hourlyRate: action.formData.price,
                    username: action.formData.user
                }})
                .pipe(
                    map(() => {
                        toast.success("Operazione effettuata con successo");
                        return submitFormSuccess(action.token);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return updatedInfo;
        })
    );

export const userList = (action$, state$) =>
    action$.pipe(
        ofType(PRICING_GET_USER_LIST),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/user`;

            const userList = ajax
                .getJSON(
                    apiUrl,
                    { 'Authorization': 'Bearer ' + action.token }
                ).pipe(
                    map(data => {
                        const parsedData = data.map((x, index) => {
                            return {
                                key: index,
                                value: x.username,
                                label: x.fullName
                            };
                        }).sort((a, b) => {
                            if(a.label < b.label) {
                                return -1
                            }
                            if(a.label > b.label) {
                                return 1
                            }
                            return 0;
                        });

                        return userListSuccess(parsedData);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return userList;
        })
    )

export default combineEpics(
    submitForm,
    userList
);