import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { errorHandling } from '../../../utils/Errors/actions/index';
import { toast } from 'react-toastify';

//Actions
import {
    COST_CALC_GET_INITIAL_DATA,
    COST_CALC_GET_RATINGS
} from '../actions/actionTypes';

//Success functions
import { fetchInitialDataSuccess } from '../actions/fetchInitialData';
import { fetchRatingsSuccess } from '../actions/fetchRatings';


const fetchInitialData = (action$, state$) =>
    action$.pipe(
        ofType(COST_CALC_GET_INITIAL_DATA),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/user`;

            const userList = ajax
                .getJSON(
                    apiUrl,
                    { 'Authorization': 'Bearer ' + action.token }
                ).pipe(
                    map(data => {
                        const parsedData = data.map((x, index) => {
                            return {
                                key: index,
                                value: x.username,
                                label: x.fullName
                            };
                        });

                        return fetchInitialDataSuccess(parsedData);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return userList;
        })
    );

const getRates = (action$, state$) =>
    action$.pipe(
        ofType(COST_CALC_GET_RATINGS),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/user/rate`;

            const rates = ajax
                .getJSON(apiUrl, { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        const parsedData = [...data.map((x, index) => {
                            return {
                                key: index,
                                ...x
                            }
                        })].reduce((data, item) => {
                            var group = item['username'];
                
                            data[group] = data[group] || [];
                            data[group].push(item);
                
                            return data;
                        }, {});
                        return fetchRatingsSuccess(parsedData);
                    }),
                    catchError(error => { 
                        toast.error("Errore nel recuperare le informazioni iniziali");
                        return errorHandling(error) })
                );

            return rates;
        })
    );

export default combineEpics(
    fetchInitialData,
    getRates
);