import {
    TIME_REPORT_RESUME_TABLE_GET_PRESENCES
} from '../../ResumeTable/actions/actionTypes';

export function formSubmit(from, to, token) {
    return {
        type: TIME_REPORT_RESUME_TABLE_GET_PRESENCES,
        from,
        to,
        token
    };
};