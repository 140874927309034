import { ofType, combineEpics } from  'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Libraries components
import { toast } from 'react-toastify';
import moment from 'moment';

//Components
import { errorHandling } from '../../../../../../../utils/Errors/actions/index';

//Success functions
import { getProjectsSuccess } from '../actions/getProjects';
import { getCurrentFiltersSuccess } from '../actions/getCurrentFilters';
import {
    accountPricingFormSubmitIncludedElements,
    accountPricingFormSubmitExcludedElements,
    accountPricingFormSubmitSuccess
} from '../actions/accountPricingFormSubmit';

//Actions
import {
    PRICING_ISSUES_WITHOUT_ACCOUNT_GET_PROJECTS,
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_DATE,
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_INCLUDED_ACCOUNTS,
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_EXCLUDED_ACCOUNTS,
    PRICING_ISSUES_WITHOUT_ACOCUNT_FILTER_GET_CURRENT_SETTINGS
} from '../actions/actionTypes';

const getProjects = (action$, state$) =>
    action$.pipe(
        ofType(PRICING_ISSUES_WITHOUT_ACCOUNT_GET_PROJECTS),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/project`;

            const projects = ajax
                .getJSON(apiUrl, { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        const parsedData = data.map((x, index) =>{
                            return {
                                label: x.key + ' - ' + x.name,
                                ...x
                            };
                        });

                        return getProjectsSuccess(parsedData);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return projects;
        })
    );

const updateDateFilter = (action$, state$)     =>
    action$.pipe(
        ofType(PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_DATE),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/issue/without-account/filter/date_from?value=${ action.formData.dateFrom }`;

            const filteredDate = ajax
                .post(apiUrl, {
                    filterCode: 'date_from',
                    value: action.formData.dateFrom
                },
                { 
                    "Content-type": "application/json",
                    "Authorization": "Bearer " + action.token
                })
                .pipe(
                    map(data => {
                        return accountPricingFormSubmitIncludedElements(action.formData, action.token);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return filteredDate;
        })
    );

const updateIncludeFilter = (action$, state$) =>
    action$.pipe(
        ofType(PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_INCLUDED_ACCOUNTS),
        mergeMap(action => {
            let stringedIds = action.formData.counted ? [...action.formData.counted.map(key => {
                return JSON.stringify(key);
            })] :
            '';
            const apiUrl = `${ state$.value.apiUrl.services }/issue/without-account/filter/projects_to_include?value=[${ stringedIds }]`;

            const filteredDate = ajax
                .post(apiUrl, {
                    filterCode: 'projects_to_include',
                    value: "[" + stringedIds + "]"
                },
                { 
                    "Content-type": "application/json",
                    "Authorization": "Bearer " + action.token
                })
                .pipe(
                    map(data => {
                        toast.success("Operazione effettuata con successo");
                        return accountPricingFormSubmitExcludedElements(action.formData, action.token);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return filteredDate;
        })
    );

const updateExcludeFilter = (action$, state$)     =>
    action$.pipe(
        ofType(PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_EXCLUDED_ACCOUNTS),
        mergeMap(action => {
            let stringedIds = action.formData.notCounted ? [...action.formData.notCounted.map(key => {
                return JSON.stringify(key);
            })] :
            '';
            const apiUrl = `${ state$.value.apiUrl.services }/issue/without-account/filter/projects_to_exclude?value=[${ stringedIds }]`;

            const filteredDate = ajax
                .post(apiUrl, {
                    filterCode: 'projects_to_include',
                    value: "[" + stringedIds + "]"
                },
                { 
                    "Content-type": "application/json",
                    "Authorization": "Bearer " + action.token
                })
                .pipe(
                    map(data => {
                        const parsedData = [
                            { code: "date_from", value: data.response[0].value },
                            { code: "projects_to_include", value: JSON.parse(data.response[1].value) },
                            { code: "projects_to_exclude", value: JSON.parse(data.response[2].value) }
                        ];
                        return accountPricingFormSubmitSuccess(parsedData);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return filteredDate;
        })
    );

const getCurrentFilters = (action$, state$) =>
    action$.pipe(
        ofType(PRICING_ISSUES_WITHOUT_ACOCUNT_FILTER_GET_CURRENT_SETTINGS),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/issue/without-account/filter`;

            const currentFilters = ajax
                .getJSON(
                    apiUrl, 
                    { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        const parsedData = [
                            { code: "date_from", value: moment(data[0].value) },
                            { code: "projects_to_include", value: JSON.parse(data[1].value) },
                            { code: "projects_to_exclude", value: JSON.parse(data[2].value) }
                        ];
                        return getCurrentFiltersSuccess(parsedData);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return currentFilters;
        })
    )

export default combineEpics(
    getProjects,
    updateDateFilter,
    updateIncludeFilter,
    updateExcludeFilter,
    getCurrentFilters
);