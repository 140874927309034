import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import { errorHandling } from '../../../../../../../utils/Errors/actions/index';

//Success functions
import { getInitialInfoSuccess } from '../actions/selectedArcPresences';

//Actions
import {
    TIME_REPORT_RESUME_TABLE_GET_PRESENCES
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

export const getInitialInfo = (action$, state$) =>
    action$.pipe(
        ofType(TIME_REPORT_RESUME_TABLE_GET_PRESENCES),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/presence?dateFrom=${ action.from }&dateTo=${ action.to }`;

            const results = ajax
                .getJSON(
                    apiUrl,
                    { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        const parsedData = data.map((x, index) => {
                            return {
                                key: index,
                                fullName: x.user.fullName,
                                ...x
                            }
                        }).sort((a, b) => {
                            if(a.user.lastName.toLowerCase() < b.user.lastName.toLowerCase()) {
                                return -1;
                            }
                            if(a.user.lastName.toLowerCase() > b.user.lastName.toLowerCase()) {
                                return 1;
                            }
                            return 0;
                        });
                        return getInitialInfoSuccess(parsedData);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return results;
        })
    );

export default getInitialInfo;