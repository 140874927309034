import {
    POD_CONFIG_SELECT_FACILITY
} from '../../../actions/actionTypes';

export function updateRow(formData, token, apiKey) {
    return {
        type: "PODCAMP_SETTINGS_SUBMIT_UPDATE_ROW_FORM_DATA",
        formData,
        token,
        apiKey
    }
}

export function updateRowSuccess(token, id) {
    return {
        type: POD_CONFIG_SELECT_FACILITY,
        id,
        token
    }
}