import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import { Form, Row, Col, Select, Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

//Icons

//Style

//Actions
import { formSubmit } from './actions/formSubmit';

//Components

//Misc imports

class FormAddRow extends React.Component {
    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.checkHours = this.checkHours.bind(this);
    }
    
    checkHours(rule, value) {
        if(!value) {
            return Promise.reject('Il campo ore è obbligatorio');
        }
        if(value >= 0) {
            return Promise.resolve();
        } else {
            return Promise.reject('Il valore non può essere negativo');
        }
    }

    handleFormSubmit(formData, rows) {
        this.props.submitData(formData, rows);
    }

    render() {
        const { users } = this.props;

        const formLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };

        return (
            <React.Fragment>
                <Form
                    className='rm-form'
                    { ...formLayout }
                    name='cost-calc-add-row-form'
                    onFinish={(values) => {
                        const updatedRows = this.props.rows.concat({ 
                            value: values.username, 
                            username: users.find(x => x.value === values.username).label,
                            key: users.find(x => x.value === values.username).key,
                            hours: values.hours });
                        this.handleFormSubmit({
                            user: values.username,
                            hoursSpent: values.hours
                        }, updatedRows);
                    }}>
                    <Row
                        type='flex'
                        justify='start'
                        align='middle'
                        gutter={ 20 }>
                        <Col
                            span={ 7 }
                            align='center'>
                            <Form.Item label='Utente'
                                name='username'
                                rules={[{ required: true, message: 'Il campo Utente è obbligatorio' }]}>
                                <Select style={{ width: '100%' }} allowClear={ true } filterOption={ (inputValue, option) => {
                                    //Necessario per estendere il supporto a quello schifo di ie, altrimenti sostituire indexOf con includes
                                    return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 } 
                                } showSearch>
                                    { users.map((x, index) => {
                                        return (
                                        <Select.Option value={ x.value } key={ x.key }>
                                            { x.label }
                                        </Select.Option> );
                                    }) }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            span={ 7 }
                            align='center'>
                            <Form.Item label="Ore investite"
                            name="hours"
                            rules={[{ 
                                required: true,
                                validator: this.checkHours }]}>
                                <Input type="number" placeholder="Notazione decimale" />
                            </Form.Item>
                        </Col>
                        <Col
                            span={ 7 }
                            align='center'>
                            <Button type="primary" htmlType="submit">
                                Aggiungi <PlusOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        formData: state.costCalcFormAddRow.formData,
        rows: state.costCalcFormAddRow.rows
    }
};

const mapDispatchToProps = dispatch => {
    const submitData = (formData, token) => {
        dispatch(formSubmit(formData, token));
    };

    return {
        submitData
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormAddRow);