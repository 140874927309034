import { combineReducers } from 'redux';

//Actions
import {
    SUBMIT_LOGIN_INFO,
    SUBMIT_LOGIN_INFO_SUCCESS
} from '../actions/actionTypes';

import {
    HOME_LOGOUT
} from '../../Home/actions/actionTypes';

import {
    LOGIN_DEFAULT_ERROR
} from '../../../utils/Errors/actions/actionTypes';

let initialState = {
    token: localStorage.getItem('rm-dash-token') ? localStorage.getItem('rm-dash-token') : '',
    isLoading: false
};

export const token = ( state = initialState.token, action ) => {
    switch(action.type) {
        case SUBMIT_LOGIN_INFO_SUCCESS:
            return action.token;
        case HOME_LOGOUT:
            return '';
        default:
            return state;
    }
};

export const isLoading = (state = initialState.isLoading, action) => {
    switch(action.type) {
        case SUBMIT_LOGIN_INFO:
            return true;
        case LOGIN_DEFAULT_ERROR:
            return false;
        case SUBMIT_LOGIN_INFO_SUCCESS:
            return false;
        default:
            return state;
    }
};

const login = combineReducers({
    token,
    isLoading
});

export default login;