import {
    POD_CONFIG_SELECT_FACILITY
} from '../../../actions/actionTypes';

export function rowDelete(id, facilityId, apiKey, token) {
    return {
        type: "PODCAMP_SETTINGS_ROW_DELETE",
        id,
        facilityId,
        apiKey,
        token
    }
}

export function deleteRowSuccess(token, id) {
    return {
        type: POD_CONFIG_SELECT_FACILITY,
        id,
        token
    }
}