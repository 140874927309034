import { ofType, combineEpics } from  'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import { errorHandling } from '../../../../../../../utils/Errors/actions/index';

//Success functions
import { getAccountsSuccess } from '../actions/getAccounts';
import { costList } from '../actions/getCostList';
import { submitFormSuccess } from '../actions/accountPricingFormSubmit';

//Actions
import {
    PRICING_ACCOUNT_COSTS_GET_ACCOUNT_COSTS,
    PRICING_ACCOUNT_COSTS_FORM_SUBMIT,
    PRICING_ACCOUNT_COSTS_GET_COSTS
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

const getAccounts = (action$, state$) =>
    action$.pipe(
        ofType(PRICING_ACCOUNT_COSTS_GET_ACCOUNT_COSTS),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/account`;

            const accounts = ajax.getJSON(
                apiUrl,
                { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        const parsedData = [...data.map((x, index) => {
                            return {
                                key: index,
                                value: x.jiraKey,
                                label: x.jiraKey + ' --- ' + x.name
                            };
                        })];

                        return getAccountsSuccess(parsedData);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return accounts;
        })
    );

const formSubmit = (action$, state$) =>
    action$.pipe(
        ofType(PRICING_ACCOUNT_COSTS_FORM_SUBMIT),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/account/${ action.formData.account }/cost`;
            const updatedCost = ajax({
                url: apiUrl,
                method: 'PUT',
                headers: { 'Authorization': 'Bearer ' + action.token },
                body: {
                    cost: action.formData.cost,
                    costDate: action.formData.requiredDate,
                    description: action.formData.description,
                    erpKey: action.formData.ERPkey,
                    updateDate: action.formData.dateUpdate
                }
            })
            .pipe(
                map(() => {
                    toast.success("Operazione effettuata con successo");
                    return submitFormSuccess(action.token);
                }),
                catchError(error => {
                    toast.error("Errore inaspettato");
                    return of(errorHandling(error)) })
            );

            return updatedCost;
        })
    );

const getUpdatedCostView = (action$, state$) =>
    action$.pipe(
        ofType(PRICING_ACCOUNT_COSTS_GET_COSTS),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/account/cost`;

            const costs = ajax.getJSON(
                apiUrl,
                { 'Authorization': 'Bearer ' + action.token }
            )
            .pipe(
                map(data => {
                    const parsedData = data.map((x, index) => {
                        return {
                            key: index,
                            jiraKey: x.account.jiraKey,
                            ...x
                        }
                    });

                    return costList(parsedData);
                }),
                catchError(error => {
                    toast.error("Errore inaspettato");
                    return of(errorHandling(error)) })
            );

            return costs;
        })
    );

export default combineEpics(
    getAccounts,
    formSubmit,
    getUpdatedCostView
);