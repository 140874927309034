import { combineReducers } from 'redux';

//Actions
import {
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_DATE,
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_DATE_FROM,
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_INCLUDED_PROJECTS,
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_EXCLUDED_PROJECTS,
    PRICING_ISSUES_WITHOUT_ACCOUNT_GET_PROJECTS_SUCCESS,
    PRICING_ISSUES_WITHOUT_ACCOUNT_UPDATE_FILTER_KEYS,
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_SUCCESS,
    PRICING_ISSUES_WITHOUT_ACOCUNT_FILTER_GET_CURRENT_SETTINGS_SUCCESS
} from '../actions/actionTypes';

const initialState = {
    counted: [],
    notCounted: [],
    dateFrom: null,
    projects: null,
    keys: null,
    isStillLoading: true
};

export const counted = (state = initialState.counted, action) => {
    switch(action.type) {
        case PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_INCLUDED_PROJECTS:
            return action.value;
        case PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_SUCCESS:
            return action.results[1].value;
        case PRICING_ISSUES_WITHOUT_ACOCUNT_FILTER_GET_CURRENT_SETTINGS_SUCCESS:
            return action.filters[1].value;
        default:
            return state;
    }
};

export const notCounted = (state = initialState.notCounted, action) => {
    switch(action.type) {
        case PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_EXCLUDED_PROJECTS:
            return action.value;
        case PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_SUCCESS:
            return action.results[2].value;
        case PRICING_ISSUES_WITHOUT_ACOCUNT_FILTER_GET_CURRENT_SETTINGS_SUCCESS:
            return action.filters[2].value;
        default:
            return state;
    }
};

export const dateFrom = (state = initialState.dateFrom, action) => {
    switch(action.type) {
        case PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_DATE_FROM:
            return action.value;
        case PRICING_ISSUES_WITHOUT_ACOCUNT_FILTER_GET_CURRENT_SETTINGS_SUCCESS:
            return action.filters[0].value;
        default:
            return state;
    }
};

export const projects = (state = initialState.projects, action) => {
    switch(action.type) {
        case PRICING_ISSUES_WITHOUT_ACCOUNT_GET_PROJECTS_SUCCESS:
            return action.projects;
        default:
            return state;
    }
};

export const keys = (state = initialState.projects, action) => {
    switch(action.type) {
        case PRICING_ISSUES_WITHOUT_ACCOUNT_UPDATE_FILTER_KEYS:
            return action.keys
        default:
            return state;
    }
};

export const isStillLoading = (state = initialState.isStillLoading, action) => {
    switch(action.type) {
        case PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_DATE:
            return true;
        case PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_SUCCESS:
            return false;
        case PRICING_ISSUES_WITHOUT_ACOCUNT_FILTER_GET_CURRENT_SETTINGS_SUCCESS:
            return false;
        default:
            return state;
    }
};

const issuesWithoutAccount = combineReducers({
    counted,
    notCounted,
    dateFrom,
    projects,
    keys,
    isStillLoading
});

export default issuesWithoutAccount;