import {
    PRICING_ACCOUNT_COSTS_FORM_ACCOUNT,
    PRICING_ACCOUNT_COSTS_FORM_KEY,
    PRICING_ACCOUNT_COSTS_FORM_DATE,
    PRICING_ACCOUNT_COSTS_FORM_DATE_UPDATE,
    PRICING_ACCOUNT_COSTS_FORM_COST,
    PRICING_ACCOUNT_COSTS_FORM_DESC,
    PRICING_ACCOUNT_COSTS_FORM_DEFAUL
} from './actionTypes';

export function updateFormField(key, value) {
    switch(key) {
        case 'account':
            return {
                type: PRICING_ACCOUNT_COSTS_FORM_ACCOUNT,
                value
            };
        case 'ERPkey':
            return {
                type: PRICING_ACCOUNT_COSTS_FORM_KEY,
                value
            };
        case 'requiredDate':
            return {
                type: PRICING_ACCOUNT_COSTS_FORM_DATE,
                value
            };
        case 'dateUpdate':
            return {
                type: PRICING_ACCOUNT_COSTS_FORM_DATE_UPDATE,
                value
            };
        case 'cost':
            return {
                type: PRICING_ACCOUNT_COSTS_FORM_COST,
                value
            };
        case 'desc':
            return {
                type: PRICING_ACCOUNT_COSTS_FORM_DESC,
                value
            };
        default:
            return {
                type: PRICING_ACCOUNT_COSTS_FORM_DEFAUL
            }
    }
}