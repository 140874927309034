export const PRICING_ACCOUNT_COSTS_FORM_ACCOUNT = "PRICING_ACCOUNT_COSTS_FORM_ACCOUNT";
export const PRICING_ACCOUNT_COSTS_FORM_KEY = "PRICING_ACCOUNT_COSTS_FORM_KEY";
export const PRICING_ACCOUNT_COSTS_FORM_DATE = "PRICING_ACCOUNT_COSTS_FORM_DATE";
export const PRICING_ACCOUNT_COSTS_FORM_DATE_UPDATE = "PRICING_ACCOUNT_COSTS_FORM_DATE_UPDATE";
export const PRICING_ACCOUNT_COSTS_FORM_COST = "PRICING_ACCOUNT_COSTS_FORM_COST";
export const PRICING_ACCOUNT_COSTS_FORM_DESC = "PRICING_ACCOUNT_COSTS_FORM_DESC";
export const PRICING_ACCOUNT_COSTS_FORM_DEFAUL = "PRICING_ACCOUNT_COSTS_FORM_DEFAUL";
export const PRICING_ACCOUNT_COSTS_GET_ACCOUNT_COSTS = "PRICING_ACCOUNT_COSTS_GET_ACCOUNT_COSTS";
export const PRICING_ACCOUNT_COSTS_GET_ACCOUNT_COSTS_SUCCESS = "PRICING_ACCOUNT_COSTS_GET_ACCOUNT_COSTS_SUCCESS";
export const PRICING_ACCOUNT_COSTS_FORM_SUBMIT = "PRICING_ACCOUNT_COSTS_FORM_SUBMIT";
export const PRICING_ACCOUNT_COSTS_GET_COSTS = "PRICING_ACCOUNT_COSTS_GET_COSTS";
export const PRICING_ACCOUNT_COSTS_GET_COSTS_LIST = "PRICING_ACCOUNT_COSTS_GET_COSTS_LIST";