import React from 'react';
import moment from "moment"

export const columns = [{
    title: 'Data',
    dataIndex: 'date',
    width: 200,
    render: (text, record, index) => {
        return (
            <div style={ record.alert ? { color: 'red' } : { color: 'black' } }>
                <span>{ moment(record.date).format('DD/MM/YYYY') }</span>
            </div>
        )
    }
},{
    title: 'Ore di presenza',
    dataIndex: 'presenceHours',
    width: 100,
    render: (text, record, index) => {
        return (
            <div style={ record.alert ? { color: 'red' } : { color: 'black' } }>
                <span>{ Math.floor(record.presenceHours || 0) + "h " + Math.floor(((record.presenceHours || 0) % 1) * 60) + "m" }</span>
            </div>
        )
    }
},{
    title: 'Ore di straordinario',
    dataIndex: 'overtimeHours',
    width: 100,
    render: (text, record, index) => {
        return (
            <div style={ record.alert ? { color: 'red' } : { color: 'black' } }>
                <span>{ Math.floor(record.overTimeHours || 0) + "h " + Math.floor(((record.overTimeHours || 0) % 1) * 60) + "m" }</span>
            </div>
        )
    }
},{
    title: 'Ore di diario',
    dataIndex: 'diaryHours',
    width: 100,
    render: (text, record, index) => {
        return (
            <div style={ record.alert ? { color: 'red' } : { color: 'black' } }>
                <span>{ Math.floor(record.diaryHours || 0) + "h " + Math.floor(((record.diaryHours || 0) % 1) * 60) + "m" }</span>
            </div>
        )
    }
}]