import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import { Spin, Alert, Tabs, Row, Col, Checkbox, Button, Tooltip } from 'antd';

//Style

//Actions
import { resetToDefault } from './actions/resetToDefault';
import { checkedValues } from './actions/checkedValues';

//Components
import history from '../../../../../../history';
import AccountDetailTable from './components/AccountDetailTable/AccountDetailTable';
import moment from 'moment';

//Misc imports

class AccountDetail extends React.Component {
    constructor(props) {
        super(props);
        this.groupBy = this.groupBy.bind(this);
    }

    componentDidMount() {
        if(!this.props.selectedRows.length && !this.props.isFetching) {
            history.push('/home/management');
        }
    }

    componentDidUpdate() {
        window.onpopstate = (e) => {
            this.props.resetToDefaultSelectedRow();
        }
    }

    // =================================== GROUPING FUNCTION ===================================
    groupBy(issues, key) {
        return issues.reduce((issue, item) => {
            (issue[item[key]] = issue[item[key]] || []).push(item);

            // Sommo le ore e i costi totali per ciascuna issue
            if((issue[item[key]].cost === undefined && key === 'issueJiraKey') || 
                (issue[item[key]].hours === undefined && key === 'issueJiraKey')) {
                issue[item[key]].cost = item.cost;
                issue[item[key]].hours = item.hours;
            } else if(key === 'issueJiraKey') {
                issue[item[key]].cost += item.cost;
                issue[item[key]].hours += item.hours;
            }
            return issue;
        }, {});
    }

    groupByBoth(rows) {
        let issueMap={};
        rows.forEach(element => {
            if(!issueMap[element.issue.jiraKey]) { issueMap[element.issue.jiraKey] = {}; }
            if(!issueMap[element.issue.jiraKey][element.user.username]) {
                issueMap[element.issue.jiraKey][element.user.username] = {
                    issue: element.issue,
                    user: element.user,
                    hours: 0,
                    cost: 0,
                    currency: element.currency,
                    date: null,
                    formatedHours: null
                }
            }

            issueMap[element.issue.jiraKey][element.user.username].hours += element.hours;
            issueMap[element.issue.jiraKey][element.user.username].cost += element.cost;

            issueMap[element.issue.jiraKey][element.user.username].formatedHours = Math.floor(element.hours || 0) + "h " + Math.floor(((element.hours || 0) % 1) * 60) + "m";
        });
        return issueMap;
    }
    // =================================== GROUPING FUNCTION ===================================

    render() {
        const { selectedRows, isFetching, selectedIssuesByAccount, checkedValues } = this.props;
        let parsedIssuesByAccount = {};

        //============ FACCIO IL PARSING DI CIASCUN RISULTATO E LI SMISTO PER OGNI ACCOUNT APERTO ============//
        if(selectedIssuesByAccount &&
            selectedIssuesByAccount.length &&
            selectedRows && 
            selectedRows.length) {

            let byAccount = {
                groupingBy: 'byAccount',
                groupedData: this.groupBy(selectedIssuesByAccount, 'issueJiraKey')
            };

            let tmp = [];
            for (let k in byAccount.groupedData) {
                tmp.push({ 
                    'issueJiraKey': k, 
                    'value': byAccount.groupedData[k]
                });
            }
            
            let byUser = {
                groupingBy: 'byUser',
                groupedData: this.groupBy(selectedIssuesByAccount, 'assignedUser')
            };

            let tmpUser = [];
            for (let k in byUser.groupedData) {
                tmpUser.push({ 
                    'assignedUser': k, 
                    'value': byUser.groupedData[k]
                });
            };

            let byBoth = {
                groupingBy: 'byBoth',
                groupedData: this.groupByBoth(selectedIssuesByAccount)
            };

            let tmpBoth = []
            for (let k in byBoth.groupedData) {
                tmpBoth.push({ 'issueJiraKey': k, 'value': byBoth.groupedData[k] });
            }

            parsedIssuesByAccount = selectedRows.map((row, index) => {
                return {
                    completeIssues: [...row.issues.map(issue => {
                        let issueData = selectedIssuesByAccount.map((x) => {
                            return x.issue.jiraKey === issue.issueJiraKey ? x : null
                        }, issue).filter(y => y);
                        return issueData.map(x => {
                            return { 
                                extendedDataOnIssue: issue, 
                                issueSummary: x.issue.summary,
                                assignedUser: x.user.fullName,
                                ...x 
                            }}).reduce((acc, val) => acc.concat(val), []);
                    })].reduce((acc, val) => acc.concat(val), []),
                    byAccount: tmp.filter(account => {
                        return row.issues.find(element => {
                            return element.issueJiraKey === '' + account.issueJiraKey + '';
                        }, account);
                    }, row),
                    byUser: [...tmpUser.map(user => {
                        return {
                            assignedUser: user.assignedUser,
                            issues: user.value.filter(issue => {
                                return row.issues.find(element => {
                                    return element.issueJiraKey === '' + issue.issueJiraKey + ''
                                }, issue);
                            }, row)
                        }
                    })].filter(element => {
                        return element.issues.length !== 0;
                    }),
                    byBoth: tmpBoth.filter(account => {
                        return row.issues.find(element => {
                            return element.issueJiraKey === '' + account.issueJiraKey + '';
                        }, account);
                    }, row),
                    parsedByBoth: [],
                    csvContent: window.URL.createObjectURL(new Blob(["\"Customer\";\"Account\";\"Account key\";\"Issue key\";\"Issue\";\"Utente\";\"Data\";\"Ore\";\"Costo\"\n" 
                    + [...row.issues.map(issue => {
                        let issueData = selectedIssuesByAccount.map((x) => {
                            return x.issue.jiraKey === issue.issueJiraKey ? x : null
                        }, issue).filter(y => y);
                        return issueData.map(x => {
                            return { 
                                extendedDataOnIssue: issue, 
                                issueSummary: x.issue.summary,
                                assignedUser: x.user.fullName,
                                ...x 
                            }}).reduce((acc, val) => acc.concat(val), [])
                        })].reduce((acc, val) => acc.concat(val), [])
                        .map(x => {
                            return "\"" + x.issue.account.customer.name + "\";"
                            + "\"" + x.issue.account.name + "\";"
                            + "\"" + x.issue.account.jiraKey + "\";"
                            + "\"" + x.issue.jiraKey + "\";"
                            + "\"" + x.issue.summary + "\";"
                            + "\"" + x.user.fullName + "\";"
                            + "\"" + moment(x.date).format("DD-MM-YYYY") + "\";"
                            + "\"" + (x.hours || 0).toString().replace(".", ",") + "\";"
                            + "\"" + (x.cost || 0).toString().replace(".", ",") + "\"\n";
                        })
                        .join('')
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")], { type: "text/csv;charset=utf-8" })),
                    ...row
                }
            });

            for(let k in parsedIssuesByAccount) {
                for(let j in parsedIssuesByAccount[k].byBoth) {
                    for(let i in parsedIssuesByAccount[k].byBoth[j].value) {
                        parsedIssuesByAccount[k].parsedByBoth.push({
                            issueJiraKey: parsedIssuesByAccount[k].byBoth[j].value[i].issue.jiraKey,
                            summary: parsedIssuesByAccount[k].byBoth[j].value[i].issue.summary,
                            ...parsedIssuesByAccount[k].byBoth[j].value[i]
                        });
                    }
                }
            }

            //============ TOTALE ORE PER CIASCUN UTENTE ============//
            for(let k in parsedIssuesByAccount) {
                for(let i in parsedIssuesByAccount[k].byUser) {
                    parsedIssuesByAccount[k].byUser[i] = {
                        totCost: parsedIssuesByAccount[k].byUser[i].issues.reduce((prev, cur) => { return {cost: prev.cost + cur.cost} }),
                        totHours: parsedIssuesByAccount[k].byUser[i].issues.reduce((prev, cur) => { return {hours: prev.hours + cur.hours} }),
                        ...parsedIssuesByAccount[k].byUser[i]
                    }
                }
            }
            //============ TOTALE ORE PER CIASCUN UTENTE ============//

        }
        //============ FACCIO IL PARSING DI CIASCUN RISULTATO E LI SMISTO PER OGNI ACCOUNT APERTO ============//

        const options = [ {label: 'Segnalazione', value:'byAccount'}, {label: 'Utente', value:'byUser'} ];
        let { selectedUser, selectedAccount, selectedBoth } = false;
        return (
            <React.Fragment>
                { isFetching === true || selectedIssuesByAccount == null ? 
                    <Spin tip="Loading..." className="rm-loader-management">
                        <Alert 
                            message="Ridurre i tempi di attesa"
                            description="Potrebbe essere una buona idea considerare dei filtri più stringenti, cosi da ridurre i tempi di caricamento." 
                            type="info"
                            className="rm-message-info"
                        />
                    </Spin> : 
                        <Tabs>
                            { selectedRows.map((row, index) => {
                                    if(parsedIssuesByAccount[index] && parsedIssuesByAccount[index].completeIssues) {
                                        parsedIssuesByAccount[index].completeIssues = parsedIssuesByAccount[index].completeIssues.map(element => {
                                            return {
                                                ...element,
                                                customerName: row.customer.name,
                                                jiraKey: row.jiraKey
                                            }
                                        });
                                    }
                                    return (<Tabs.TabPane tab={ row.account } key={ 'tab-' + index }>
                                        <Row
                                            type="flex"
                                            justify="start"
                                            align="middle"
                                            gutter={ 20 }>
                                                <Col 
                                                    xl={ 3 }
                                                    lg={ 4 }
                                                    xs={ 4 }
                                                    sm={ 4 }
                                                    md={ 4 }
                                                    align="left">
                                                    <span>Raggruppa per: </span><br />
                                                </Col>
                                                <Col 
                                                    xl={ 6 }
                                                    lg={ 6 }
                                                    xs={ 8 }
                                                    sm={ 8 }
                                                    md={ 8 }
                                                    align="left">
                                                    <Checkbox.Group 
                                                        className='rm-grouping-by'
                                                        options={ options }
                                                        name={ 'checkboxes-tab-' + index } 
                                                        value={ checkedValues }
                                                        onChange={ checkedValues => {
                                                            this.props.changeCheckedValue(checkedValues);
                                                        } } />
                                                </Col>
                                                <Col 
                                                    xl={ 4 }
                                                    lg={ 4 }
                                                    xs={ 4 }
                                                    sm={ 4 }
                                                    md={ 4 }
                                                    align="left">
                                                { parsedIssuesByAccount[index] ? (                                        
                                                    <a href={ parsedIssuesByAccount[index].csvContent } download={ parsedIssuesByAccount[index].account + ".csv" }>
                                                        <Button type="primary">
                                                            Esporta tutto
                                                        </Button>
                                                    </a>
                                                ) : (
                                                    <Tooltip title="Stiamo preparando il file CSV">
                                                        <Button type="primary" disabled={ true }>
                                                            Esporta tutto
                                                        </Button>
                                                    </Tooltip>
                                                ) }
                                            </Col>
                                        </Row>
                                        <Row
                                            type="flex"
                                            justify="start"
                                            align="middle"
                                            gutter={ 20 }>
                                            { !checkedValues.length ?
                                                <Col span={ 24 }>
                                                    { selectedIssuesByAccount &&
                                                        selectedIssuesByAccount.length &&
                                                        selectedRows && 
                                                        selectedRows.length ?
                                                    (<AccountDetailTable tableHeight={ '45vh' } grouping={ 'none' } data={ 
                                                        parsedIssuesByAccount[index].completeIssues 
                                                    } />) :
                                                    null }
                                                </Col> :
                                            null }
                                            { checkedValues.length > 1 ?
                                                <Col span={ 24 }>
                                                    { selectedIssuesByAccount &&
                                                        selectedIssuesByAccount.length &&
                                                        selectedRows && 
                                                        selectedRows.length ?
                                                    (<AccountDetailTable tableHeight={ '45vh' } grouping={ 'byBoth' } data={ 
                                                        parsedIssuesByAccount[index].parsedByBoth
                                                    } />) :
                                                    null }
                                                </Col> :
                                            null }
                                            { checkedValues.length && checkedValues.length === 1 && checkedValues[0] === 'byUser' ?
                                                <Col span={ 24 }>
                                                    { selectedIssuesByAccount &&
                                                        selectedIssuesByAccount.length &&
                                                        selectedRows && 
                                                        selectedRows.length ?
                                                    (<AccountDetailTable tableHeight={ '45vh' } grouping={ 'byUser' } data={ 
                                                        parsedIssuesByAccount[index].byUser 
                                                    } />) :
                                                    null }
                                                </Col> :
                                            null }
                                            { checkedValues.length && checkedValues.length === 1  && checkedValues[0] === 'byAccount' ?
                                                <Col span={ 24 }>
                                                    { selectedIssuesByAccount &&
                                                        selectedIssuesByAccount.length &&
                                                        selectedRows && 
                                                        selectedRows.length ?
                                                    (<AccountDetailTable tableHeight={ '45vh' } grouping={ 'byAccount' } data={ 
                                                        parsedIssuesByAccount[index].byAccount 
                                                    } />) :
                                                    null }
                                                </Col> :
                                            null }
                                        </Row>
                                    </Tabs.TabPane>) }, { selectedUser, selectedAccount, selectedBoth }
                            ) }
                        </Tabs> }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedRows: state.management.selectedRows,
        isFetching: state.accountDetail.isFetching,
        selectedIssuesByAccount: state.management.selectedIssuesByAccount,
        checkedValues: state.accountDetail.checkedValues,
    }
};

const mapDispatchToProps = dispatch => {
    const resetToDefaultSelectedRow = () => {
        dispatch(resetToDefault());
    };

    const changeCheckedValue = values => {
        dispatch(checkedValues(values));
    }

    return {
        resetToDefaultSelectedRow,
        changeCheckedValue
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountDetail);