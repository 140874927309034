import { ofType, combineEpics } from  'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import { errorHandling } from '../../../../../utils/Errors/actions/index';

//Success functions
import { getInitialDataSuccess } from '../actions/fetchInitialData';
import { getRatesSuccess } from '../actions/requestUserRates';

//Actions
import {
    PRICING_GET_INITIAL_DATA,
    PRICING_GET_USER_RATES
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

const getInitialData = (action$, state$) =>
    action$.pipe(
        ofType(PRICING_GET_INITIAL_DATA),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/account`;

            const results = ajax
                .getJSON(apiUrl, { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        const parsedData = [...data.map((x, index) => {
                            return {
                                key: index,
                                customerName: x.customer.name,
                                leadName: x.lead.fullName,
                                ...x
                            }
                        })];
                        return getInitialDataSuccess(parsedData);
                    }),
                    catchError(error => { 
                        toast.error("Errore nel recuperare le informazioni iniziali");
                        return of(errorHandling(error)) })
                );

            return results;
        })
    );

const getRates = (action$, state$) =>
    action$.pipe(
        ofType(PRICING_GET_USER_RATES),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/user/rate`;

            const rates = ajax
                .getJSON(apiUrl, { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        const parsedData = [...data.map((x, index) => {
                            return {
                                key: index,
                                ...x
                            }
                        })];
                        return getRatesSuccess(parsedData);
                    }),
                    catchError(error => { 
                        toast.error("Errore nel recuperare le informazioni iniziali");
                        return errorHandling(error) })
                );

            return rates;
        })
    )

export default combineEpics(
    getInitialData,
    getRates
);