import React from 'react';
import { connect } from 'react-redux';

import '@ant-design/compatible/assets/index.css';

//Libraries components
import { Form, Col, Row, Input, Select, Button } from 'antd';

//Style

//Actions
import { updateFormField } from './actions/updateFormField';
import { pricingFormSubmit } from './actions/pricingFormSubmit';

//Components

//Misc imports

class PricingForm extends React.Component {
    constructor(props) {
        super(props);

        this.handlePricingFormSubmit = this.handlePricingFormSubmit.bind(this);
    }

    handlePricingFormSubmit(formData, token) {
        this.props.submitData(formData, token);
    }

    render() {
        const { accountList } = this.props;

        const formLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };

        return (
            <Form 
                {...formLayout}
                className='rm-form'
                name='pricing-budget-update-form'
                onFinish={ values => {
                    Object.keys(values).forEach((key, index) => {
                        return this.props.updateField(key, 
                            values[Object.keys(values)[index]] === undefined || 
                            values[Object.keys(values)[index]] === null ? '' : 
                            values[Object.keys(values)[index]]);
                    });
                    this.handlePricingFormSubmit({
                        jiraKey: this.props.account ? this.props.account : '',
                        budget: this.props.budget ? this.props.budget.toString().replace(',', '.') : ''
                    }, this.props.token);
                }}>
                <Row
                    type='flex'
                    justify='start'
                    align='middle'
                    gutter={ 20 }>
                    <Col
                        span={ 6 }
                        align='left'>
                        <Form.Item label='Nome account'
                        name='account'
                        rules={[{ required: true, message: 'Specificare almeno un account' }]}>
                            <Select
                                allowClear={ true }
                                filterOption={ (inputValue, option) => {
                                    //Necessario per estendere il supporto a quello schifo di ie, altrimenti sostituire indexOf con includes
                                    return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                } } showSearch>
                                { accountList.map(x => {
                                    return (
                                        <Select.Option value={ x.jiraKey } key={ x.key }>
                                            { x.jiraKey + '---' + x.name }
                                        </Select.Option> );
                                }) }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        span={ 6 }
                        align='left'>
                        <Form.Item label='Budget'
                        name='budget'
                        rules={[{ required: true, message: 'Il budget è obbligatorio' }]}>
                            <Input placeholder='Budget' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    type='flex'
                    justify='start'
                    align='middle'
                    gutter={ 20 }>
                    <Col
                        span={ 6 }
                        align='left'>
                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'>
                                <span>Aggiorna</span>
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    }
}

const mapStateToProps = state => {
    return {
        account: state.UpdatePriceForm.account,
        budget: state.UpdatePriceForm.budget,
        token: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    const updateField = (key, value) => {
        dispatch(updateFormField(key, value));
    };

    const submitData = (formData, token) => {
        dispatch(pricingFormSubmit(formData, token));
    };

    return {
        updateField,
        submitData
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PricingForm);