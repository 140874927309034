import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import { Row, Col } from 'antd';

//Style

//Actions
import { fetchInitialData } from './actions/fetchInitialData';
import { fetchRatings } from './actions/fetchRatings';

//Components
import FormAddRow from './components/FormAddRow';
import CurrentTable from './components/CurrentTable';
import RecapAndEditForm from './components/RecapAndEditForm';

//Misc imports

class CostCalc extends React.Component {
    componentDidMount() {
        this.props.getInitialData(this.props.token);
        this.props.getRatings(this.props.token);
    }

    render() {
        const { userList, userRates, rows } = this.props;

        const users = [...Object.keys(userRates).map(user => {
            return userList.find(x => x.value === user.toLowerCase());
        })].filter(function( element ) {
            return element !== undefined;
         });

        return (
        <React.Fragment>
            <Row
                type='flex'
                justify='start'
                align='top'
                gutter={ 20 }>
                <Col
                    xl={ 11 }
                    lg={ 11 }
                    xs={ 24 }
                    sm={ 24 }
                    md={ 24 }>
                    <FormAddRow users={ users } />
                </Col>
            </Row>
            <Row
                type='flex'
                justify='start'
                align='top'
                gutter={ 20 }>
                <Col
                    xl={ 11 }
                    lg={ 11 }
                    xs={ 24 }
                    sm={ 24 }
                    md={ 24 }>
                    <CurrentTable rows={ rows } />
                </Col>
                <Col
                    xl={ 11 }
                    lg={ 11 }
                    xs={ 24 }
                    sm={ 24 }
                    md={ 24 }>
                    <RecapAndEditForm />
                </Col>
            </Row>
        </React.Fragment>);
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userList: state.costCalc.userList,
        userRates: state.costCalc.userRates,
        rows: state.costCalcFormAddRow.rows
    };
};

const mapDispatchToProps = dispatch => {
    const getInitialData = token => {
        dispatch(fetchInitialData(token));
    };

    const getRatings = token => {
        dispatch(fetchRatings(token));
    };

    return {
        getInitialData,
        getRatings
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CostCalc);