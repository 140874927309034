import { combineReducers } from 'redux';
import moment from 'moment';

import {
    RESUME_FORM_DATE_FIELD_UPDATE,
} from '../actions/actionTypes';

let initialState = {
    date: null
};

export const date = (state = initialState.date, action) => {
    switch(action.type) {
        case RESUME_FORM_DATE_FIELD_UPDATE:
            return action.value;
        default:
            return state === null ? moment() : state;
    }
};

const resumeForm = combineReducers({
    date
});

export default resumeForm;