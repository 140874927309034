import {
    PRICING_ISSUES_WITHOUT_ACOCUNT_FILTER_GET_CURRENT_SETTINGS,
    PRICING_ISSUES_WITHOUT_ACOCUNT_FILTER_GET_CURRENT_SETTINGS_SUCCESS
} from './actionTypes';

export function getCurrentFilters(token) {
    return {
        type: PRICING_ISSUES_WITHOUT_ACOCUNT_FILTER_GET_CURRENT_SETTINGS,
        token
    }
};

export function getCurrentFiltersSuccess(filters) {
    return {
        type: PRICING_ISSUES_WITHOUT_ACOCUNT_FILTER_GET_CURRENT_SETTINGS_SUCCESS,
        filters
    }
}