import {
    UPDATE_MANAGEMENT_FILTER_CLIENT,
    UPDATE_MANAGEMENT_FILTER_STATUS,
    UPDATE_MANAGEMENT_FILTER_RANGE,
    UPDATE_MANAGEMENT_FILTER_DEFAULT
} from './actionTypes';

export function updateFormField(field, value) {
    switch(field) {
        case 'clientJiraKey':
            return {
                type: UPDATE_MANAGEMENT_FILTER_CLIENT,
                value
            };
        case 'status':
            return {
                type: UPDATE_MANAGEMENT_FILTER_STATUS,
                value
            };
        case 'range':
            return {
                type: UPDATE_MANAGEMENT_FILTER_RANGE,
                value
            };
        default:
            return {
                type: UPDATE_MANAGEMENT_FILTER_DEFAULT
            };
    }
}