import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import { Spin, Alert } from 'antd';

//Style

//Actions
import { getDiary } from './actions/getDiary';

//Components
import FilterForm from './components/FilterForm/index';
import DashboardTable from '../../utils/DashboardTable/index';
import { columns } from './columns';

//Misc imports

class MyDiary extends React.Component {
    constructor(props) {
        super(props);

        this.getInitialData = this.getInitialData.bind(this);
    }

    getInitialData () {
        const token = this.props.token;
        this.props.fetchDiaryData(token);
    }

    render() {
        const { results, isFetching} = this.props;

        return (
            <React.Fragment>
                <FilterForm />
                { isFetching === true ? 
                    <Spin tip="Loading..." className="rm-loader-management">
                        <Alert 
                            message="Loading"
                            description="Stiamo riorganizzando i dati." 
                            type="info"
                            className="rm-message-info"
                        />
                    </Spin> : null }
                { results.diaries ? 
                    <DashboardTable 
                        data={ results.diaries } 
                        columns={ columns } /> : null }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        results: state.myDiary.results,
        isFetching: state.myDiary.isFetching
    }
};

const mapDispatchToProps = dispatch => {
    const fetchDiaryData = (token, dateFrom, dateTo, username) => {
        dispatch(getDiary(token, dateFrom, dateTo));
    };

    return {
        fetchDiaryData
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyDiary);