import {
    COST_CALC_GET_INITIAL_DATA,
    COST_CALC_GET_INITIAL_DATA_SUCCESS
} from './actionTypes';

export function fetchInitialData(token) {
    return {
        type: COST_CALC_GET_INITIAL_DATA,
        token
    };
}

export function fetchInitialDataSuccess(data) {
    return {
        type: COST_CALC_GET_INITIAL_DATA_SUCCESS,
        data
    };
}