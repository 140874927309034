import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import { errorHandling } from '../../../../../../../../../utils/Errors/actions/index';

//Success functions
import { pricingFormSubmitSuccess } from '../actions/pricingFormSubmit';

//Actions
import {
    PRICING_BUDGET_UPDATE_FORM_SUBMIT
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

export const submitForm = (action$, state$) =>
    action$.pipe(
        ofType(PRICING_BUDGET_UPDATE_FORM_SUBMIT),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/account/${ action.formData.jiraKey }/budget?budget=${ action.formData.budget }`;
            const updatedInfo = ajax({
                url: apiUrl,
                method: 'PUT' ,
                headers: { 'Authorization': 'Bearer ' + action.token }, 
                body: {
                    jiraKey: action.formData.jiraKey,
                    budget: action.formData.budget
                }})
                .pipe(
                    map(() => {
                        toast.success("Operazione effettuata con successo");
                        return pricingFormSubmitSuccess(action.token);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return updatedInfo;
        })
    );
    
export default combineEpics(
    submitForm
);