import React from 'react';

//Libraries components
import { Tag, Col } from 'antd';

//Icons

//Style
import './style.scss';

//Actions

//Components
import DashboardTable from '../../../../../../utils/DashboardTable/index';

//Misc imports

class ManagementTable extends React.Component {
    render() {
        const { data } = this.props;

        const columns = [{
            title: 'Cliente',
            width: 150,
            dataIndex: 'customerName',
            searchable: true,
            sorter: (a, b) => {
                if(a.customerName < b.customerName) {
                    return -1;
                }
                if(a.customerName > b.customerName) {
                    return 1;
                }
                return 0;
            },
            onCell: record => {
                return {
                    onClick: e => {
                        this.props.rowSelect(record.key, [record], true);
                    }
                }
            },
            defaultSortOrder: 'descend'
        },{
            title: 'Account',
            width: 300,
            dataIndex: 'accountTitle',
            searchable: true,
            onCell: record => {
                return {
                    onClick: e => {
                        this.props.rowSelect(record.key, [record], true);
                    }
                }
            }
        },{
            title: 'Budget',
            width: 90,
            dataIndex: 'budget',
            sorter: (a, b) => a.budget - b.budget,
            defaultSortOrder: 'ascend',
            /** Filtro per budget a zero */
            // filters: [{
            //     text: 'Budget a zero',
            //     value: 0
            // }],
            // onFilter: (value, record) => record.budget === value,
            onCell: record => {
                return {
                    onClick: e => {
                        this.props.rowSelect(record.key, [record], true);
                    }
                }
            }
        },{
            title: 'Costo Interno',
            width: 90,
            dataIndex: 'parsedIssueCost',
            sorter: (a, b) => a.issueCost - b.issueCost,
            onCell: record => {
                return {
                    onClick: e => {
                        this.props.rowSelect(record.key, [record], true);
                    }
                }
            }
        },{
            title: 'Costo Esterno',
            width: 90,
            dataIndex: 'accountCost',
            sorter: (a, b) => a.accountCost - b.accountCost,
            onCell: record => {
                return {
                    onClick: e => {
                        this.props.rowSelect(record.key, [record], true);
                    }
                }
            }
        },{
            title: 'Costo Totale',
            width: 100,
            dataIndex: 'percentageCost',
            sorter: (a, b) => a.percentageCost.cost - b.percentageCost.cost,
            render: element => {
                return (
                    <span style={ element.cost > element.budget ? { color: 'red' } : { color: 'black' }}>
                        { element.parsedCost } { element.percentage !== null && '- ' + element.percentage + '%' }
                    </span>
                )
            },
            onCell: record => {
                return {
                    onClick: e => {
                        this.props.rowSelect(record.key, [record], true);
                    }
                }
            }
        },{
            title: 'Ore Totali',
            width: 150,
            dataIndex: 'issueHours',
            sorter: (a, b) => a.issueHours - b.issueHours,
            render: element => {
                return (
                <span>{ 
                    Math.floor(element || 0) + "h " + Math.floor(((element || 0) % 1) * 60) + "m" }
                </span> )
            },
            onCell: record => {
                return {
                    onClick: e => {
                        this.props.rowSelect(record.key, [record], true);
                    }
                }
            }
        },{
            title: 'Issues',
            width: 70,
            dataIndex: 'issues',
            sorter: (a, b) => a.issues.length - b.issues.length,
            render: (text, record, index) => {
                return (<span>{ record.issues.length }</span>);
            },
            onCell: record => {
                return {
                    onClick: e => {
                        this.props.rowSelect(record.key, [record], true);
                    }
                }
            }
        },{
            title: 'Stato',
            width: 100,
            dataIndex: 'status',
            filters: [{
                text: 'Aperto',
                value: 'OPEN'
            },{
                text: 'Archiviato',
                value: 'ARCHIVED'
            },{
                text: 'Chiuso',
                value: 'CLOSED'
            }],
            onFilter: (value, record) => record.status.indexOf(value) === 0,
            filterMultiple: false,
            render: element => {
                let color = element === 'CLOSED' ? 'volcano' : null;
                if(element === 'ARCHIVED') {
                    color = 'geekblue';
                } else if(element === 'OPEN'){
                    color = 'green';
                }
                return (
                    <Col align='center'>
                        <Tag color={ color }>
                            { element }
                        </Tag>
                    </Col>
                )
            },
            onCell: record => {
                return {
                    onClick: e => {
                        this.props.rowSelect(record.key, [record], true);
                    }
                }
            }
        },{
            title: 'Responsabile',
            width: 130,
            dataIndex: 'leadName',
            searchable: true,
            ellipsis: true,
            onCell: record => {
                return {
                    onClick: e => {
                        this.props.rowSelect(record.key, [record], true);
                    }
                }
            }
        }];

        return (
            <React.Fragment>
                <DashboardTable 
                    columns={ columns } 
                    data={ data } 
                    rowSelect={ this.props.rowSelect }
                    tableHeight={ '60vh' }
                    tableWidth={ '80vw' }
                    pagination={ 100 } />
            </React.Fragment>
        );
    }
}

export default ManagementTable;