import {
    RATINGS_TABLE_UPDATE_ROW
} from './actionTypes';

export function rowUpdate(row, open) {
    return {
        type: RATINGS_TABLE_UPDATE_ROW,
        row,
        open
    }
}