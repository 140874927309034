import { combineReducers } from 'redux';

import {
    PRICING_ACCOUNT_COSTS_FORM_SUBMIT,
    PRICING_ACCOUNT_COSTS_FORM_ACCOUNT,
    PRICING_ACCOUNT_COSTS_FORM_KEY,
    PRICING_ACCOUNT_COSTS_FORM_DATE,
    PRICING_ACCOUNT_COSTS_FORM_DATE_UPDATE,
    PRICING_ACCOUNT_COSTS_FORM_COST,
    PRICING_ACCOUNT_COSTS_FORM_DESC,
    PRICING_ACCOUNT_COSTS_GET_ACCOUNT_COSTS_SUCCESS,
    PRICING_ACCOUNT_COSTS_GET_COSTS_LIST
} from '../actions/actionTypes';

const initialState = {
    account: null,
    ERPkey: null,
    requiredDate: null,
    dateUpdate: null,
    cost: null,
    desc: null,
    accounts: [],
    isStillLoading: false
};

const account = (state = initialState.account, action) => {
    switch(action.type) {
        case PRICING_ACCOUNT_COSTS_FORM_ACCOUNT:
            return action.value;
        default:
            return state;
    }
};

const ERPkey = (state = initialState.ERPkey, action) => {
    switch(action.type) {
        case PRICING_ACCOUNT_COSTS_FORM_KEY:
            return action.value;
        default:
            return state;
    }
};

const requiredDate = (state = initialState.requiredDate, action) => {
    switch(action.type) {
        case PRICING_ACCOUNT_COSTS_FORM_DATE:
            return action.value;
        default:
            return state;
    }
};

const dateUpdate = (state = initialState.dateUpdate, action) => {
    switch(action.type) {
        case PRICING_ACCOUNT_COSTS_FORM_DATE_UPDATE:
            return action.value;
        default:
            return state;
    }
};

const cost = (state = initialState.cost, action) => {
    switch(action.type) {
        case PRICING_ACCOUNT_COSTS_FORM_COST:
            return action.value;
        default:
            return state;
    }
};

const desc = (state = initialState.desc, action) => {
    switch(action.type) {
        case PRICING_ACCOUNT_COSTS_FORM_DESC:
            return action.value;
        default:
            return state;
    }
};

const accounts = (state = initialState.accounts, action) => {
    switch(action.type) {
        case PRICING_ACCOUNT_COSTS_GET_ACCOUNT_COSTS_SUCCESS:
            return action.accounts;
        default:
            return state;
    }
};

const isStillLoading = (state = initialState.isStillLoading, action) => {
    switch(action.type) {
        case PRICING_ACCOUNT_COSTS_FORM_SUBMIT:
            return true;
        case PRICING_ACCOUNT_COSTS_GET_ACCOUNT_COSTS_SUCCESS:
            return false;
        case PRICING_ACCOUNT_COSTS_GET_COSTS_LIST:
            return false;
        default:
            return state;
    }
}

const accountPricingForm = combineReducers({
    account,
    ERPkey,
    requiredDate,
    dateUpdate,
    cost,
    desc,
    accounts,
    isStillLoading
});

export default accountPricingForm;