import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import { Row, Col, Alert, Spin, Tabs } from 'antd';

//Style
import './style.scss';

//Actions

//Components
import FilterForm from './components/TimeReportFilterForm/index';
import SettingsForm from './components/SettingsForm/index';
import TimeReportTable from './components/TimeReportTable/index';
import TimeReportTableDetail from './components/TimeReportTableDetail/index';
import ResumeTable from './components/ResumeTable/index';

//Misc imports

class TimeReport extends React.Component {
    render() {
        const { isFetching, results, selectedRow } = this.props;
        return (
            <React.Fragment>
                <Tabs>
                    <Tabs.TabPane tab='Riepilogo' key='0'>
                        <Row
                            type='flex'
                            justify='start'
                            align='middle'
                            gutter={ 20 }>
                            <Col
                                span={ 24 }
                                align='left'>
                                    <ResumeTable />
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab='Presenze' key='1'>
                        <Row
                            type='flex'
                            justify='start'
                            align='middle'
                            gutter={ 20 }>
                            <Col
                                span={ 24 }
                                align='left'>
                                    <FilterForm />
                            </Col>
                        </Row>
                        <Row
                            type='flex'
                            justify='start'
                            align='middle'
                            gutter={ 20 }>
                                { isFetching === true ||  (isFetching === true && !results.length) ? (
                                    <Col
                                    span={ 24 }
                                    align='left'>
                                        <Spin tip="Loading..." className="rm-loader-time-report">
                                            <Alert 
                                                message="Stiamo riorganizzando i dati"
                                                description="Stiamo riorganizzando i dati" 
                                                type="info"
                                                className="rm-message-info-time-report" />
                                        </Spin>
                                    </Col>
                                ) : null }
                                { isFetching === false && results.length ? (
                                    <Col
                                    span={ 12 }
                                    align='left'>
                                        <TimeReportTable results={ results } />
                                    </Col>
                                ) : null }
                                { isFetching === false && results.length && selectedRow ? (
                                    <Col
                                    span={ 12 }
                                    align='left'>
                                        <TimeReportTableDetail data={ selectedRow }/>
                                    </Col>
                                ) : null }
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab='Configurazione' key='2'>
                        <Row
                            type='flex'
                            justify='start'
                            align='middle'
                            gutter={ 20 }>
                            <Col
                                span={ 24 }
                                align='left'>
                                    <SettingsForm />
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </React.Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        isFetching: state.timeReport.isFetching,
        results: state.timeReport.results,
        selectedRow: state.timeReport.selectedRow
    }
};

export default connect(
    mapStateToProps
)(TimeReport);