import {
    RESUME_FORM_DATE_FIELD_UPDATE,
    RESUME_FORM_DEFAULT
} from './actionTypes';

export function updateFormField(key, value) {
    switch(key) {
        case 'date':
            return {
                type: RESUME_FORM_DATE_FIELD_UPDATE,
                value
            };
        default:
            return {
                type: RESUME_FORM_DEFAULT
            };
    }
};