import { combineReducers } from 'redux';

//Actions
import {
    UPDATE_PRICING_FORM_USER,
    UPDATE_PRICING_FORM_FROM,
    UPDATE_PRICING_FORM_PRICE,
    PRICING_GET_USER_LIST_SUCCESS
} from '../actions/actionTypes';

import {
    PRICING_GET_USER_RATES
} from '../../../actions/actionTypes';

let initialState = {
    user: null,
    from: null,
    price: null,
    userList: []
};

export const user = (state = initialState.user, action) => {
    switch(action.type) {
        case UPDATE_PRICING_FORM_USER:
            return action.value;
        case PRICING_GET_USER_RATES:
            return null;
        default:
            return state;
    }
}

export const from = (state = initialState.from, action) => {
    switch(action.type) {
        case UPDATE_PRICING_FORM_FROM:
            return action.value;
        case PRICING_GET_USER_RATES:
            return null;
        default:
            return state;
    }
}

export const price = (state = initialState.price, action) => {
    switch(action.type) {
        case UPDATE_PRICING_FORM_PRICE:
            return action.value;
        case PRICING_GET_USER_RATES:
            return null;
        default:
            return state;
    }
}

export const userList = (state = initialState.userList, action) => {
    switch(action.type) {
        case PRICING_GET_USER_LIST_SUCCESS:
            return action.data;
        default:
            return state;
    }
}

const pricingFormToUpdate = combineReducers({
    user,
    from,
    price,
    userList
});

export default pricingFormToUpdate;