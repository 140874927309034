export const authorizations = {
    'management': {
        auth: ['DCG Controllo Gestione'],
        policy: ''
    },
    'pricing': {
        auth: ['DCG Costi', 'DCG Acquisti'],
        policy: 'OR'
    },
    'account-detail': {
        auth: ['DCG Controllo Gestione'],
        policy: ''
    },
    'home': {
        auth: ['DCG Users'],
        policy: ''
    },
    'attendance-list': {
        auth: ['DCG Presenze'],
        policy: ''
    },
    'issues-without-account': {
        auth: ['DCG Costi', 'DCG Controllo Gestione'],
        policy: 'OR'
    },
    'my-diary': {
        auth: ['DCG Users'],
        policy: ''
    },
    'cost-calculation': {
        auth: ['DCG Costi', 'DCG Controllo Gestione'],
        policy: 'OR'
    },
    'pod-management': {
        auth: ['DCG Operatore Podcamp'],
        policy: ''
    }
};