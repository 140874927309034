import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import '@ant-design/compatible/assets/index.css';

//Libraries components
import { Form, Col, Row, Select, /*DatePicker,*/ Button, Tooltip } from 'antd';

//Icons

//Style
import './style.scss';

//Actions
import { updateFormField } from './actions/updateFormValue';
import { getClients } from './actions/loadInitialData';
import { managementFormSubmit } from './actions/formSubmit';

//Components

//Misc imports

class FiltersForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }
    componentDidMount() {
        const token = this.props.token;
        this.props.getClientsList(token);
        if(!this.props.results || !this.props.results.length) {
            /** Filtri di default su ultimo mese */
            // const formData = {
            //     clientJiraKey: this.props.clientJiraKey,
            //     status: this.props.status,
            //     dateFrom: moment().startOf('month').format('DD-MM-YYYY'),
            //     dateTo: moment().endOf('month').format('DD-MM-YYYY')
            // };
            const formData = {
                clientJiraKey: this.props.clientJiraKey,
                status: this.props.status,
                dateFrom: '',
                dateTo: ''
            };
            //Decommentare in caso si voglia un load dei risultati
            //appena il componente/la pagina viene aperta e si carica
            
            // this.props.submitData(formData, this.props.token);
        }
    }

    handleFormSubmit(formData, token) {
        this.props.submitData(formData, token);
    }

    render() {
        const { clients, selectedRows, onRowSelect, CSVinfo, isStillFetching } = this.props;

        const formLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };
        return (
            <Form 
                {...formLayout}
                className='rm-form'
                name='management-filters-form'
                onFinish={ values => {
                    const formData = {
                        clientJiraKey: values.clientJiraKey ? values.clientJiraKey: '',
                        status: values.status ? values.status : '',
                        dateFrom: values.range && values.range.length ? values.range[0].format('DD-MM-YYYY') : '',
                        dateTo: values.range && values.range.length ? values.range[1].format('DD-MM-YYYY') : ''
                    };
                    Object.keys(values).forEach((key, index) => {
                        return this.props.updateField(key, 
                            values[Object.keys(values)[index]] === undefined || 
                            values[Object.keys(values)[index]] === null ? '' : 
                            values[Object.keys(values)[index]]);
                    });
                    this.handleFormSubmit(formData, this.props.token);
                } }
                initialValues={{
                    status: 'OPEN',
                    range: [moment().startOf('month'), moment().endOf('month')]
                }}>
                <Row
                    type="flex"
                    justify="bottom"
                    align="middle"
                    gutter={ 20 }>
                    <Col
                        span={4}
                        align="left">
                        <Form.Item 
                            label="Cliente"
                            name='clientJiraKey'>
                            <Select style={{ width: '100%' }} allowClear={ true } filterOption={ (inputValue, option) =>
                                //Necessario per estendere il supporto a quello schifo di ie, altrimenti sostituire indexOf con includes
                                option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            } showSearch>
                                { clients.map((x, index) => {
                                    return (
                                    <Select.Option value={ x.value } key={ x.key }>
                                        { x.label }
                                    </Select.Option> );
                                }) }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        span={4}
                        align="left">
                        <Form.Item label="Stato"
                            name='status'>
                            <Select style={{ width: '100%' }} allowClear={ true }>
                                <Select.Option value="OPEN">Aperto</Select.Option>
                                <Select.Option value="ARCHIVED">Archiviato</Select.Option>
                                <Select.Option value="CLOSED">Chiuso</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col
                        span={10}
                        align="left">
                        <Form.Item 
                            label="Data Da ~ A"
                            name='range'>
                            <DatePicker.RangePicker
                            allowClear={ true }
                            ranges={{
                                'Oggi': [moment(), moment()],
                                'Ultimo mese': [moment().startOf('month'), moment().endOf('month')]
                            }}
                            format='DD/MM/YYYY'
                            placeholder={['Da', 'A']} />
                        </Form.Item>
                    </Col> */}
                    <Col
                        align="left">
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                <span>Cerca</span>
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col
                        align="left">
                        <Form.Item>
                            { !isStillFetching && CSVinfo.csvData && CSVinfo.csvData.length ? (
                                <a href={ CSVinfo.actualCsv } download={ 'export-globale.csv' }>
                                    <Button type="primary">
                                        Esporta tutto
                                    </Button>
                                </a>
                            ) : (
                                <Tooltip title="Stiamo preparando il file CSV">
                                    <Button type="primary" disabled={ true }>
                                        Esporta tutto
                                    </Button>
                                </Tooltip>
                            ) }
                        </Form.Item>                        
                    </Col>
                    {  1 < selectedRows.length && selectedRows.length < 6 ? 
                        <Col
                            align="left">
                            <Form.Item>
                                <Button type="primary" onClick={ () => {
                                    onRowSelect(null, selectedRows, true)
                                } }>
                                <span>Apri selezionati</span>
                                </Button>
                            </Form.Item>
                        </Col> :
                    null }
                </Row>
            </Form>
        );
    }
}

const mapStateToProps = state => {
    return {
        clientJiraKey: state.managementFilters.clientJiraKey,
        status: state.managementFilters.status,
        range: state.managementFilters.range,
        token: state.auth.token,
        clients: state.managementFilters.clients,
        CSVinfo: state.management.CSVinfo,
        results: state.management.results,
        isStillFetching: state.managementFilters.isStillFetching
    };
}

const mapDispatchToProps = dispatch => {
    const getClientsList = token => {
        dispatch(getClients(token));
    };

    const updateField = (key, value)=> {
        dispatch(updateFormField(key, value));
    };

    const submitData = (formData, token) => {
        dispatch(managementFormSubmit(formData, token));
    };

    return {
        getClientsList,
        updateField,
        submitData
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(FiltersForm);