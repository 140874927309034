import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import moment from 'moment';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, DatePicker, Button, Form } from 'antd';

//Icons

//Style

//Actions
import { updateFormField } from './actions/updateFormField';
import { accountPricingFormSubmit } from './actions/accountPricingFormSubmit';
import { hideForm } from './actions/cancelButton';

//Components

//Misc imports

class AccordionForm extends React.Component {
    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleFormSubmit(formData, token) {
        this.props.submitData(formData, token);
    }

    handleCancel() {
        this.props.cancel();
    }

    render() {
        const { token } = this.props;

        const formLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };
        return (
            <Form 
                className='rm-form'
                name='accordion-form'
                initialValues={{
                    erpKey: this.props.erpKey,
                    date: this.props.date,
                    dateUpdate: this.props.dateUpdate,
                    description: this.props.description,
                    cost: this.props.cost,
                    accountKey: this.props.accountKey,
                    id: this.props.id
                }}
                onFinish={ values => {
                    const formData = {
                        erpKey: values.erpKey,
                        date: values.date ? values.date.format('DD-MM-YYYY') : null,
                        dateUpdate: values.dateUpdate ? values.dateUpdate.format('DD-MM-YYYY') : null,
                        description: values.description,
                        cost: values.cost,
                        accountKey: this.props.row.jiraKey,
                        id: this.props.row.id
                    };
                    this.handleFormSubmit(formData, token);
                } }
                { ...formLayout }>
                <Row
                    type='flex'
                    justify='start'
                    align='middle'
                    gutter={ 20 }>
                    <Col
                        span={ 4 }
                        align='left'>
                        <Form.Item label='Chiave ERP' name='erpKey'>
                            <Input type='number' />
                        </Form.Item>
                    </Col>
                    <Col
                        span={ 4 }
                        align='left'>
                        <Form.Item label='Data' name='date'>
                            <DatePicker
                                format='DD/MM/YYYY'
                                allowClear={ false }
                                placeholder='gg/mm/aaaa' />
                        </Form.Item>
                    </Col>
                    <Col
                        span={ 4 }
                        align='left'>
                        <Form.Item label='Data update' name='dateUpdate'>
                            <DatePicker
                                format='DD/MM/YYYY'
                                allowClear={ true }
                                placeholder='gg/mm/aaaa' />
                        </Form.Item>
                    </Col>
                    <Col
                        span={ 4 }
                        align='left'>
                        <Form.Item label='Descrizione' name='description'>
                            <Input type='text' />
                        </Form.Item>
                    </Col>
                    <Col
                        span={ 4 }
                        align='left'>
                        <Form.Item label='Costo' name='cost'>
                            <Input type='number' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    type='flex'
                    justify='start'
                    align='middle'
                    gutter={ 20 }>
                    <Col
                        span={ 4 }
                        align='left'>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" >
                                <span>Modifica</span>                                
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col
                        span={ 4 }
                        align='left'>
                        <Form.Item>
                            <Button type="danger" onClick={ () => {
                                this.handleCancel();
                            } }>
                                <span>Cancella</span>
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>    
            </Form>
        )
    }
}

const mapStateToProps = state => {
    return {
        erpKey: state.pricingAccordionForm.erpKey,
        date: moment(state.pricingAccordionForm.date),
        dateUpdate: state.pricingAccordionForm.dateUpdate !== null ? moment(state.pricingAccordionForm.dateUpdate) : null,
        description: state.pricingAccordionForm.description,
        cost: state.pricingAccordionForm.cost,
        row: state.accountPricingAccordion.selectedRow,
        token: state.auth.token
    }
};

const mapDispatchToProps = dispatch => {
    const updateField = (key, value) => {
        dispatch(updateFormField(key, value));
    };

    const submitData = (formData, token) => {
        dispatch(accountPricingFormSubmit(formData, token));
    };

    const cancel = () => {
        dispatch(hideForm());
    };

    return {
        updateField,
        submitData,
        cancel
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccordionForm)