import React from 'react';

export const columns = [{
    title: 'Nome',
    dataIndex: 'userFullName',
    searchable: true,
    render: (text, record, index) => {
        return (
        <span style={ record.alertedRows ? { color: 'red' } : { color: 'black' } }>{ text }</span>
        );
    }
},{
    title: 'Righe',
    dataIndex: 'totalRows',
    sorter: (a, b) => a.totalRows - b.totalRows,
    width: 200,
    render: (text, record, index) => {
        return (
        <span style={ record.alertedRows ? { color: 'red' } : { color: 'black' } }>{ text }</span>
        );
    }
},{
    title: 'Righe in errore',
    dataIndex: 'alertedRows',
    sorter: (a, b) => a.alertedRows - b.alertedRows,
    width: 200,
    render: (text, record, index) => {
        return (
        <span style={ record.alertedRows ? { color: 'red' } : { color: 'black' } }>{ text }</span>
        );
    }
}]