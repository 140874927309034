import {
    RATINGS_TABLE_DELETE_ROW
} from './actionTypes';

import {
    PRICING_GET_USER_RATES
} from '../../../actions/actionTypes';

export function rowDelete(row, token) {
    return {
        type: RATINGS_TABLE_DELETE_ROW,
        row,
        token
    }
}

export function deleteRowSuccess(token) {
    return {
        type: PRICING_GET_USER_RATES,
        token
    }
}