import {
    HOME_SELECTED_ENTRY
} from './actionTypes';

export function entrySelect(key, componentId) {
    return {
        type: HOME_SELECTED_ENTRY,
        key,
        componentId
    }
}