import { combineReducers } from 'redux';

//Actions
import {
    POD_CONFIG_SELECT_FACILITY,
    POD_CONFIG_GET_FACILITES_SUCCESS,
    POD_CONFIG_SELECT_FACILITY_FETCH_DATA_SUCCESS,
    POD_CONFIG_SUBMIT_FORM_DATA
} from '../actions/actionTypes';

const initialState = {
    facilities: [],
    selectedFacility: null,
    facilityData: null
};

export const selectedFacility = (state = initialState.selectedFacility, action) => {
    switch(action.type) {
        case POD_CONFIG_SELECT_FACILITY:
            return action.id;
        default:
            return state;
    };
};

export const facilities = (state = initialState.facilities, action) => {
    switch(action.type) {
        case POD_CONFIG_GET_FACILITES_SUCCESS:
            return action.facilities;
        default:
            return state;
    }
};

export const facilityData = (state = initialState.facilityData, action) => {
    switch(action.type) {
        case POD_CONFIG_SELECT_FACILITY_FETCH_DATA_SUCCESS:
            return action.facilityData;
        case POD_CONFIG_SUBMIT_FORM_DATA:
            return null;
        case POD_CONFIG_SELECT_FACILITY:
            return null;
        default:
            return state;
    };
};

const podConfig = combineReducers({
    selectedFacility,
    facilities,
    facilityData
});

export default podConfig;