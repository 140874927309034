import  { combineReducers } from 'redux';

//Actions
import {
    TIME_REPORT_RESUME_TABLE_GET_PRESENCES_SUCCESS,
    TIME_REPORT_RESUME_TABLE_GET_PRESENCES
} from '../actions/actionTypes';

let initialState = {
    data: [],
    isFetching: null
};

export const data = (state = initialState.data, action) => {
    switch(action.type) {
        case TIME_REPORT_RESUME_TABLE_GET_PRESENCES_SUCCESS:
            return action.data
        default:
            return state;
    }
};

export const isFetching = (state = initialState.isFetching, action) => {
    switch(action.type) {
        case TIME_REPORT_RESUME_TABLE_GET_PRESENCES_SUCCESS:
            return false;
        case TIME_REPORT_RESUME_TABLE_GET_PRESENCES:
            return true;
        default:
            return state;
    }
}

const timeReportResumePresences = combineReducers({
    data,
    isFetching
});

export default timeReportResumePresences;
