/** Sfondi note */
export const backgrounds = {
    ferie: 'rgb(249, 207, 197)',
    trasferta: 'rgb(255, 223, 153)',
    telelavoro: 'rgb(255, 223, 153)',
    lutto: 'rgb(255, 223, 153)',
    malattia: 'rgb(206, 238, 206)',
    permessoCompensativo: 'rgb(255, 223, 153)',
    permesso: 'rgb(170, 183, 229)',
    corsoApprendistato: 'rgb(255, 223, 153)',
    assenza: 'rgb(255, 223, 153)',
    cassaIntegrazione: 'rgb(255, 223, 153)',
    richiestaMultipla: 'rgb(5, 168, 170)',
    esameUniversitario: 'rgb(255, 223, 153)',
    licenzaMatrimoniale: 'rgb(255, 223, 153)',
    reperibilita: 'rgba(255, 223, 153)'
};

/** Elenco legenda */
export const tableFooter = [{
    label: "AS = Assenza",
    separator: " || ",
    bg: backgrounds.assenza
},{
    label: "AS1 = Cassa",
    separator: " || ",
    bg: backgrounds.cassaIntegrazione
},{
    label: "AS2 = Telelavoro",
    separator: " || ",
    bg: backgrounds.telelavoro
},{
    label: "AS3 = Trasferta",
    separator: " || ",
    bg: backgrounds.trasferta
},{
    label: "AS4 = Lutto",
    separator: " || ",
    bg: backgrounds.lutto
},{
    label: "AS5 = Corso obbligatorio per apprendistato",
    separator: " || ",
    bg: backgrounds.corsoApprendistato
},{
    label: "AS6 = Permesso compensativo",
    separator: " || ",
    bg: backgrounds.permessoCompensativo
},{
    label: "AS7 = Licenza matrimoniale",
    separator: " || ",
    bg: backgrounds.licenzaMatrimoniale
},{
    label: "AS8 = Reperibilità",
    separator: " || ",
    bg: backgrounds.reperibilita
},{
    label: "AS9 = Esame universitario",
    separator: " || ",
    bg: backgrounds.esameUniversitario
},{
    label: "FE = Ferie",
    separator: " || ",
    bg: backgrounds.ferie
},{
    label: "MA = Malattia",
    separator: " || ",
    bg: backgrounds.malattia
},{
    label: "PE = Permesso",
    separator: " || ",
    bg: backgrounds.permesso
}, {
    label: "Richiesta multipla",
    bg: backgrounds.richiestaMultipla
}];