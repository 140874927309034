import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import { Row, Col, Tabs, Form, Select, Spin } from 'antd';

//Styles

//Actions
import { setFacility } from './actions/setFacility';
import { facilities } from './actions/facilities';
import { saveForm } from './actions/saveForm';

//Components
import CustomTabForm from './components/CustomTabForm';

//Misc imports

class PodManagement extends React.Component {
    constructor(props) {
        super(props);

        this.setSelectFacility = this.setSelectFacility.bind(this);
        this.handleFormSave = this.handleFormSave.bind(this);
    }

    componentDidMount() {
        const { token } = this.props;
        this.props.getFacilities(token);
    }

    setSelectFacility(option) {
        const { token } = this.props;
        this.props.selectFacility(option, token);
    }

    handleFormSave(formData) {
        const { selectedFacility, token } = this.props;
        this.props.requestSaveForm(formData, token, selectedFacility);
    }

    render() {
        const { selectedFacility, facilities, facilityData } = this.props;

        const formLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };

        return (
            <React.Fragment>
                <Row
                    type="flex"
                    justify="center"
                    align="middle">
                    <Col  
                        xl={ 8 }
                        lg={ 8 }
                        md={ 12 }
                        sm={ 12 }
                        xs={ 24 }>
                        <Form
                            className='rm-form'
                            { ...formLayout }
                            name='pod-config-row-selection'>
                            <Row
                                type="flex"
                                justify="center"
                                align="middle">
                                <Col span={ 24 }>
                                    <Form.Item label="Seleziona una facility" name="facility">
                                        <Select
                                            allowClear={ true }
                                            showSearch
                                            filterOption={ (inputValue, option) => {
                                                //Necessario per estendere il supporto a quello schifo di ie, altrimenti sostituire indexOf con includes
                                                return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                            } }
                                            onSelect={ option => this.setSelectFacility(option) }>
                                            { facilities.map((facility, index) => {
                                                return (
                                                    <Select.Option value={ facility.value } key={ index }>
                                                        { facility.label }
                                                    </Select.Option>
                                                );
                                            }) }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                { (selectedFacility !== null && selectedFacility !== undefined) && (facilityData === null || facilityData === undefined) ?
                    <Spin tip="Loading..." className="rm-loader-management">
                    </Spin> : 
                    null }
                { selectedFacility !== null && selectedFacility !== undefined && facilityData !== null && facilityData !== undefined ? 
                    <Tabs>
                        { facilityData.map((tab, index) => {
                            return (
                            <Tabs.TabPane tab={ tab.nomeTab } key={ 'tab-' + index }>
                                <CustomTabForm 
                                    formStructure={ tab.fields } 
                                    formName={ selectedFacility + '-' + tab.nomeTab + '-form' } 
                                    tabName={ tab.nomeTab } 
                                    allTabs={ facilityData }
                                    blocks={ tab.blocks }
                                    handleSave={ this.handleFormSave } />
                            </Tabs.TabPane>
                        )}) }
                    </Tabs>
                : null }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedFacility: state.podConfig.selectedFacility,
        facilities: state.podConfig.facilities,
        token: state.auth.token,
        facilityData: state.podConfig.facilityData
    }
};

const mapDispatchToProps = dispatch => {
    const getFacilities = token => {
        dispatch(facilities(token));
    };

    const selectFacility = (facility, token) => {
        dispatch(setFacility(facility, token));
    };

    const requestSaveForm = (formData, token, selectedFacility) => {
        dispatch(saveForm(formData, token, selectedFacility))
    }

    return {
        getFacilities,
        selectFacility,
        requestSaveForm
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PodManagement);