import { combineReducers } from 'redux';

import {
    ACCOUNT_DETAIL_CHECKED_VALUES
} from '../actions/actionTypes';

import {
    MANAGEMENT_SELECTED_ROW_BY_ISSUES_SUCCESS,
    MANAGEMENT_SELECTED_ROW
} from '../../../actions/actionTypes';

let initialState = {
    checkedValues: [],
    isFetching: false
};

export const checkedValues = (state = initialState.checkedValues, action) => {
    switch(action.type) {
        case ACCOUNT_DETAIL_CHECKED_VALUES:
            return action.values;
        default:
            return state;
    }
};

export const isFetching = (state = initialState.isFetching, action) => {
    switch(action.type) {
        case  MANAGEMENT_SELECTED_ROW_BY_ISSUES_SUCCESS:
            return false;
        case MANAGEMENT_SELECTED_ROW:
            return true;
        default:
            return state;
    }
}

const accountDetail = combineReducers({
    checkedValues,
    isFetching
});

export default accountDetail;