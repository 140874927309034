import {
    TIME_REPORT_SETTINGS_FORM_TOLLERANCE,
    TIME_REPORT_FORM_DEFAULT_ACTION
} from './actionTypes';

export function updateFormField(key, value) {
    switch(key) {
        case 'tollerance':
            return {
                type: TIME_REPORT_SETTINGS_FORM_TOLLERANCE,
                value
            }
        default:
            return {
                type: TIME_REPORT_FORM_DEFAULT_ACTION
            }
    }
}