import {
    STOPWATCH_TIME_START,
    STOPWATCH_TIME_STOP
} from './actionTypes';

export function startTimer(baseTime) {
    return {
      type: STOPWATCH_TIME_START,
      baseTime: baseTime,
      now: new Date().getTime()
    };
};

export function stopTimer() {
    return {
      type: STOPWATCH_TIME_STOP,
      now: new Date().getTime()
    };
}
