import {
    PRICING_BUDGET_UPDATE_FORM_JIRA_KEY_UPDATE,
    PRICING_BUDGET_UPDATE_FORM_BUDGET_UPDATE,
    PRICING_BUDGET_UPDATE_FORM_DEFAULT
} from './actionTypes';

export function updateFormField(field, value) {
    switch(field) {
        case 'account':
            return {
                type: PRICING_BUDGET_UPDATE_FORM_JIRA_KEY_UPDATE,
                value               
            };
        case 'budget':
            return {
                type: PRICING_BUDGET_UPDATE_FORM_BUDGET_UPDATE,
                value
            };
        default:
            return {
                type: PRICING_BUDGET_UPDATE_FORM_DEFAULT               
            };
    }
}