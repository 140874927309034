import {
    PRICING_ACCOUNT_COSTS_GET_COSTS_LIST,
    PRICING_ACCOUNT_COSTS_GET_COSTS
} from './actionTypes';

export function costList(costs) {
    return {
        type: PRICING_ACCOUNT_COSTS_GET_COSTS_LIST,
        costs
    }
}

export function getCostList(token) {
    return {
        type: PRICING_ACCOUNT_COSTS_GET_COSTS,
        token
    }
}