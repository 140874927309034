import React from 'react';
import moment from "moment"

export const columns = [{
    title: 'Data',
    dataIndex: 'date',
    render: (text, record, index) => {
        return <span style={ record.alert ? { color: 'red' } : { color: 'black' } }>{ moment(text).format('DD/MM/YYYY') }</span>
    }
},{
    title: 'Ore di presenza',
    dataIndex: 'presenceHours',
    render: (text, record, index) => {
        return <span style={ record.alert ? { color: 'red' } : { color: 'black' } }>{ Math.floor(text || 0) + "h " + Math.floor(((text || 0) % 1) * 60) + "m" }</span>
    }
},{
    title: 'Ore di straordinario',
    dataIndex: 'overtimeHours',
    render: (text, record, index) => {
        return <span style={ record.alert ? { color: 'red' } : { color: 'black' } }>{ Math.floor(text || 0) + "h " + Math.floor(((text || 0) % 1) * 60) + "m" }</span>
    }
},{
    title: 'Ore di diario',
    dataIndex: 'diaryHours',
    render: (text, record, index) => {
        return <span style={ record.alert ? { color: 'red' } : { color: 'black' } }>{ Math.floor(text || 0) + "h " + Math.floor(((text || 0) % 1) * 60) + "m" }</span>
    }
}]