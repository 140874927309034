import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import { Row, Col, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'

//Icons

//Style

//Actions
import { rowDelete } from './actions/rowDelete';
import { selectRow } from './actions/selectRow';

//Components
import DashboardTable from '../../../../utils/DashboardTable/index'

//Misc imports

class CurrentTable extends React.Component {
    constructor(props) {
        super(props);

        this.handleDeleteRow = this.handleDeleteRow.bind(this);
        this.rowSelect = this.rowSelect.bind(this);
    }

    rowSelect(key, record) {
        this.props.handleRowSelect(record, key);
    }

    handleDeleteRow(row) {
        const { rows } = this.props;
        const updatedRows = rows.filter(x => 
            x.key !== row.key || 
            x.value !== row.value || 
            x.username !== row.username || 
            x.hours !== row.hours);
        this.props.deleteRow(updatedRows);
    }

    render() {
        const { rows } = this.props;
        const columns = [{
            title: 'Nome',
            dataIndex: 'username',
            searchable: true,
            width: 200,
            onCell: record => {
                return {
                    onClick: e => {
                        this.rowSelect(record.key, record);
                    }
                }
            },
            sorter: (a, b) => {
                if(a.username < b.username) {
                    return -1;
                }
                if(a.username > b.username) {
                    return 1;
                }
                return 0;
            }
        },{
            title: 'Ore spese',
            dataIndex: 'hours',
            width: 100,
            sorter: (a, b) => a.hours - b.hours,
            onCell: record => {
                return {
                    onClick: e => {
                        this.rowSelect(record.key, record);
                    }
                }
            },
            render: (text, record, index) => {
                return (<Col span={ 24 }><span>{ record.hours.toString().replace('.', ',') }</span></Col>)
            }
        },{
            title: '',
            width: 100,
            render: (text, record) => {
                return (
                    <Row>
                        <Col
                            span={ 24 }
                            align='center'>
                            {/* <Popconfirm 
                                title="Sei sicuro di voler eliminare questa riga?"
                                onConfirm={ () => { this.handleDeleteRow(record) } }
                                okText="Si"
                                cancelText="No"> */}
                                <Button
                                    style={{ marginRight: '10px' }}
                                    type="danger"
                                    onClick={() => {
                                        this.handleDeleteRow(record)
                                    }}>
                                    Elimina <DeleteOutlined />
                                </Button>
                            {/* </Popconfirm> */}
                        </Col>
                    </Row>
                );
            }
        }];

        return (
            <React.Fragment>
                <DashboardTable 
                    tableHeight={ '60vh' }
                    data={ rows } 
                    columns={ columns } 
                    pagination={{ 
                        hideOnSinglePage: true,
                        pageSize: 50 
                    }} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        rows: state.costCalcFormAddRow.rows,
        selectedRow: state.costCalcTable.selectedRow
    };
};

const mapDispatchToProps = dispatch => {
    const deleteRow = row => {
        dispatch(rowDelete(row));
    };

    const handleRowSelect = (key, row) => {
        dispatch(selectRow(key, row));
    };

    return {
        deleteRow,
        handleRowSelect
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CurrentTable);