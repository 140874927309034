import {
    PRICING_FORM_SUBMIT
} from './actionTypes';

export function pricingFormSubmit(formData, token) {
    return {
        type: PRICING_FORM_SUBMIT,
        formData,
        token
    }
}