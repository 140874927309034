import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import { errorHandling } from '../../../../../../../utils/Errors/actions/index';

//Success functions
import { timeReportFormSubmitSuccess } from '../actions/submitFormData';

//Actions
import {
    TIME_REPORT_FILTER_FORM_SUBMIT
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

export const submitForm = (action$, state$) =>
    action$.pipe(
        ofType(TIME_REPORT_FILTER_FORM_SUBMIT),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/presence/diary?dateFrom=${ action.formData.dateFrom }&dateTo=${ action.formData.dateTo }&onlyAlert=${ action.formData.onlyErrors }`;

            const results = ajax
                .getJSON(
                    apiUrl,
                    { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        const parsedData = [...data.map((user, index) => {
                            return {
                                key: index,
                                diaries: [...user.diaries.map((diary, index) => {
                                    return {
                                        key: index,
                                        ...diary
                                    }
                                })],
                                user: user.user
                            }
                        })].sort((a, b) => {
                            if(a.user.fullName < b.user.fullName) { return -1; }
                            if(a.user.fullName > b.user.fullName) { return 1; }
                            return 0;
                        });
                        return timeReportFormSubmitSuccess(parsedData);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return results;
        })
    );

export default combineEpics(
    submitForm
);