import React from 'react';
import { connect } from 'react-redux';

import '@ant-design/compatible/assets/index.css';

//Libraries components
import { Form, Col, Row, Input, DatePicker, Button, Select } from 'antd';

//Icons

//Style
import './style.scss';

//Actions
import { updateFormField } from './actions/updateFormField';
import { pricingFormSubmit } from './actions/pricingFormSubmit';
import { userList } from './actions/userList';

//Components

//Misc imports

class PricingFormToUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.handlePricingFormSubmit = this.handlePricingFormSubmit.bind(this);
    }

    componentDidMount() {
        this.props.loadUserList(this.props.token);
    }

    handlePricingFormSubmit(formData, token) {
        this.props.submitData(formData, token);
    }

    render() {
        const { userList } = this.props;

        const formLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };
        
        return (
            <Form 
                className='rm-form'
                name='pricing-filters-form'
                onFinish={ values => {
                    Object.keys(values).forEach((key, index) => {
                        return this.props.updateField(key, 
                            values[Object.keys(values)[index]] === undefined || 
                            values[Object.keys(values)[index]] === null ? '' : 
                            values[Object.keys(values)[index]]);
                    });
                    this.handlePricingFormSubmit({
                        user: values.user ? values.user : '',
                        from: values.from ? values.from.format('DD-MM-YYYY') : null,
                        price: values.price ? values.price : ''
                    }, this.props.token);
                } }
                {...formLayout}>
                <Row
                    type='flex'
                    justify='start'
                    align='middle'
                    gutter={ 20 }>
                    <Col
                        span={ 4 }
                        align='left'>
                        <Form.Item label='Utente'
                            name='user'
                            rules={[{ required: true, message: 'Il nome è obbligatorio' }]}>
                                <Select allowClear={ true } filterOption={ (inputValue, option) => 
                                    //Necessario per estendere il supporto a quello schifo di ie, altrimenti sostituire indexOf con includes
                                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                } showSearch>
                                    { userList.map(x => {
                                        return (
                                            <Select.Option value={ x.value } key={ x.key }>
                                                { x.label }
                                        </Select.Option> );
                                    }) }
                                </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        span={ 4 }
                        align='left'>
                        <Form.Item label='Costo orario'
                            name='price'
                            rules={[{ required: true, message: 'Il costo orario è obbligatorio' }]}>
                            <Input placeholder='Costo' type='number' />
                        </Form.Item>
                    </Col>
                    <Col
                        span={ 4 }
                        align='left'>
                        <Form.Item label='Data Da'
                            name='from'
                            rules={[{ required: true, message: 'La data è obbligatoria' }]}>
                            <DatePicker format='DD/MM/YYYY' allowClear={ true } placeholder='Da' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    type='flex'
                    justify='start'
                    align='middle'
                    gutter={ 20 }>
                    <Col
                        span={ 4 }
                        align='left'>
                        <Form.Item>
                            <Button 
                                type='primary' 
                                htmlType='submit'>
                                <span>Aggiungi</span>
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.pricingForm.user,
        from: state.pricingForm.from,
        price: state.pricingForm.price,
        token: state.auth.token,
        userList: state.pricingForm.userList
    }
};

const mapDispatchToProps = dispatch => {
    const updateField = (key, value) => {
        dispatch(updateFormField(key, value));
    };

    const submitData = (formData, token) => {
        dispatch(pricingFormSubmit(formData, token));
    };

    const loadUserList = token => {
        dispatch(userList(token));
    }

    return {
        updateField,
        submitData,
        loadUserList
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PricingFormToUpdate);