import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { errorHandling } from '../../../utils/Errors/actions/index';
import { toast } from 'react-toastify';
import moment from 'moment';

//Actions
import {
    CHECKIN_CHECKOUT_FETCH_COMPANY_DATA,
    CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA,
    CHECKIN_CHECKOUT_REQUEST_CHECKIN,
    CHECKIN_CHECKOUT_REQUEST_CHECKOUT,
    CHECKIN_CHECKOUT_GET_ALL_EMPLOYEES_CHECKIN
} from '../actions/actionTypes';

//Success functions
import { getCurrentUserData } from '../actions/getCurrentUserData';
import { getCurrentUserDataSuccess } from '../actions/getCurrentUserData';
import { getCurrentUserCheckins, getSingleEmployeeCheckin } from '../actions/getCurrentUserData';

const fetchCompanyData = (action$, state$) =>
    action$.pipe(
        ofType(CHECKIN_CHECKOUT_FETCH_COMPANY_DATA),
        mergeMap(action => {
            const apiUrl = `https://www.badgebox.com/api/v1/company/employees?api_key=${ state$.value.apiUrl.badgeBoxAPIKey }&api_secret=${ state$.value.apiUrl.badgeBoxAPISecret }`;

            const employeeList = ajax
                .getJSON(apiUrl)
                .pipe(
                    map(data => {
                        const currentUserId = data.employees.find(x => x.first_name + " " + x.last_name === action.currentUser) !== undefined ? data.employees.find(x => x.first_name + " " + x.last_name === action.currentUser).id : null;
                        return getCurrentUserData(currentUserId, data.employees);
                    }),
                    catchError(error => of(errorHandling(error)))
                );

            return employeeList;
        })
    );

const fetchCurrentUserData = (action$, state$) => 
    action$.pipe(
        ofType(CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA),
        mergeMap(action => {
            const apiUrl = `https://www.badgebox.com/api/v1/employee/records?id=${ action.currentUserId }&api_key=${ state$.value.apiUrl.badgeBoxAPIKey }&api_secret=${ state$.value.apiUrl.badgeBoxAPISecret }`;

            const currentUserStatus = ajax
                .getJSON(apiUrl)
                .pipe(
                    map(data => {
                        const parsedStatus = data.records.find(x => x.day === moment().format('YYYY-MM-DD')) && (data.records.find(x => x.day === moment().format('YYYY-MM-DD')).checkout === undefined || data.records.find(x => x.day === moment().format('YYYY-MM-DD')).checkout === null) ? true : false;
                        const parsedCheckin = data.records.find(x => x.day === moment().format('YYYY-MM-DD')) ? moment(data.records.find(x => x.day === moment().format('YYYY-MM-DD')).checkin).format("x") : null;
                        return getCurrentUserDataSuccess(parsedStatus, parsedCheckin);
                    }),
                    catchError(error => of(errorHandling(error)))
                );

            return currentUserStatus;
        })
    );

const fetchCurrentUserCheckins = (action$, state$) => 
    action$.pipe(
        ofType(CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA),
        mergeMap(action => {
            const apiUrl = `https://www.badgebox.com/api/v1/employee/records?id=${ action.currentUserId }&api_key=${ state$.value.apiUrl.badgeBoxAPIKey }&api_secret=${ state$.value.apiUrl.badgeBoxAPISecret }&start_date=${ moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD') }&end_date=${ moment().format('YYYY-MM-DD') }`;

            const currentUserStatus = ajax
                .getJSON(apiUrl)
                .pipe(
                    map(data => {
                        const parsedCheckinList = [...data.records.map((record, index) => {
                            return {
                                key: index,
                                ...record,
                                parsedDate: moment(record.day).format('DD/MM/YYYY'),
                                parsedCheckin: record.checkin ? moment(record.checkin).format('HH:mm') : '-- : --',
                                parsedCheckout: record.checkout ? moment(record.checkout).format('HH:mm') : '-- : --'
                            }
                        })].sort((a, b) => moment(b.day) - moment(a.day));
                        return getCurrentUserCheckins(parsedCheckinList);
                    }),
                    catchError(error => of(errorHandling(error)))
                );

            return currentUserStatus;
        })
    );

const fetchCurrentEmployeeCheckins = (action$, state$) => 
    action$.pipe(
        ofType(CHECKIN_CHECKOUT_GET_ALL_EMPLOYEES_CHECKIN),
        mergeMap(action => {
            const apiUrl = `https://www.badgebox.com/api/v1/employee/records?id=${ action.currentEmployeeId }&api_key=${ state$.value.apiUrl.badgeBoxAPIKey }&api_secret=${ state$.value.apiUrl.badgeBoxAPISecret }`;

            const updatedEmployeeListCheckins = ajax.getJSON(apiUrl).pipe(
                map(data => {
                    const parsedCheckin = 
                        /** Non ho una timbratura odierna */
                        data.records.find(x => x.day === moment().format('YYYY-MM-DD')) == null || 
                        data.records.find(x => x.day === moment().format('YYYY-MM-DD')) == undefined ?
                        false :
                        /** Ho un checkin odierno ma non un checkout */
                        data.records.find(x => x.day === moment().format('YYYY-MM-DD')).checkin && 
                        (data.records.find(x => x.day === moment().format('YYYY-MM-DD')).checkout === undefined ||
                        data.records.find(x => x.day === moment().format('YYYY-MM-DD')).checkout === null) ? true : 
                        /** Non ho ne checkin ne checkout */
                        data.records.find(x => x.day === moment().format('YYYY-MM-DD')).checkin && 
                        data.records.find(x => x.day === moment().format('YYYY-MM-DD')).checkout ? false :
                        false;
                    const lastCheckout = 
                        data.records.find(x => x.day === moment().format('YYYY-MM-DD')) != null || 
                        data.records.find(x => x.day === moment().format('YYYY-MM-DD')) != undefined && 
                        data.records.find(x => x.day === moment().format('YYYY-MM-DD')).checkin && 
                        data.records.find(x => x.day === moment().format('YYYY-MM-DD')).checkout ? 
                        moment(data.records.find(x => x.day === moment().format('YYYY-MM-DD')).checkout).format("HH:MM") : null;
                    return getSingleEmployeeCheckin({
                        key: action.currentEmployeeId, 
                        employee: data.employee.first_name + " " + data.employee.last_name, 
                        isAvailable: parsedCheckin,
                        lastCheckout: lastCheckout,
                        employeeLastname: data.employee.last_name
                    });
                }),
                catchError(error => of(errorHandling(error)))
            );

            return updatedEmployeeListCheckins;
        })
    )

const doCheckin = (action$, state$) =>
    action$.pipe(
        ofType(CHECKIN_CHECKOUT_REQUEST_CHECKIN),
        mergeMap(action => {
            const apiUrl = `https://www.badgebox.com/api/v1/track/checkin`;

            const updatedCheckin = ajax({
                method: 'PUT',
                url: apiUrl,
                body: {
                    api_key: state$.value.apiUrl.badgeBoxAPIKey,
                    api_secret: state$.value.apiUrl.badgeBoxAPISecret,
                    latitude: action.latitude,
                    longitude: action.longitude,
                    date: action.date,
                    employee_id: action.currentUser
                }
            }).pipe(
                map(data => {
                    toast.success('Operazione andata a buon fine');
                    return getCurrentUserData(action.currentUser)
                }),
                catchError(error => {
                    toast.error('Errore durante la comunicazione con badgebox');
                    return of(errorHandling(error))
                })
            );

            return updatedCheckin;
        })
    );

const doCheckout = (action$, state$) =>
    action$.pipe(
        ofType(CHECKIN_CHECKOUT_REQUEST_CHECKOUT),
        mergeMap(action => {
            const apiUrl = `https://www.badgebox.com/api/v1/track/checkout`;

            const updateCheckout = ajax({
                method: 'PUT',
                url: apiUrl,
                body: {
                    api_key: state$.value.apiUrl.badgeBoxAPIKey,
                    api_secret: state$.value.apiUrl.badgeBoxAPISecret,
                    latitude: action.latitude,
                    longitude: action.longitude,
                    date: action.date,
                    employee_id: action.currentUser
                }
            }).pipe(
                map(data => {
                    toast.success('Operazione andata a buon fine');
                    return getCurrentUserData(action.currentUser)
                }),
                catchError(error => {
                    toast.error('Errore durante la comunicazione con badgebox');
                    return of(errorHandling(error))
                })
            );

            return updateCheckout;

        })
    );

export default combineEpics(
    fetchCompanyData,
    fetchCurrentUserData,
    fetchCurrentUserCheckins,
    doCheckin,
    doCheckout,
    fetchCurrentEmployeeCheckins
)