import { combineReducers } from 'redux';

//Actions
import {
    PRICING_GET_INITIAL_DATA,
    PRICING_GET_INITIAL_DATA_SUCCESS,
    PRICING_GET_USER_RATES_SUCCESS
} from '../actions/actionTypes';
import {
    RATINGS_TABLE_UPDATE_ROW
} from '../components/RatingsTable/actions/actionTypes';
import {
    RATING_HIDE_UPDATE_ROW_FORM
} from '../components/RatingEditForm/actions/actionTypes';
import {
    PRICING_ACCOUNT_COSTS_GET_COSTS_LIST
} from '../components/AccountPricingForm/actions/actionTypes';
import {
    PRICING_BUDGET_UPDATE_FORM_SUBMIT
} from '../components/PricingTable/components/updatePricingForm/actions/actionTypes';

let initialState = {
    results: [],
    isFetching: null,
    rates: [],
    isEditFormOpen: false,
    selectedRow: null,
    costList: []
};

export const results = (state = initialState.results, action) => {
    switch(action.type) {
        case PRICING_GET_INITIAL_DATA_SUCCESS:
            return action.data;
        default:
            return state;
    }
};

export const isFetching = (state = initialState.isFetching, action) => {
    switch(action.type) {
        case PRICING_GET_INITIAL_DATA:
            return true;
        case PRICING_BUDGET_UPDATE_FORM_SUBMIT:
            return true;
        case PRICING_GET_INITIAL_DATA_SUCCESS:
            return false;
        default:
            return state;
    }
};

export const rates = (state = initialState.rates, action) => {
    switch(action.type) {
        case PRICING_GET_USER_RATES_SUCCESS:
            return action.rates;
        default:
            return state;
    }
}

export const isEditFormOpen = (state = initialState.isEditFormOpen, action) => {
    switch(action.type) {
        case RATINGS_TABLE_UPDATE_ROW:
            return action.open;
        case RATING_HIDE_UPDATE_ROW_FORM:
            return false;
        case PRICING_GET_USER_RATES_SUCCESS:
            return false;
        default:
            return state;
    }
}

export const selectedRow = (state = initialState.selectedRow, action) => {
    switch(action.type) {
        case RATINGS_TABLE_UPDATE_ROW:
            return action.row;
        case RATING_HIDE_UPDATE_ROW_FORM:
            return {};
        default:
            return state;
    }
}

export const costList = (state = initialState.costList, action) => {
    switch(action.type) {
        case PRICING_ACCOUNT_COSTS_GET_COSTS_LIST:
            return action.costs;
        default:
            return state;
    }
}

const pricing = combineReducers({
    results,
    isFetching,
    rates,
    isEditFormOpen,
    selectedRow,
    costList
});

export default pricing;