import React from 'react';
import { Button } from 'antd';
import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { errorHandling } from '../../../utils/Errors/actions/index';
import { toast } from 'react-toastify';

//Actions
import {
    POD_CONFIG_GET_FACILITES,
    POD_CONFIG_SELECT_FACILITY,
    POD_CONFIG_SUBMIT_FORM_DATA
} from '../actions/actionTypes';

//Success functions
import { facilitiesSuccess, facilities } from '../actions/facilities';
import { facilityDataSuccess } from '../actions/setFacility';
import { saveDataSuccess } from '../actions/saveForm';

const getFacilities = (action$, state$) =>
    action$.pipe(
        ofType(POD_CONFIG_GET_FACILITES),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.podBaseURL }/facilities`;

            const facilityList = ajax
                .getJSON(
                    apiUrl,
                    { 'Authorization': 'Bearer ' + action.token }
                ).pipe(
                    map(data => {
                        const parsedData = data.map((facility, index) => {
                            return {
                                value: facility.facilityId,
                                label: facility.facilityName,
                                key: index
                            };
                        });
                        return facilitiesSuccess(parsedData);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error));
                    })
                );

            return facilityList;
        })
    );

const facilityData = (action$, state$) =>
    action$.pipe(
        ofType(POD_CONFIG_SELECT_FACILITY),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.podBaseURL }/settings?facilityId=${ action.id }`;

            const returnedData = ajax
                .getJSON(
                    apiUrl,
                    { 'Authorization': 'Bearer ' + action.token }
                )
                .pipe(
                    map(data => {
                        return facilityDataSuccess(data);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error));
                    })
                );

            return returnedData;
        })
    );

const saveFormData = (action$, state$) => 
    action$.pipe(
        ofType(POD_CONFIG_SUBMIT_FORM_DATA),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.podBaseURL }/settings?facilityId=${ action.selectedFacility }`;

            const savedData = ajax
                .post(
                    apiUrl,
                    action.formData,
                    { 
                        "Content-type": "application/json",
                        "Authorization": "Bearer " + action.token
                    })
                    .pipe(
                        map(data => {
                            toast.success("Informazioni salvate con successo");
                            return saveDataSuccess(action.token, action.selectedFacility);
                        }),
                        catchError(error => {
                            toast.error("Errore inaspettato");
                            return of(errorHandling(error)) })
                    );

            return savedData;
        })
    )

export default combineEpics(
    getFacilities,
    facilityData,
    saveFormData
);