import { combineReducers } from 'redux';

import {
    ISSUES_WITHOUT_ACCOUNT_PAGE_FETCH_INITIAL_DATA,
    ISSUES_WITHOUT_ACCOUNT_PAGE_FETCH_INITIAL_DATA_SUCCESS
} from '../actions/actionTypes';

let initialState = {
    issues: [],
    isFetching: null
};

export const issues = (state = initialState.issues, action) => {
    switch(action.type) {
        case ISSUES_WITHOUT_ACCOUNT_PAGE_FETCH_INITIAL_DATA_SUCCESS:
            return action.issues;
        default:
            return state;
    }
};

export const isFetching = (state = initialState.isFetching, action) => {
    switch(action.type) {
        case ISSUES_WITHOUT_ACCOUNT_PAGE_FETCH_INITIAL_DATA_SUCCESS:
            return false;
        case ISSUES_WITHOUT_ACCOUNT_PAGE_FETCH_INITIAL_DATA:
            return true;
        default:
            return state;
    }
}

const issuesWithoutAccountPage = combineReducers({
    issues,
    isFetching
});

export default issuesWithoutAccountPage;