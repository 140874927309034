import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';

//Style

//Actions
import { rowDelete } from './actions/delete';
import { rowUpdate } from './actions/update';

//Components
import DashboardTable from '../../../../../../utils/DashboardTable/index';

//Misc imports

class RatingsTable extends React.Component {
    constructor(props) {
        super(props);
        this.deleteRow = this.deleteRow.bind(this);
        this.updateRow = this.updateRow.bind(this);
    }

    deleteRow(row) {
        this.props.deleteSelectedRow({
            username: row.username,
            dateFrom: moment(row.dateFrom).format('DD-MM-YYYY')
        }, this.props.token);
    }

    updateRow(row) {
        this.props.updateSelectedRow(row, true);
    }

    render() {
        const { data } = this.props;
        
        const columns = [{
            title: 'Username',
            width: 200,
            dataIndex: 'username',
            searchable: true
        },{
            title: 'Da',
            width: 100,
            dataIndex: 'dateFrom',
            render: element => {
                return (
                    <span>
                        { moment(element).format('DD/MM/YYYY') }
                    </span>
                )
            }
        },{
            title: 'A',
            width: 100,
            dataIndex: 'dateTo',
            render: element => {
                return (
                    <span>
                        { moment(element).format('DD/MM/YYYY') }
                    </span>
                )
            }
        },{
            title: 'Costo orario',
            dataIndex: 'hourlyRate',
            width: 100,
            sorter: (a, b) => a.hourlyRate - b.hourlyRate,
            render: element => {
                return (
                    <span>{ element.toFixed(2).toString().replace('.', ',') }</span>
                )
            }
        },{
            title: '',
            align: 'center',
            width: 200,
            render: (text, record) => (
                <div>
                    <span>
                        <Popconfirm onConfirm={ () => { this.deleteRow(record) } } title={ 'Sei sicuro di voler eliminare questa riga?' } okText='Si' cancelText='No'>
                            <Button type='danger'>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </span>
                    <span style={{ paddingLeft: '10%' }}>
                        <Button type='primary' onClick={ () => this.updateRow(record) }>
                            <EditOutlined />
                        </Button>
                    </span>
                </div>
            )
        }];

        return (
            <React.Fragment>
                <DashboardTable
                    columns={ columns }
                    data={ data }
                    onRow={ record => {
                        this.props.updateRow(record);
                    } }
                    tableHeight={ '40vh' } />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token
    }
}

const mapDispatchToProps = dispatch => {
    const deleteSelectedRow = (row, token) => {
        dispatch(rowDelete(row, token));
    };

    const updateSelectedRow = (row, open) => {
        dispatch(rowUpdate(row, open));
    };

    return {
        deleteSelectedRow,
        updateSelectedRow
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RatingsTable);