import {
    UPDATE_PRICING_FORM_USER,
    UPDATE_PRICING_FORM_FROM,
    UPDATE_PRICING_FORM_PRICE,
    UPDATE_MANAGEMENT_FILTER_DEFAULT
} from './actionTypes';

import {
    PRICING_GET_USER_RATES
} from '../../../actions/actionTypes';

export function updateFormField(field, value) {
    switch(field) {
        case 'user':
            return {
                type: UPDATE_PRICING_FORM_USER,
                value
            }
        case 'from':
            return {
                type: UPDATE_PRICING_FORM_FROM,
                value
            }
        case 'price':
            return {
                type: UPDATE_PRICING_FORM_PRICE,
                value
            }
        default:
            return {
                type: UPDATE_MANAGEMENT_FILTER_DEFAULT
            }
    }
}

export function submitFormSuccess(token) {
    return {
        type: PRICING_GET_USER_RATES,
        token
    }
}