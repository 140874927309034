import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import moment from 'moment';
import { Collapse, Row, Col, Button, Popconfirm } from 'antd';

//Icons

//Style

//Actions
import { rowDelete } from './actions/rowDelete';
import { rowUpdate } from './actions/rowUpdate';
import { hideForm } from './components/EditAccordionForm/actions/cancelButton';

//Components
import DashboardTable from '../../../../../../utils/DashboardTable/index';
import AccordionForm from './components/EditAccordionForm/index';

//Misc imports

class AccountPricingAccordion extends React.Component {
    constructor(props) {
        super(props);

        this.groupBy = this.groupBy.bind(this);
        this.accountCostRowDelete = this.accountCostRowDelete.bind(this);
        this.updateRow = this.updateRow.bind(this);
    }

    accountCostRowDelete (element) {
        const token = this.props.token;
        this.props.deleteCostRow(element, token);
    }

    groupBy(account, key) {
        return account.reduce((newAccount, item) => {
            (newAccount[item[key]] = newAccount[item[key]] || []).push(item);
            return newAccount;
        }, {})
    }

    updateRow(row) {
        this.props.updateSelectedRow(row, true);
    }

    hideFormAsDefault() {
        this.props.defaultHide();
    }

    render() {
        const { data, isEditFormOpen, selectedRow } = this.props;
        const groupedData = this.groupBy(data, 'jiraKey');

        let parsedGroupedData = [];

        const columns = [{
            title: 'Chiave ERP',
            dataIndex: 'erpKey',
            width: 100,
            searchable: true
        },{
            title: 'Data',
            dataIndex: 'costDate',
            render: (text, record, index) => {
                return (
                    <span>{ moment(text).format('DD/MM/YYYY') }</span>
                );
            },
            width: 100
        },{
            title: 'Data aggiornamento',
            dataIndex: 'updateDate',
            width: 100,
            render: (text, record, index) => {
                return (
                    <span>{ 
                        text !== '' && 
                        text !== null && 
                        text !== undefined ? 
                            moment(text).format('DD/MM/YYYY') : 
                            '' }</span>
                );
            }
        },{
            title: 'Descrizione',
            dataIndex: 'description',
            width: 200
        },{
            title: 'Costo',
            dataIndex: 'cost',
            width: 100,
            render: record => {
                return (
                <span>{ record.toString().replace('.', ',') }</span>
                )
            }
        },{
            title: '',
            width: 200,
            render: (tesxt, record) => {
                return (
                    <Row>
                        <Popconfirm 
                            title="Sei sicuro di voler eliminare questa riga?"
                            onConfirm={ () => { this.accountCostRowDelete(record) } }
                            okText="Si"
                            cancelText="No">
                            <Button
                                style={{ marginRight: '10px' }}
                                size="small"
                                type="danger">
                                Elimina
                            </Button>
                        </Popconfirm>
                        <Button 
                            size="small"
                            type="primary"
                            onClick={ () => this.updateRow(record) }>
                            Modifica
                        </Button>
                    </Row>
                );
            }
        }];

        for(var i in groupedData){
            parsedGroupedData.push({
                'jiraKey': i,
                'value': groupedData[i]
            });
        }

        return (
            <React.Fragment>
                <Row
                    type='flex'
                    justify='start'
                    align='middle'
                    gutter={ 20 }>
                    <Col
                        span={ 24 }
                        align='left'>
                        <Collapse 
                            accordion
                            onChange={ () => {
                                this.hideFormAsDefault();
                            } }>
                            { parsedGroupedData.map(x => {
                                return (
                                    <Collapse.Panel header={ x.jiraKey + ' -- ' + x.value[0].account.name } key={ x.value[0].key }>
                                        <DashboardTable
                                            data={ x.value } 
                                            columns={ columns } 
                                            key={ x.value[0].key }
                                            onRow={ record => {
                                                this.props.updateRow(record);
                                            } } />
                                            { isEditFormOpen && selectedRow ? (
                                                <AccordionForm />
                                            ) : null }
                                    </Collapse.Panel>
                                );
                            }) }
                        </Collapse>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        isEditFormOpen: state.accountPricingAccordion.isEditFormOpen,
        selectedRow: state.accountPricingAccordion.selectedRow
    }
};

const mapDispatchToProps = dispatch => {
    const deleteCostRow = (element, token) => {
        dispatch(rowDelete(element, token));
    };

    const updateSelectedRow = (element, token) => {
        dispatch(rowUpdate(element, token));
    };

    const defaultHide = () => {
        dispatch(hideForm());
    };

    return {
        deleteCostRow,
        updateSelectedRow,
        defaultHide
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPricingAccordion);