import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import moment from 'moment';
import { Spin, Alert, Row, Button, Col } from 'antd';

//Icons

//Style
import { backgrounds, tableFooter } from './styleElements.js';

//Actions
import { selectedArcPresences } from './actions/selectedArcPresences';

//Components
import DashboardTable from '../../../../../../utils/DashboardTable/index';
import ResumeFilterForm from '../ResumeForm/index';

//Misc imports

class ResumeTable extends React.Component {
    constructor(props) {
        super(props);

        this.getDays = this.getDays.bind(this);
        this.exportCurrentCsv = this.exportCurrentCsv.bind(this);
        this.getRecord = this.getRecord.bind(this);
        this.getRequest = this.getRequest.bind(this);
    }

    exportCurrentCsv(formData) {
        window.open(this.props.services + '/presence/' + formData.format('YYYY') + '/' + formData.format('MM') + '/presenze.xls?token=' + this.props.token);
    }

    getRecord(currentDay, currentRecord) {
        return currentRecord.records.find(x => moment(x.checkin).format('YYYY-MM-DD') === this.currentDateFormat(currentDay));
    };

    getRequest(currentDay, currentRequest) {
        return currentRequest.requests.filter(x => moment(x.date).format('YYYY-MM-DD') === this.currentDateFormat(currentDay));
    };

    getDays() {
        let daysInMonth = this.props.formData && this.props.formData.from ? this.props.formData.daysInMonth() : moment().daysInMonth();
        let arrDay = [];

        while(daysInMonth) {
            arrDay.push(this.props.formData && this.props.formData.from ? this.props.formData.date(daysInMonth).format('MM-DD-YYYY') : moment().date(daysInMonth).format('MM-DD-YYYY'));
            daysInMonth--;
        }

        return arrDay.reverse();
    }

    componentDidMount() {
        const schedule = this.getDays();
        this.props.getCurrentPresences(moment(schedule[0]).format('DD-MM-YYYY'), moment(schedule[schedule.length - 1]).format('DD-MM-YYYY'), this.props.token);
    }

    currentDateFormat(rawDate) {
        return moment(rawDate).format('YYYY-MM-DD');
    }

    render() {
        const { data, isFetching } = this.props;

        const columns = data && data.length ? [{
            title: 'Nome',
            dataIndex: 'fullName',
            searchable: true,
            width: 300,
            fixed: 'left'
        }, ...this.getDays().map((day, index) => {
            return {
                //Definisco i colori per ciascuna cella
                onCell: record => {
                    const actualRequestValue = this.getRequest(day, record);
                    //Check ferie
                    if( actualRequestValue && actualRequestValue.length && actualRequestValue.length === 1 && actualRequestValue[0].type === "ferie") {
                        return { style: { backgroundColor: backgrounds.ferie } }
                    }
                    //Check malattia
                    if( actualRequestValue && actualRequestValue.length && actualRequestValue.length === 1 && actualRequestValue[0].type === "malattia") {
                        return { style: { backgroundColor: backgrounds.malattia } }
                    }
                    //Check telelavoro
                    if( actualRequestValue && actualRequestValue.length && actualRequestValue.length === 1 && actualRequestValue[0].type === "telelavoro") {
                        return { style: { backgroundColor: backgrounds.telelavoro } }
                    }
                    //Check permesso compensativo
                    if( actualRequestValue && actualRequestValue.length && actualRequestValue.length === 1 && actualRequestValue[0].type === "permesso compensativo") {
                        return { style: { backgroundColor: backgrounds.permessoCompensativo } }
                    }
                    //Check permesso
                    if( actualRequestValue && actualRequestValue.length && actualRequestValue.length === 1 && actualRequestValue[0].type === "permesso") {
                        return { style: { backgroundColor: backgrounds.permesso } }
                    }
                    //Check trasferta
                    if( actualRequestValue && actualRequestValue.length && actualRequestValue.length === 1 && actualRequestValue[0].type === "trasferta") {
                        return { style: { backgroundColor: backgrounds.trasferta } }
                    }
                    //Check corso apprendistato
                    if( actualRequestValue && actualRequestValue.length && actualRequestValue.length === 1 && actualRequestValue[0].type === "corso obbligatorio apprendistato") {
                        return { style: { backgroundColor: backgrounds.corsoApprendistato } }
                    }
                    //Check cassa integrazione
                    if( actualRequestValue && actualRequestValue.length && actualRequestValue.length === 1 && actualRequestValue[0].type === "cassa integrazione" ) {
                        return { style: { backgroundColor: backgrounds.cassaIntegrazione } }
                    }
                    //Check licenza matrimoniale
                    if( actualRequestValue && actualRequestValue.length && actualRequestValue.length === 1 && actualRequestValue[0].type === "licenza matrimoniale" ) {
                        return { style: { backgroundColor: backgrounds.licenzaMatrimoniale } }
                    }
                    //Check reperibilità
                    if( actualRequestValue && actualRequestValue.length && actualRequestValue.length === 1 && actualRequestValue[0].type === "reperibilita" ) {
                        return { style: { backgroundColor: backgrounds.reperibilita } }
                    }
                    //Check esame universitario
                    if( actualRequestValue && actualRequestValue.length && actualRequestValue.length === 1 && actualRequestValue[0].type === "esame universitario" ) {
                        return { style: { backgroundColor: backgrounds.esameUniversitario } }
                    }
                    //Check lutto
                    if( actualRequestValue && actualRequestValue.length && actualRequestValue.length === 1 && actualRequestValue[0].type === "lutto" ) {
                        return { style: { backgroundColor: backgrounds.lutto } }
                    }
                    if(actualRequestValue && actualRequestValue.length && actualRequestValue.length > 1) {
                        return { style: { backgroundColor: backgrounds.richiestaMultipla } }
                    }
                },
                title: moment(day).format('D - dddd')
                    .replace("Monday", "Lunedì")
                    .replace("Tuesday", "Martedì")
                    .replace("Wednesday", "Mercoledì")
                    .replace("Thursday", "Giovedì")
                    .replace("Friday", "Venerdì")
                    .replace("Saturday", "Sabato")
                    .replace("Sunday", "Domenica"),
                dataIndex: this.currentDateFormat(day) + '.worked',
                width: 300,
                render: (text, record, index) => {
                    const actualRequestValue = this.getRequest(day, record);
                    const actualRecordValue = this.getRecord(day, record);
                    return (
                        <React.Fragment>
                            { !actualRecordValue && actualRequestValue && actualRequestValue.length ? 
                            actualRequestValue.map((actualRequestSingleValue, index) => {
                                return (
                                    <React.Fragment key={ index }>
                                        { actualRequestSingleValue.type === "ferie" ? 
                                        /** FERIE */
                                        (<span style={{ backgroundColor: backgrounds.ferie }}>{ 
                                            actualRequestSingleValue.worked 
                                        } FE</span>) : null }
                                        { actualRequestSingleValue.type === "trasferta" ? 
                                        /** TRASFERTA */
                                        (<span style={{ backgroundColor: backgrounds.trasferta }}>{ 
                                            actualRequestSingleValue.worked 
                                        } AS3</span>) : null }
                                        { actualRequestSingleValue.type === "malattia" ?
                                        /** MALATTIA */
                                        (<span style={{ backgroundColor: backgrounds.malattia }}>
                                            MA({ actualRequestSingleValue.protocol })
                                        </span>) : null }
                                        { actualRequestSingleValue.type === "permesso compensativo" ? 
                                        /** PEMRESSO COMPENSATIVO */
                                        (<span style={{ backgroundColor: backgrounds.permessoCompensativo }}>
                                            AS6
                                        </span>) : null }
                                        { actualRequestSingleValue.type === "permesso" ? 
                                        /** PERMESSO */
                                        (<span style={{ backgroundColor: backgrounds.permesso }}>
                                            { actualRequestSingleValue.hours } PE
                                        </span>) : null }
                                        { actualRequestSingleValue.type === "corso obbligatorio apprendistato" ?
                                        /** CORSO OBBLIGATORIO APPRENDISTATO */
                                        (<span style={{ backgroundColor: backgrounds.corsoApprendistato }}>
                                            AS5
                                        </span>) : null }
                                        {/* { actualRequestSingleValue.type === "lutto" ? */}
                                        {/** LUTTO */}
                                        {/* (<span style={{ backgroundColor: backgrounds.lutto }}>
                                            AS4 
                                        </span>) : null }
                                        { actualRequestSingleValue.type === "licenza matrimoniale" ? */}
                                        {/** LICENZA MATRIMONIALE */}
                                        {/* (<span style={{ backgroundColor: backgrounds.licenzaMatrimoniale }}>
                                            AS7 
                                        </span>) : null }
                                        { actualRequestSingleValue.type === "reperibilita" ? */}
                                        {/** REPERIBILITA */}
                                        {/* (<span style={{ backgroundColor: backgrounds.reperibilita }}>
                                            AS8 
                                        </span>) : null }
                                        { actualRequestSingleValue.type === "esame universitario" ? */}
                                        {/** ESAME UNIVERSITARIO */}
                                        {/* (<span style={{ backgroundColor: backgrounds.corsoApprendistato }}>
                                            AS9 
                                        </span>) : null } */}
                                    </React.Fragment>
                                )
                            })
                            : null }
                            <div>
                                {  actualRecordValue && actualRecordValue !== null && actualRecordValue !== undefined ?
                                    (<div>
                                        <span>{ Math.floor(actualRecordValue.worked || 0) + "h " + 
                                                Math.floor(((actualRecordValue.worked || 0) % 1) * 60) + "m" }
                                        </span><br />
                                    </div>) 
                                : null }
                                    
                                {/* STRAORDINARIO */}
                                {  actualRecordValue && actualRecordValue !== null && actualRecordValue !== undefined && 
                                    actualRecordValue.overtime !== 0 ? 
                                    (<div>
                                        <span>{ "(* " + Math.floor(actualRecordValue.overtime || 0) + "h " + 
                                        Math.floor(((actualRecordValue.overtime || 0) % 1) * 60) + "m straordinario)" }
                                        </span><br />
                                    </div>) 
                                : null }

                                { actualRequestValue && actualRequestValue.length ? 
                                    actualRequestValue.map((actualRequestSingleValue, index) => {
                                        return (
                                            <React.Fragment key={ index }>
                                                {/* TELELAVORO */}
                                                { actualRequestSingleValue && actualRequestSingleValue.type === 'telelavoro' ?
                                                    (<div style={{ backgroundColor: backgrounds.telelavoro }}>
                                                        <span>{ actualRequestSingleValue.hours === undefined ||
                                                                actualRequestSingleValue.hours === null ? "(" :
                                                            "( " + Math.floor(actualRequestSingleValue.hours || 0) + "h " + 
                                                                Math.floor(((actualRequestSingleValue.hours || 0) % 1) * 60) + "m" } AS2 )
                                                        </span><br />
                                                    </div>) 
                                                : null }
                
                                                {/* PERMESSO COMPENSATIVO */}
                                                { actualRequestSingleValue && actualRequestSingleValue.type === 'permesso compensativo' ?
                                                    (<div style={{ backgroundColor: backgrounds.permessoCompensativo }}>
                                                        <span>{ actualRequestSingleValue.hours === undefined ||
                                                                actualRequestSingleValue.hours === null ? "( " :
                                                                Math.floor(actualRequestSingleValue.hours || 0) + "h " + 
                                                                Math.floor(((actualRequestSingleValue.hours || 0) % 1) * 60) + "m" } AS6 )
                                                        </span><br />
                                                    </div>) 
                                                : null }
                
                                                {/* PERMESSO */}
                                                { actualRequestSingleValue && actualRequestSingleValue.type === "permesso" ?
                                                    (<div style={{ backgroundColor: backgrounds.permesso }}>
                                                        <span>{ actualRequestSingleValue.hours === undefined ||
                                                                actualRequestSingleValue.hours === null ? "( " :
                                                                "( " + Math.floor(actualRequestSingleValue.hours || 0) + "h " + 
                                                                Math.floor(((actualRequestSingleValue.hours || 0) % 1) * 60) + "m" } PE )
                                                        </span><br />
                                                    </div>) 
                                                : null }
                
                                                {/* TRASFERTA */}
                                                { actualRequestSingleValue && actualRequestSingleValue.type === "trasferta" ?
                                                    (<div style={{ backgroundColor: backgrounds.trasferta }}>
                                                        <span>{ actualRequestSingleValue.hours === undefined ||
                                                                actualRequestSingleValue.hours === null ? "( " :
                                                                "( " + Math.floor(actualRequestSingleValue.hours || 0) + "h " + 
                                                                Math.floor(((actualRequestSingleValue.hours || 0) % 1) * 60) + "m" } AS3 )
                                                        </span><br />
                                                    </div>) 
                                                : null }
                
                                                {/* CORSO APPRENDISTATO */}
                                                { actualRequestSingleValue && actualRequestSingleValue.type === 'corso obbligatorio apprendistato' ? 
                                                    (<div>
                                                        <span>AS5</span><br />
                                                    </div>) 
                                                : null }

                                                {/* CASSA INTEGRAZIONE */}
                                                { actualRequestSingleValue && actualRequestSingleValue.type === "cassa integrazione" ?
                                                    (<div style={{ backgroundColor: backgrounds.cassaIntegrazione }}>
                                                        <span>{ actualRequestSingleValue.hours === undefined ||
                                                                actualRequestSingleValue.hours === null ? "( " :
                                                                "( " + Math.floor(actualRequestSingleValue.hours || 0) + "h " + 
                                                                Math.floor(((actualRequestSingleValue.hours || 0) % 1) * 60) + "m" } AS1 )
                                                        </span><br />
                                                    </div>)
                                                : null }

                                                {/* LICENZA MATRIMONIALE */}
                                                { actualRequestSingleValue && actualRequestSingleValue.type === "licenza matrimoniale" ?
                                                    (<div style={{ backgroundColor: backgrounds.licenzaMatrimoniale }}>
                                                        <span>{ actualRequestSingleValue.hours === undefined ||
                                                                actualRequestSingleValue.hours === null ? "( " :
                                                                "( " + Math.floor(actualRequestSingleValue.hours || 0) + "h " + 
                                                                Math.floor(((actualRequestSingleValue.hours || 0) % 1) * 60) + "m" } AS7 )
                                                        </span><br />
                                                    </div>)
                                                : null }

                                                {/* REPERIBILITA */}
                                                { actualRequestSingleValue && actualRequestSingleValue.type === "reperibilita" ?
                                                    (<div style={{ backgroundColor: backgrounds.reperibilita }}>
                                                        <span>{ actualRequestSingleValue.hours === undefined ||
                                                                actualRequestSingleValue.hours === null ? "( " :
                                                                "( " + Math.floor(actualRequestSingleValue.hours || 0) + "h " + 
                                                                Math.floor(((actualRequestSingleValue.hours || 0) % 1) * 60) + "m" } AS8 )
                                                        </span><br />
                                                    </div>)
                                                : null }

                                                {/* ESAME UNIVERSITARIO */}
                                                { actualRequestSingleValue && actualRequestSingleValue.type === "esame universitario" ?
                                                    (<div style={{ backgroundColor: backgrounds.esameUniversitario }}>
                                                        <span>{ actualRequestSingleValue.hours === undefined ||
                                                                actualRequestSingleValue.hours === null ? "( " :
                                                                "( " + Math.floor(actualRequestSingleValue.hours || 0) + "h " + 
                                                                Math.floor(((actualRequestSingleValue.hours || 0) % 1) * 60) + "m" } AS9 )
                                                        </span><br />
                                                    </div>)
                                                : null }

                                                {/* LUTTO */}
                                                { actualRequestSingleValue && actualRequestSingleValue.type === "lutto" ?
                                                    (<div style={{ backgroundColor: backgrounds.lutto }}>
                                                        <span>{ actualRequestSingleValue.hours === undefined ||
                                                                actualRequestSingleValue.hours === null ? "( " :
                                                                "( " + Math.floor(actualRequestSingleValue.hours || 0) + "h " + 
                                                                Math.floor(((actualRequestSingleValue.hours || 0) % 1) * 60) + "m" } AS4 )
                                                        </span><br />
                                                    </div>)
                                                : null }
                                            </React.Fragment>
                                        );
                                    }): null }
                            </div>
                        </React.Fragment>
                    );
                }
            }
        })] : [];

        return (
            <React.Fragment>
                { isFetching === true || data == null ? 
                    <Spin tip="Loading..." className="rm-loader-management">
                        <Alert 
                            message="Ridurre i tempi di attesa"
                            description="Potrebbe essere una buona idea considerare dei filtri più stringenti, cosi da ridurre i tempi di caricamento." 
                            type="info"
                            className="rm-message-info"
                        />
                    </Spin> : null }
                { data && data.length && !isFetching ?
                    <DashboardTable 
                        columns={ columns } 
                        data={ data } 
                        tableWidth={ '400vw' } 
                        tableHeight={ '60vh' }
                        footer={ () => {
                            return (
                                <Row
                                    type='flex'
                                    justify='start'
                                    align='middle'
                                    gutter={ 20 } >
                                    <Col span={ 16 }>
                                        <div>
                                            { tableFooter.map((span, index) => {
                                                return (
                                                    <React.Fragment key={ index }>
                                                        <span style={{ backgroundColor: span.bg }}>{ span.label }</span>
                                                        <span>{ span.separator ? span.separator : null }</span>
                                                    </React.Fragment>
                                                );
                                            }) }
                                        </div>
                                    </Col>
                                    <Col span={ 4 }>
                                        <ResumeFilterForm />
                                    </Col>
                                    <Col span={ 2 } offset={ 2 }>
                                        <div>
                                            <Button disabled={ this.props.formData === null ? true : false } onClick={ () => {
                                                this.exportCurrentCsv(this.props.formData);
                                            } }>Esporta</Button>
                                        </div>
                                    </Col>
                                </Row>
                            );
                        } }
                        pagination={ {
                            hideOnSinglePage: true,
                            pageSize: 400
                        } } /> : null }
            </React.Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        data: state.timeReportResumePresences.data,
        isFetching: state.timeReportResumePresences.isFetching,
        services: state.apiUrl.services,
        formData: state.resumeForm.date
    };
};

const mapDispatchToProps = dispatch => {
    const getCurrentPresences = (from, to, token) => {
        dispatch(selectedArcPresences(from, to, token));
    };

    return {
        getCurrentPresences
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResumeTable);