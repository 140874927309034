import {
    TIME_REPORT_RESUME_TABLE_GET_PRESENCES,
    TIME_REPORT_RESUME_TABLE_GET_PRESENCES_SUCCESS
} from './actionTypes';

export function selectedArcPresences(from, to, token) {
    return {
        type: TIME_REPORT_RESUME_TABLE_GET_PRESENCES,
        from,
        to,
        token
    };
};

export function getInitialInfoSuccess(data) {
    return {
        type: TIME_REPORT_RESUME_TABLE_GET_PRESENCES_SUCCESS,
        data
    };
};