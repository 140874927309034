import React from 'react';

//Libraries components

//Style

//Actions

//Components
import { columns } from './columns';
import DashboardTable from '../../../../../../utils/DashboardTable/index';

//Misc imports



class PricingTable extends React.Component {
    render() {
        const { data } = this.props;

        return (
            <React.Fragment>
                <DashboardTable
                    columns={ columns }
                    data={ data } />
            </React.Fragment>
        )
    }
}

export default PricingTable;