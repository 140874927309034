import {
    PRICING_ISSUES_WITHOUT_ACCOUNT_GET_PROJECTS,
    PRICING_ISSUES_WITHOUT_ACCOUNT_GET_PROJECTS_SUCCESS
} from './actionTypes';

export function getProjects(token) {
    return {
        type: PRICING_ISSUES_WITHOUT_ACCOUNT_GET_PROJECTS,
        token
    }
};

export function getProjectsSuccess(projects) {
    return {
        type: PRICING_ISSUES_WITHOUT_ACCOUNT_GET_PROJECTS_SUCCESS,
        projects
    }
};