import { combineReducers } from 'redux';

import {
    ACCOUNT_DETAIL_SET_SELECTED_KEYS
} from '../actions/actionTypes';

let initialState = {
    searchText: ''
};

export const searchText = (state = initialState.searchText, action) => {
    switch(action.type) {
        case ACCOUNT_DETAIL_SET_SELECTED_KEYS:
            return action.keys;
        default:
            return state;
    }
};

const accountDetailTable = combineReducers({
    searchText
});

export default accountDetailTable;