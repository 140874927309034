import {
    TIME_REPORT_FILTER_FORM_SUBMIT,
    TIME_REPORT_FILTER_FORM_SUBMIT_SUCCESS
} from './actionTypes';

export function timeReportFormSubmit(formData, token) {
    return {
        type: TIME_REPORT_FILTER_FORM_SUBMIT,
        formData,
        token
    }
};

export function timeReportFormSubmitSuccess(results) {
    return {
        type: TIME_REPORT_FILTER_FORM_SUBMIT_SUCCESS,
        results
    }
};