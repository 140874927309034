import {
    PRICING_ACCOUNT_COSTS_GET_ACCOUNT_COSTS,
    PRICING_ACCOUNT_COSTS_GET_ACCOUNT_COSTS_SUCCESS
} from './actionTypes';

export function getAccounts(token) {
    return {
        type: PRICING_ACCOUNT_COSTS_GET_ACCOUNT_COSTS,
        token
    }
}

export function getAccountsSuccess(accounts) {
    return {
        type: PRICING_ACCOUNT_COSTS_GET_ACCOUNT_COSTS_SUCCESS,
        accounts
    }
}