import {
    HOME_GET_UNASSIGNED_ISSUES,
    HOME_GET_UNASSIGNED_ISSUES_SUCCESS
} from './actionTypes';

export function unassignedIssues(token) {
    return {
        type: HOME_GET_UNASSIGNED_ISSUES,
        token
    }
}

export function getUnassignedIssuesSuccess(issues) {
    return {
        type: HOME_GET_UNASSIGNED_ISSUES_SUCCESS,
        issues
    }
}