import { combineReducers } from 'redux';

//Actions
import { 
    COST_CALC_SELECT_ROW 
} from '../actions/actionTypes';

let initialState = {
    selectedRow: null
};

const selectedRow = (state = initialState.selectedRow, action) => {
    switch(action.type) {
        case COST_CALC_SELECT_ROW:
            return { key: action.key, row: action.row };
        default:
            return state;
    }
};

const costCalcTable = combineReducers({
    selectedRow
});

export default costCalcTable;