import {
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_DATE_FROM,
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_INCLUDED_PROJECTS,
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_EXCLUDED_PROJECTS,
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_DEFAULT
} from './actionTypes';

export function updateFormField(key, value) {
    switch(key) {
        case 'counted':
            return {
                type: PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_INCLUDED_PROJECTS,
                value
            };
        case 'notCounted':
            return {
                type: PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_EXCLUDED_PROJECTS,
                value
            };
        case 'dateFrom':
            return {
                type: PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_DATE_FROM,
                value
            };
        default:
            return {
                type: PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_DEFAULT
            }
    }
}