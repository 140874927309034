import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import { errorHandling } from '../../../../../../../utils/Errors/actions/index';

//Success functions
import { deleteRowSuccess, updateRowSuccess } from '../actions/ratingsSubmit';
import { getRatesSuccess } from '../../../../Pricing/actions/requestUserRates';

//Actions
import {
    SUBMIT_RATING_FORM_ROW_UPDATE,
    SUBMIT_RATING_FORM_ROW_UPDATE_INSERT,
    SUBMIT_RATING_FORM_GET_UPDATED_RATES
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

const getInitialData = (action$, state$) =>
    action$.pipe(
        ofType(SUBMIT_RATING_FORM_ROW_UPDATE),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/user/rate?dateFrom=${ action.initialDate }&username=${ action.formData.username }`;

            const deletedRow = ajax({
                url: apiUrl,
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + action.token
                }
            }).pipe(
                map(() => {
                    return deleteRowSuccess(action.formData, action.token);
                }),
                catchError(error => {
                    toast.error("Errore inaspettato");
                    return of(errorHandling(error)) })
            );

            return deletedRow;
        })
    );

const insertUpdatedRow = (action$, state$) =>
    action$.pipe(
        ofType(SUBMIT_RATING_FORM_ROW_UPDATE_INSERT),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/user/rate`;

            const updatedInfo = ajax({
                url: apiUrl,
                method: 'PUT',
                headers: { 'Authorization': 'Bearer ' + action.token },
                body: {
                    currencyCode: 'EUR',
                    dateFrom: action.info.dateFrom,
                    hourlyRate: action.info.hourlyRate,
                    username: action.info.username
                }})
                .pipe(
                    map(() => {
                        toast.success("Operazione effettuata con successo");
                        return updateRowSuccess(action.token);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return updatedInfo;
        })
    );

const getRates = (action$, state$) => 
    action$.pipe(
        ofType(SUBMIT_RATING_FORM_GET_UPDATED_RATES),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/user/rate`;

            const rates = ajax
                .getJSON(apiUrl, { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        const parsedData = [...data.map((x, index) => {
                            return {
                                key: index,
                                ...x
                            }
                        })];
                        return getRatesSuccess(parsedData);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return rates;
        })
    );

export default combineEpics(
    getInitialData,
    insertUpdatedRow,
    getRates
)