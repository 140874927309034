import {
    COST_CALC_SELECT_ROW
} from './actionTypes';

export function selectRow(key, row) {
    return {
        type: COST_CALC_SELECT_ROW,
        key,
        row
    }
};