import { combineReducers } from 'redux';

import {
    STOPWATCH_TIME_START,
    STOPWATCH_TIME_STOP
} from '../actions/actionTypes';
import {
    CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA_SUCCESS
} from '../../../actions/actionTypes';

const initialState = {
    startedAt: null,
    stoppedAt: null,
    baseTime: null
  };

const startedAt = (state = initialState.startedAt, action) => {
    switch(action.type) {
        case STOPWATCH_TIME_START:
            return action.now;
        case CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA_SUCCESS:
            return parseInt(action.parsedCheckin, 10);
        default:
            return state;
    }
};

const stoppedAt = (state = initialState.stoppedAt, action) => {
    switch(action.type) {
        case STOPWATCH_TIME_STOP:
            return action.now;
        case STOPWATCH_TIME_START:
            return null;
        default:
            return state;
    }
};

const baseTime = (state = initialState.baseTime, action) => {
    switch(action.type) {
        case STOPWATCH_TIME_START:
            return action.baseTime;
        case CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA_SUCCESS:
            return parseInt(action.parsedCheckin, 10);
        default:
            return state;
    }
}

const stopWatch = combineReducers({
    startedAt,
    stoppedAt,
    baseTime
});

export default stopWatch;