import { combineReducers } from 'redux';

//Actions
import {
    PRICING_BUDGET_UPDATE_FORM_JIRA_KEY_UPDATE,
    PRICING_BUDGET_UPDATE_FORM_BUDGET_UPDATE,
    PRICING_BUDGET_UPDATE_FORM_SUBMIT
} from '../actions/actionTypes';

let initialState = {
    account: null,
    budget: null
};

export const account = (state = initialState.account, action) => {
    switch(action.type) {
        case PRICING_BUDGET_UPDATE_FORM_JIRA_KEY_UPDATE:
            return action.value;
        case PRICING_BUDGET_UPDATE_FORM_SUBMIT:
            return null;
        default:
            return state;
    };
};
export const budget = (state = initialState.budget, action) => {
    switch(action.type) {
        case PRICING_BUDGET_UPDATE_FORM_BUDGET_UPDATE:
            return action.value;
        case PRICING_BUDGET_UPDATE_FORM_SUBMIT:
            return null;
        default:
            return state;
    }
}

const updatePricingForm = combineReducers({
    account,
    budget
});

export default updatePricingForm;