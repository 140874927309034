import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import { errorHandling } from '../../../../../../../utils/Errors/actions/index';

//Success functions
import { deleteRowSuccess } from '../actions/delete';

//Actions
import {
    RATINGS_TABLE_DELETE_ROW
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

export const rowDelete = (action$, state$) =>
    action$.pipe(
        ofType(RATINGS_TABLE_DELETE_ROW),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/user/rate?dateFrom=${ action.row.dateFrom }&username=${ action.row.username }`;

            const deletedRow = ajax({
                url: apiUrl,
                method: 'DELETE',
                headers: { 
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + action.token 
                }
            }).pipe(
                map(() => {
                    toast.success("Operazione effettuata con successo");
                        return deleteRowSuccess(action.token);
                }),
                catchError(error => {
                    toast.error("Errore inaspettato");
                    return of(errorHandling(error)) })
            );

            return deletedRow;
        })
    )

export default combineEpics(
    rowDelete
);