import {
    AUTH_TOKEN_CHECK
} from "./actionTypes";

export function validToken(authorizations, exp, iat, user) {
    return {
        type: AUTH_TOKEN_CHECK,
        userInfo: {
            user,
            authorizations,
            iat,
            exp
        }
    }
}