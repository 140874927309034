import {
    UPDATE_RATING_FORM_DATE_FROM,
    UPDATE_RATING_FORM_HOURLY_RATE,
    UPDATE_RATING_FORM_DEFAULT
} from './actionTypes';

export function updateFormField(field, value) {
    switch(field) {
        case 'dateFrom':
            return {
                type: UPDATE_RATING_FORM_DATE_FROM,
                value
            };
        case 'hourlyRate':
            return {
                type: UPDATE_RATING_FORM_HOURLY_RATE,
                value
            };
        default:
            return {
                type: UPDATE_RATING_FORM_DEFAULT
            };
    }
}