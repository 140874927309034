import {
    PRICING_GET_USER_LIST,
    PRICING_GET_USER_LIST_SUCCESS
} from './actionTypes';

export function userList(token) {
    return {
        type: PRICING_GET_USER_LIST,
        token
    }
};

export function userListSuccess(data) {
    return  {
        type: PRICING_GET_USER_LIST_SUCCESS,
        data
    }
};