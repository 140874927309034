import {
    ACCOUNT_COST_LIST_TABLE_ROW_DELETE
} from './actionTypes';

export function rowDelete(element, token) {
    return {
        type: ACCOUNT_COST_LIST_TABLE_ROW_DELETE,
        element,
        token
    }
};