import {
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_DATE,
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_INCLUDED_ACCOUNTS,
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_EXCLUDED_ACCOUNTS,
    PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_SUCCESS
} from './actionTypes';

export function accountPricingFormSubmit(formData, token) {
    return {
        type: PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_DATE,
        formData,
        token
    }
};

export function accountPricingFormSubmitIncludedElements(formData, token) {
    return {
        type: PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_INCLUDED_ACCOUNTS,
        formData,
        token
    }
};

export function accountPricingFormSubmitExcludedElements(formData, token) {
    return {
        type: PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_EXCLUDED_ACCOUNTS,
        formData,
        token
    }
};

export function accountPricingFormSubmitSuccess(results) {
    return {
        type: PRICING_ISSUES_WITHOUT_ACCOUNT_FILTER_FORM_SUBMIT_SUCCESS,
        results
    }
};