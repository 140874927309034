import { createEpicMiddleware } from 'redux-observable';
import { createStore, applyMiddleware } from 'redux';
import { rootEpic, rootReducer } from '../modules/root';
import { routerMiddleware } from 'connected-react-router';
import history from '../history';

//Components reducers

const epicMiddleware = createEpicMiddleware();
const appRouterMiddleware = routerMiddleware(history);
const preloadedState = {
    apiUrl: {
        services: '/api',
        auth: '/api',
        /** API DI PRODUZIONE COMMENTATE PERCHE STAGING UTILIZZA L'ENV DI PROD */
        podBaseURL: 'https://api.pod.camp/v1/dashboard',
        /** API DI STAGING */
        // podBaseURL: 'http://api-staging.pod.camp/v1/dashboard',
        badgeBoxAPIKey: '315a312ed7c209b0f4dd7a2d3e4ec741',
        badgeBoxAPISecret: '3e6737a81617396af3666f504f9aae2f'
    }
}

const store = createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(appRouterMiddleware, epicMiddleware)
);

epicMiddleware.run(rootEpic);

export default store;