import { combineReducers } from 'redux';

import {
    TIME_REPORT_SET_SELECTED_ROW
} from '../components/TimeReportTable/actions/actionTypes';

import {
    TIME_REPORT_FILTER_FORM_SUBMIT,
    TIME_REPORT_FILTER_FORM_SUBMIT_SUCCESS    
} from '../components/TimeReportFilterForm/actions/actionTypes';

let initialState = {
    isFetching: null,
    results: [],
    selectedRow: null
};

export const isFetching = (state = initialState.isFetching, action) => {
    switch(action.type) {
        case TIME_REPORT_FILTER_FORM_SUBMIT:
            return true;
        case TIME_REPORT_FILTER_FORM_SUBMIT_SUCCESS:
            return false;
        default:
            return state;
    }
};

export const results = (state = initialState.results, action) => {
    switch(action.type) {
        case TIME_REPORT_FILTER_FORM_SUBMIT_SUCCESS:
            return action.results;
        default:
            return state;
    }
}

export const selectedRow = (state = initialState.selectedRow, action) => {
    switch(action.type) {
        case TIME_REPORT_SET_SELECTED_ROW:
            return action.row;
        default:
            return state;
    }
};

const timeReport = combineReducers({
    isFetching,
    results,
    selectedRow
});

export default timeReport;