import {
    POD_CONFIG_SELECT_FACILITY,
    POD_CONFIG_SELECT_FACILITY_FETCH_DATA_SUCCESS
} from './actionTypes';

export function setFacility(id, token) {
    return {
        type: POD_CONFIG_SELECT_FACILITY,
        id,
        token
    };
};

export function facilityDataSuccess(facilityData) {
    return {
        type: POD_CONFIG_SELECT_FACILITY_FETCH_DATA_SUCCESS,
        facilityData
    }
}