import React from 'react';
import { connect } from 'react-redux';

import '@ant-design/compatible/assets/index.css';

//Libraries components
import { Form, Input, DatePicker, Button, Row, Col, Spin, Alert } from 'antd';
import moment from 'moment';

//Icons

//Style

//Actions
import { updateFormField } from './actions/updateFormField';
import { ratingsSubmit } from './actions/ratingsSubmit';
import { hideForm } from './actions/hideForm';

//Components

//Misc imports

class RatingFormRowUpdate extends React.Component {
    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.cancelRowUpdate = this.cancelRowUpdate.bind(this);
    }

    handleFormSubmit(formData, token) {
        const initialDate = moment(this.props.oldDateFrom).format('DD-MM-YYYY');
        this.props.submitData(formData, token, initialDate);
    }

    cancelRowUpdate() {
        this.props.hideUpdateForm();
    }

    render() {
        const { token, isStillLoading } = this.props;

        const formLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };

        return (
            <React.Fragment>
                { isStillLoading === true ? 
                    <Spin tip="Loading..." className="rm-loader-management">
                        <Alert
                            type="info"
                            className="rm-message-info"
                        />
                    </Spin> : 
                null }
                { isStillLoading === false ?
                    <Form 
                        className='rm-form' 
                        {...formLayout}
                        name='rating-form-row-update'
                        initialValues={{
                            username: this.props.username,
                            dateFrom: this.props.dateFrom,
                            hourlyRate: this.props.hourlyRate,
                        }}
                        onFinish={ values => {
                            Object.keys(values).forEach((key, index) => {
                                return this.props.updateField(key, 
                                    values[Object.keys(values)[index]] === undefined || 
                                    values[Object.keys(values)[index]] === null ? '' : 
                                    values[Object.keys(values)[index]]);
                            });
                            this.handleFormSubmit({
                                username: values.username,
                                dateFrom: values.dateFrom.format('DD-MM-YYYY'),
                                hourlyRate: values.hourlyRate.toString().replace(',', '.')
                            }, token);
                        } }>
                        <Row
                            type='flex'
                            justify='start'
                            align='middle'
                            gutter={ 20 }>
                            <Col
                                span={ 16 }
                                align='center'>
                                <Form.Item label='Utente'
                                name='username'>
                                    <Input placeholder='username' disabled={ true } />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row
                            type='flex'
                            justify='start'
                            align='middle'
                            gutter={ 20 }>
                            <Col
                                span={ 8 }
                                align='left'>
                                <Form.Item label='Da'
                                name='dateFrom'>
                                    <DatePicker format='DD/MM/YYYY' allowClear={ true } placeholder='dateFrom' />
                                </Form.Item>
                            </Col>
                            <Col
                                span={ 8 }
                                align='left'>
                                <Form.Item label='Costo'
                                    name='hourlyRate'>
                                    <Input placeholder='hourlyRate' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row
                            type='flex'
                            justify='start'
                            align='middle'
                            gutter={ 20 }>
                            <Col
                                span={ 8 }
                                align='left'>
                                <Form.Item>
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        <span>Conferma</span>
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col
                                span={ 8 }
                                align='left'>
                                <Form.Item>
                                    <Button
                                        type='danger'
                                        onClick={ () => {
                                            this.cancelRowUpdate();
                                        } }>
                                        <span>Annulla</span>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form> : 
                null }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        username: state.pricing.selectedRow.username,
        dateFrom: state.ratingEditForm.dateFrom,
        oldDateFrom: state.pricing.selectedRow.dateFrom,
        hourlyRate: state.ratingEditForm.hourlyRate,
        token: state.auth.token,
        isStillLoading: state.ratingEditForm.isStillLoading
    }
};

const mapDispatchToProps = dispatch => {
    const updateField = (key, value) => {
        dispatch(updateFormField(key, value));
    };

    const submitData = (formData, token, oldDateFrom) => {
        dispatch(ratingsSubmit(formData, token, oldDateFrom));
    };

    const hideUpdateForm = () => {
        dispatch(hideForm());
    };

    return {
        updateField,
        submitData,
        hideUpdateForm
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RatingFormRowUpdate);