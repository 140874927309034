import { combineReducers } from 'redux';

//Actions
import {
    MY_DIARY_GET_DIARY_SUCCESS,
    UPDATE_MY_DIARY_FILTER_RANGE
} from '../actions/actionTypes';

let initialState = {
    dateRange: null,
    rows: []
};

export const dateRange = (state = initialState.dateRange, action) => {
    switch(action.type) {
        case UPDATE_MY_DIARY_FILTER_RANGE:
            return action.value;
        default:
            return state;
    }
};

export const rows = (state = initialState.rows, action) => {
    switch(action.type) {
        case MY_DIARY_GET_DIARY_SUCCESS:
            return action.rows;
        default:
            return state;
    }
};

const diaryForm = combineReducers({
    dateRange,
    rows
});

export default diaryForm;