import {
    MANAGEMENT_FILTER_GET_CLIENTS,
    MANAGEMENT_FILTER_GET_CLIENTS_SUCCESS
} from './actionTypes';

export function getClients(token) {
    return {
        type: MANAGEMENT_FILTER_GET_CLIENTS,
        token
    }
}

export function getClientsSuccess(clients) {
    return {
        type: MANAGEMENT_FILTER_GET_CLIENTS_SUCCESS,
        clients
    }
}