import React from 'react';

//Libraries components

//Icons

//Style

//Actions

//Components
import { 
    columnsDefault, 
    columnsGroupByUser, 
    columnsGroupByIssues, 
    columnsGroupByBoth 
} from './columns';
import DashboardTable from '../../../../../../../../utils/DashboardTable/index';

//Misc imports

class AccountDetailTable extends React.Component {
    render() {
        const { data, grouping, tableHeight } = this.props;
        const parsedData = [...data.map((x, index) => {
            if(grouping === 'byAccount') {
                return {
                    key: index,
                    cost: x.value.cost,
                    hours: x.value.hours,
                    summary: x.value[0].issue.summary,
                    ...x
                }
            }
            if(grouping === 'byUser') {
                return {
                    key: index,
                    cost: x.totCost.cost,
                    hours: x.totHours.hours,
                    ...x
                }
            }
            if(grouping === 'byBoth') {
                return {
                    key: index,
                    fullName: x.user.fullName,
                    ...x
                }
            }
            return {
                key: index,
                ...x
            }
        })]
        
        return (
            <React.Fragment>
                { grouping === 'none' ?
                    <DashboardTable
                        tableHeight={ tableHeight }
                        columns={ columnsDefault }
                        data={ parsedData }
                        footer={ (currentPageData) => {
                            return (
                                // <div>
                                //     <span>Totale ore: {
                                //         Math.floor(currentPageData.reduce((a, b) => a + (b['hours'] || 0 ), 0)|| 0) + "h " + 
                                //         Math.floor(((currentPageData.reduce((a, b) => a + (b['hours'] || 0 ), 0) || 0) % 1) * 60) + "m" }</span><br />
                                //     <span>Totale costo: { currentPageData.reduce((a, b) => a + (b['cost'] || 0), 0) }</span>
                                // </div>
                                <div>
                                    <span>Totale ore: {
                                        Math.floor(parsedData.reduce((a, b) => a + (b['hours'] || 0 ), 0)|| 0) + "h " + 
                                        Math.floor(((parsedData.reduce((a, b) => a + (b['hours'] || 0 ), 0) || 0) % 1) * 60) + "m" }</span><br />
                                    <span>Totale costo: { parsedData.reduce((a, b) => a + (b['cost'] || 0), 0).toFixed(2).toString().replace('.', ',') }</span>
                                </div>
                            )
                        } }
                    /> :
                    null }
                { grouping === 'byUser' ?
                    <DashboardTable
                        tableHeight={ tableHeight }
                        columns={ columnsGroupByUser }
                        data={ parsedData }
                        footer={ (currentPageData) => {
                            return (
                                <div>
                                    <span>Totale ore: {
                                        Math.floor(parsedData.reduce((a, b) => a + (b['hours'] || 0 ), 0)|| 0) + "h " + 
                                        Math.floor(((parsedData.reduce((a, b) => a + (b['hours'] || 0 ), 0) || 0) % 1) * 60) + "m" }</span><br />
                                    <span>Totale costo: { parsedData.reduce((a, b) => a + (b['cost'] || 0), 0).toFixed(2).toString().replace('.', ',') }</span>
                                </div>
                            )
                        } }
                    /> :
                null }
                { grouping === 'byAccount' ? 
                    <DashboardTable
                        tableHeight={ tableHeight }
                        columns={ columnsGroupByIssues }
                        data={ parsedData }
                        footer={ (currentPageData) => {
                            return (
                                <div>
                                    <span>Totale ore: {
                                        Math.floor(parsedData.reduce((a, b) => a + (b['hours'] || 0 ), 0)|| 0) + "h " + 
                                        Math.floor(((parsedData.reduce((a, b) => a + (b['hours'] || 0 ), 0) || 0) % 1) * 60) + "m" }</span><br />
                                    <span>Totale costo: { parsedData.reduce((a, b) => a + (b['cost'] || 0), 0).toFixed(2).toString().replace('.', ',') }</span>
                                </div>
                            )
                        } }
                    /> :
                null }
                { grouping === 'byBoth' ?
                    <DashboardTable
                        tableHeight={ tableHeight }
                        columns={ columnsGroupByBoth }
                        data={ parsedData }
                        footer={ (currentPageData) => {
                            return (
                                <div>
                                    <span>Totale ore: {
                                        Math.floor(parsedData.reduce((a, b) => a + (b['hours'] || 0 ), 0)|| 0) + "h " + 
                                        Math.floor(((parsedData.reduce((a, b) => a + (b['hours'] || 0 ), 0) || 0) % 1) * 60) + "m" }</span><br />
                                    <span>Totale costo: { parsedData.reduce((a, b) => a + (b['cost'] || 0), 0).toFixed(2).toString().replace('.', ',') }</span>
                                </div>
                            )
                        } }
                    /> :
                null }
            </React.Fragment>
        );
    }
}

export default AccountDetailTable;