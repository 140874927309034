import {
    SUBMIT_RATING_FORM_ROW_UPDATE,
    SUBMIT_RATING_FORM_ROW_UPDATE_INSERT,
    SUBMIT_RATING_FORM_GET_UPDATED_RATES
} from './actionTypes';

export function ratingsSubmit(formData, token, initialDate) {
    return {
        type: SUBMIT_RATING_FORM_ROW_UPDATE,
        formData,
        initialDate,
        token
    }
}

export function deleteRowSuccess(info, token) {
    return {
        type: SUBMIT_RATING_FORM_ROW_UPDATE_INSERT,
        info,
        token
    }
}

export function updateRowSuccess(token) {
    return {
        type: SUBMIT_RATING_FORM_GET_UPDATED_RATES,
        token
    }
}