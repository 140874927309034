import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import history from '../../../history';
import { errorHandling } from '../../../utils/Errors/actions/index';
import { toast } from 'react-toastify';


import {
    HOME_SELECTED_ENTRY,
    HOME_LOGOUT,
    HOME_GET_UNASSIGNED_ISSUES
} from '../actions/actionTypes';

import { getUnassignedIssuesSuccess } from '../actions/unassignedIssues';

const selectEntry = (action$, state$) =>
    action$.pipe(
        ofType(HOME_SELECTED_ENTRY),
        mergeMap(action => {
            history.push('/home/' + action.componentId);
            return of({type: 'success'});
        })
    );

const handleLogout = (action$, state$) =>
    action$.pipe(
        ofType(HOME_LOGOUT),
        mergeMap(() => {
            history.push('/login');
            return of({type: 'success'});
        })
    );

const unassignedIssues = (action$, state$) =>
    action$.pipe(
        ofType(HOME_GET_UNASSIGNED_ISSUES),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/issue/without-account/count/`;

            const issues = ajax
                .getJSON(apiUrl, { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        return getUnassignedIssuesSuccess(data);
                    }),
                    catchError(error => { 
                        toast.error("Errore nel fetch delle issue senza account");
                        return of(errorHandling(error)) 
                    })
                );

            return issues;
        })
    );

export default combineEpics(
    selectEntry,
    handleLogout,
    unassignedIssues,
    unassignedIssues
);