import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import {
    Spin,
    Row,
    Col,
    Alert,
    Tabs,
} from 'antd';

//Icons

//Style
import './style.scss';

//Actions
import { fetchInitialData } from './actions/fetchInitialData';
import { getRates } from './actions/requestUserRates';

//Components
import PricingTable from './components/PricingTable/PricingTable';
import RatingsTable from './components/RatingsTable/RatingsTable';
import PricingFormToUpdate from './components/PricingForm/PricingForm';
import RatingFormRowUpdate from './components/RatingEditForm/RatingEditForm';
import AccountPricingForm from './components/AccountPricingForm/AccountPricingForm';
import IssuesFilterForm from './components/IssuesWithoutAccountFilter/IssuesWithoutAccountFilter';
import AccountPricingAccordion from './components/AccountPricingAccordion/AccountPricingAccordion';
import PricingForm from './components/PricingTable/components/updatePricingForm/index';

//Misc imports

class Pricing extends React.Component {
    constructor(props) {
        super(props);

        this.getUserRates = this.getUserRates.bind(this);
    }
    componentDidMount() {
        this.props.getInitialData(this.props.token);
    }

    getUserRates() {
        this.props.requestUserRates(this.props.token);
    }

    render() {
        const { 
            isFetching, 
            results, 
            rates, 
            isEditFormOpen, 
            selectedRow, 
            costList,
            authorizations 
        } = this.props;

        return (
            <React.Fragment>
                { isFetching === true ?
                    <Spin tip="Loading..." className="rm-loader-management">
                        <Alert
                            description="Stiamo riordinando i dati"
                            type="info"
                            className="rm-message-info"
                        />
                    </Spin> :
                null }
                { results.length && isFetching === false ?
                    <div>
                        <Tabs onTabClick={ e => {
                            if(e === '1') {
                                this.getUserRates();
                            }
                            } }>
                            { authorizations.indexOf("DCG Costi") > -1 ? 
                            <Tabs.TabPane tab="Account" key={ 0 }>
                                <Row
                                    className="rm-full-container">
                                    <Col
                                        span={ 24 }
                                        align="left">
                                        <PricingForm accountList={ results } style={{ marginBottom: '20px' }} />
                                    </Col>
                                    <Col
                                        span={ 24 }
                                        align="left">
                                            <PricingTable data={ results } />
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                            : null }
                            { authorizations.indexOf("DCG Costi") > -1 ? 
                            <Tabs.TabPane tab="Costi utente" key={ 1 }>
                                <Row>
                                    <Col
                                        span={ 24 }
                                        align="left">
                                            <PricingFormToUpdate />
                                    </Col>
                                    <Col
                                        xs={ 24 }
                                        sm={ 24 }
                                        md={ 24 }
                                        lg={ 14 }
                                        xl={ 16 }
                                        align="left">
                                            <RatingsTable data={ rates } />
                                    </Col>
                                    { isEditFormOpen === true && selectedRow ?
                                        <Col
                                            xs={ 24 }
                                            sm={ 24 }
                                            md={ 24 }
                                            lg={ 10 }
                                            xl={ 8 }
                                            align='left'
                                            className='rm-full-container'
                                            style={{ paddingLeft: '60px', paddingRight: '60px' }}>
                                                <RatingFormRowUpdate row={ selectedRow } />
                                        </Col> :
                                        null
                                    }
                                </Row>
                            </Tabs.TabPane>
                            : null }
                            <Tabs.TabPane tab="Costi account" key={ 2 }>
                                <Row>
                                    <Col
                                        span={ 24 }
                                        align='left'
                                        style={{ paddingLeft: '60px', paddingRight: '60px' }}>
                                            <AccountPricingForm />
                                    </Col>
                                    <Col
                                        span={ 24 }
                                        align='left'
                                        className='rm-full-container'
                                        style={{ paddingLeft: '60px', paddingRight: '60px' }}>
                                            { costList && costList.length ? 
                                                <AccountPricingAccordion data={ costList } /> : 
                                            null }
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                            { authorizations.indexOf("DCG Costi") > -1 ? 
                            <Tabs.TabPane tab="Filtri per issue senza account" key={ 3 }>
                                <IssuesFilterForm />
                            </Tabs.TabPane>
                            : null }
                        </Tabs>
                    </div> :
                null }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        results: state.pricing.results,
        token: state.auth.token,
        authorizations: state.auth.userInfo.authorizations,
        isFetching: state.pricing.isFetching,
        rates: state.pricing.rates,
        selectedRow: state.pricing.selectedRow,
        isEditFormOpen: state.pricing.isEditFormOpen,
        costList: state.pricing.costList
    }
}

const mapDispatchToProps = dispatch => {
    const getInitialData = token => {
        dispatch(fetchInitialData(token));
    };

    const requestUserRates = token => {
        dispatch(getRates(token));
    };

    return {
        getInitialData,
        requestUserRates
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Pricing);