import React from 'react';

//Libraries components
import { Row, Col } from 'antd';

//Icons

//Style

//Actions

//Components
import { columns } from './columns';
import DashboardTable from '../../../../../../utils/DashboardTable';

//Misc imports

class TimeReportTableDetail extends React.Component {
    render() {
        const { data } = this.props;
        const parsedData = [...data.diaries.map((x, index) => {
            return {
                key: index,
                ...x
            }
        })];
        return (
            <React.Fragment>
                <Row
                    type="flex"
                    align="middle"
                    justify="center"
                    gutter={ 20 }>
                        <h3 style={{ marginTop: '-33px' }}>{ data.userFullName }</h3>
                </Row>
                <Row
                    type="flex"
                    align="middle"
                    gutter={ 20 }>
                    <Col span={ 24 }>
                        <DashboardTable 
                            data={ parsedData } 
                            columns={ columns }
                            tableHeight={ '45vh' }
                            footer={currentPageData => {
                                return (
                                    <div>
                                        <span>Totale ore: {
                                            Math.floor(currentPageData.reduce((a, b) => a + (b['presenceHours'] || 0 ), 0)|| 0) + "h " + 
                                            Math.floor(((currentPageData.reduce((a, b) => a + (b['presenceHours'] || 0 ), 0) || 0) % 1) * 60) + "m" } || </span>
                                        <span>Totale straordinari: { 
                                            Math.floor(currentPageData.reduce((a, b) => a + (b['overtimeHours'] || 0 ), 0)|| 0) + "h " + 
                                            Math.floor(((currentPageData.reduce((a, b) => a + (b['overtimeHours'] || 0 ), 0) || 0) % 1) * 60) + "m" } || </span>
                                        <span>Totale diario: { 
                                            Math.floor(currentPageData.reduce((a, b) => a + (b['diaryHours'] || 0 ), 0)|| 0) + "h " + 
                                            Math.floor(((currentPageData.reduce((a, b) => a + (b['diaryHours'] || 0 ), 0) || 0) % 1) * 60) + "m" }</span>
                                    </div>
                                );
                            }}
                            pagination={{ 
                                hideOnSinglePage: true,
                                pageSize: 50 
                            }} />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
};

export default TimeReportTableDetail;