import React from 'react';

import '@ant-design/compatible/assets/index.css';

//Libraries components
import { Form, Input, Col, Row, Button } from 'antd';

//Icons

//Style

//Actions

//Components

//Misc imports

class CustomLoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.mailParser = this.mailParser.bind(this);
    }

    mailParser(rule, value) {
        if(!value) {
            return Promise.reject("La mail è obbligatoria");
        }
        if(!value.includes('@')) {
            return Promise.reject("Devi inserire una mail");
        }
        if(!value.includes('@remedia')) {
            return Promise.reject("La mail deve essere valida");
        }

        return Promise.resolve();
    }
    
    render() {
        const { handleFormSubmit } = this.props;

        const formLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };

        return (
            <Form 
                {...formLayout}
                id='login-form'
                name='login-form'
                onFinish={ values => {
                    handleFormSubmit({
                        username: values.username,
                        password: values.password
                    });
                } }>
                <Row
                    type="flex"
                    justify="space-around"
                    align="middle">
                    <Col
                        span={12}
                        justify="space-around"
                        align="middle">
                        <Form.Item label="Email"
                            name='username'
                            rules={[{ 
                                required: true,
                                validator: this.mailParser }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    type="flex"
                    justify="space-around"
                    align="middle">
                    <Col
                        span={12}
                        justify="space-around"
                        align="middle">
                        <Form.Item label="Password"
                            name='password'
                            rules={[{ required: true, message: 'è necessario inserire una password valida' }]}>
                                <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>
                <Row
                    type="flex" 
                    justify="space-around" 
                    align="middle">
                    <Col 
                        span={ 12 }  
                        type="flex" 
                        justify="space-around" 
                        align="middle">
                        <Button type="primary" htmlType="submit">
                            <span>Login</span>
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
};

export default CustomLoginForm;