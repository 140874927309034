import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import history from '../../../../../history';
import { errorHandling } from '../../../../../utils/Errors/actions/index';

//Success functions
import { rowSelectSuccess, getIssuesByUserSuccess } from '../actions/rowSelect';

//Actions
import {
    MANAGEMENT_SELECTED_ROW
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

const rowSelect = (action$, state$) =>
    action$.pipe(
        ofType(MANAGEMENT_SELECTED_ROW),
        mergeMap(action => {
            let stringedIds = [...action.formData.jiraKeys.map(key => {
                return JSON.stringify(key);
            })];
            const apiUrl = `${ state$.value.apiUrl.services }/cost/?accountKeys=[${ stringedIds }]&status=${ action.formData.status }&dateFrom=${ action.formData.dateFrom }&dateTo=${ action.formData.dateTo }&group=${ true }`;
            
            history.push(`/home/account-detail/${ action.formData.jiraKeys }`)

            const token = ajax
                .getJSON(apiUrl, { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                    const parsedData = data.map((element, index) => {
                        return {
                            key: index,
                            formattedHours: 
                                Math.floor(element.issueHours || 0) + 'h ' +
                                Math.floor(((element.issueHours || 0) % 1) * 60) + "m",
                            parsedLead: element.lead.fullName,
                            issueJiraKey: element.customer.jiraKey,
                            ...element
                        }
                    })
                        
                    return rowSelectSuccess(parsedData);
                    }),
                    catchError(error => { 
                        toast.error("Errore durante la selezione della riga");
                        return of(errorHandling(error)) })
                );

            return token;
        })
    );

const rowSelectIssues = (action$, state$) =>
    action$.pipe(
        ofType(MANAGEMENT_SELECTED_ROW),
        mergeMap(action => {
            let stringedIds = [...action.formData.jiraKeys.map(key => {
                return JSON.stringify(key);
            })];
            const apiUrl = `${ state$.value.apiUrl.services }/cost/?accountKeys=[${ stringedIds }]&status=${ action.formData.status }&dateFrom=${ action.formData.dateFrom }&dateTo=${ action.formData.dateTo }&group=${ false }&withoutIssues=${ false }`;
            const token = ajax
                .getJSON(apiUrl, { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        const parsedData = data.map((element, index) => {
                            return {
                                assignedUser: element.user.fullName,
                                key: index,
                                issueJiraKey: element.issue.jiraKey,
                                ...element
                            }
                        });
                        return getIssuesByUserSuccess(parsedData);
                    }),
                    catchError(error => { 
                        toast.error("Errore durante la selezione delle riga");
                        return of(errorHandling(error)) })
                );

            return token;
        })
    )

export default combineEpics(
    rowSelect,
    rowSelectIssues

);