import { combineReducers } from 'redux';

//Actions
import {
    COST_CALC_ADD_ROW_FORM_SUBMIT
} from '../actions/actionTypes';

import {
    COST_CALC_REMOVE_ROW
} from '../../CurrentTable/actions/actionTypes';

let initialState = {
    formData: null,
    rows: []
};

const formData = (state = initialState.formData, action) => {
    switch(action.type) {
        case COST_CALC_ADD_ROW_FORM_SUBMIT:
            return action.formData;
        default:
            return state;
    }
};

const rows = (state = initialState.rows, action) => {
    switch(action.type) {
        case COST_CALC_ADD_ROW_FORM_SUBMIT:
            return action.rows;
        case COST_CALC_REMOVE_ROW:
            return action.rows;
        default:
            return state;
    }
};

const costCalcFormAddRow = combineReducers({
    formData,
    rows
});

export default costCalcFormAddRow;

