import {
    TIME_REPORT_FILTER_FORM_UPDATE_DATE_RANGE,
    TIME_REPORT_FILTER_FORM_UPDATE_ONLY_ERRORS,
    TIME_REPORT_FILTER_FORM_UPDATE_DEFAULT
} from './actionTypes';

export function updateFormField(key, value) {
    switch(key) {
        case 'range':
            return {
                type: TIME_REPORT_FILTER_FORM_UPDATE_DATE_RANGE,
                value
            };
        case 'onlyErrors':
            return {
                type: TIME_REPORT_FILTER_FORM_UPDATE_ONLY_ERRORS,
                value
            };
        default:
            return {
                type: TIME_REPORT_FILTER_FORM_UPDATE_DEFAULT
            };
    }
};