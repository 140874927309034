import { combineReducers } from 'redux';

//Actions
import {
    UPDATE_MANAGEMENT_FILTER_CLIENT,
    UPDATE_MANAGEMENT_FILTER_RANGE,
    UPDATE_MANAGEMENT_FILTER_STATUS,
    MANAGEMENT_FILTER_GET_CLIENTS_SUCCESS,
    MANAGEMENT_FILTER_FORM_SUBMIT
} from '../actions/actionTypes';

import {
    MANAGEMENT_FILTER_SUBMIT_SUCCESS_NO_GROUPING
} from '../../../actions/actionTypes';

let initialState = {
    clientJiraKey: '',
    leadUsername: '',
    status: 'OPEN',
    range: '',
    clients: [],
    isStillFetching: false
};

export const clientJiraKey = (state = initialState.clientJiraKey, action) => {
    switch(action.type) {
        case UPDATE_MANAGEMENT_FILTER_CLIENT:
            return action.value;
        default:
            return state;
    }
};

export const status = (state = initialState.status, action) => {
    switch(action.type) {
        case UPDATE_MANAGEMENT_FILTER_STATUS:
            return action.value;
        default:
            return state;
    }
};

export const range = (state = initialState.range, action) => {
    switch(action.type) {
        case UPDATE_MANAGEMENT_FILTER_RANGE:
            return action.value;
        default:
            return state;
    }
};

export const clients = (state = initialState.clients, action) => {
    switch(action.type) {
        case MANAGEMENT_FILTER_GET_CLIENTS_SUCCESS:
            return action.clients;
        default:
            return state;
    }
};

export const isStillFetching = (state = initialState.isStillFetching, action) => {
    switch(action.type) {
        case MANAGEMENT_FILTER_FORM_SUBMIT:
            return true;
        case MANAGEMENT_FILTER_SUBMIT_SUCCESS_NO_GROUPING:
            return false;
        default:
            return state;
    }
}

const managementFilters = combineReducers({
    clientJiraKey,
    status,
    range,
    clients,
    isStillFetching
});

export default managementFilters;

