import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import { Spin, Row, Col, Alert } from 'antd';
import { toast } from 'react-toastify';

//Icons

//Style
import './style.scss';

//Actions
import { rowSelect } from './actions/rowSelect';
import { setSelectedRows } from './actions/selectedRows';

//Components
import FiltersForm from './components/FiltersForm/FiltersForm';
import ManagementTable from './components/ManagementTable/ManagementTable';

//Misc imports

class Management extends React.Component {
    constructor(props) {
        super(props);

        this.onRowSelect = this.onRowSelect.bind(this);
    }

    onRowSelect(key, row, submit) {
        this.props.updateSelectedRowArray(row);
        if(row.length > 5) {
            toast.error('Non è possibile selezionare più di 5 account');
        } else if(submit) {
            let keys = [];
            for(let k in row) {
                keys.push(row[k].jiraKey);
            }
            const formData = {
                clientJiraKey: this.props.formData.clientJiraKey,
                status: this.props.formData.status,
                dateFrom: this.props.formData.range && this.props.formData.range.length ? this.props.formData.range[0].format('DD-MM-YYYY') : '',
                dateTo: this.props.formData.range && this.props.formData.range.length ? this.props.formData.range[1].format('DD-MM-YYYY') : '',
                jiraKeys: keys
            };
            this.props.rowSelection(row[0], this.props.token, formData);
        }
    }

    render() {
        const { results, isFetching, selectedRows } = this.props;
        return (
            <React.Fragment>
                <FiltersForm selectedRows={ selectedRows } onRowSelect={ this.onRowSelect } />
                { isFetching === true ? 
                    <Spin tip="Loading..." className="rm-loader-management">
                        <Alert 
                            message="Ridurre i tempi di attesa"
                            description="Potrebbe essere una buona idea considerare dei filtri più stringenti, cosi da ridurre i tempi di caricamento." 
                            type="info"
                            className="rm-message-info"
                        />
                    </Spin> : 
                null }
                { results.length && isFetching === false ?
                    <div>
                        <Row>
                            <Col 
                                span={ 24 }
                                align="left"
                                className="rm-container">
                                <ManagementTable data={ results } rowSelect={ this.onRowSelect } />
                            </Col>
                            <Col 
                                span={ 8 }
                                align="left"
                                style={{ marginTop: '-40px' }}>
                                <span className='rm-result-spacing'>Son stati trovati { results.length } risultati.</span>
                            </Col>
                        </Row>
                    </div> :
                    null
                }
                { !results.length && isFetching === false ?
                    <Row
                        type="flex"
                        justify="start"
                        align="middle">    
                    </Row> :
                    null
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        results: state.management.results,
        isFetching: state.management.isFetching,
        selectedRows: state.management.selectedRows,
        token: state.auth.token,
        formData: { 
            clientJiraKey: state.managementFilters.clientJiraKey,
            range: state.managementFilters.range,
            status: state.managementFilters.status
        },
        selectedIssuesByAccount: state.management.selectedIssuesByAccount
    }
};

const mapDispatchToProps = dispatch => {
    const rowSelection = (row, token, formData) => {
        dispatch(rowSelect(row, token, formData));
    };

    const updateSelectedRowArray = selectedRows => {
        dispatch(setSelectedRows(selectedRows));
    } 

    return {
        rowSelection,
        updateSelectedRowArray
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Management);