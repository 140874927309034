import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import { Row, Col, Card } from 'antd';

//Icons

//Style

//Actions

//Components

//Misc imports

class RecapAndEditForm extends React.Component {
    render() {
        const { rows, userRates } = this.props;
        
        let data = [];
        for(let i in userRates) {
            data.push({ 
                name: i, 
                currentRate: userRates[i][userRates[i].length -1] 
            });
        }

        return(
            <React.Fragment>
            <Row
                type='flex'
                justify='start'
                align='middle'
                gutter={ 20 }>
                <Col span={ 24 }>
                    <Card style={{ width: '100%' }}>
                        <Row
                            type='flex'
                            justify='start'
                            align='middle'
                            gutter={ 20 }>
                            <Col
                                span={ 11 }
                                align='left'>
                                <span>Ore totali: { !rows || !rows.length ? '0' : rows.reduce((a, b) => parseFloat(a) + (parseFloat(b['hours']) || 0), 0) }</span>
                            </Col>
                            <Col
                                span={ 11 }
                                align='right'>
                                <span>Costo totale: { rows && rows.length >= 2 ? rows.reduce((a, b) => {
                                        return parseFloat(a) + ((parseFloat(b['hours']) || 0) * data.find(x => x.name === b['value']).currentRate.hourlyRate);
                                    }, 0).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : null }</span>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row
                type='flex'
                justify='start'
                align='middle'
                gutter={ 20 }>
                <Col span={ 24 }>
                </Col>
            </Row>
            </React.Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        rows: state.costCalcFormAddRow.rows,
        userRates: state.costCalc.userRates
    };
};

const mapDispatchToProps = dispatch => {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecapAndEditForm);