import React from 'react';
import { connect } from 'react-redux';

import '@ant-design/compatible/assets/index.css';

//Libraries components
import { Form, Button, Row, Col, Select, DatePicker, Spin, Alert } from 'antd';
import moment from 'moment';

//Icons

//Style

//Actions
import { getProjects } from './actions/getProjects';
import { getCurrentFilters } from './actions/getCurrentFilters';
import { updateFormField } from './actions/updateFormField';
import { accountPricingFormSubmit } from './actions/accountPricingFormSubmit';

//Components

//Misc imports

class AccountPricingForm extends React.Component {
    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentDidMount() {
        const token = this.props.token;
        this.props.getProjectList(token);
        this.props.getFilterList(token);
    }

    handleFormSubmit(formData, token) {
        this.props.submitData(formData, token);
    }

    render() {
        const { projects, counted, notCounted, dateFrom, isStillLoading } = this.props;

        const formLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };

        return (
            <React.Fragment>
                { isStillLoading === true ? 
                    <Spin tip="Loading..." className="rm-loader-management">
                        <Alert
                            type="info"
                            className="rm-message-info"
                        />
                    </Spin> : 
                null }
                { isStillLoading !== true ?
                    <Form 
                        {...formLayout}
                        className='rm-form'
                        name='account-pricing-form'
                        initialValues={{
                            counted: counted,
                            notCounted: notCounted,
                            dateFrom: dateFrom ? dateFrom : ''
                        }}
                        onFinish={ values => {
                            const formData = {
                                counted: values.counted,
                                notCounted: values.notCounted,
                                dateFrom: values.dateFrom ? values.dateFrom.format('YYYY-MM-DD') : values.dateFrom
                            };
                            Object.keys(values).forEach((key, index) => {
                                return this.props.updateField(key, 
                                    values[Object.keys(values)[index]] === undefined || 
                                    values[Object.keys(values)[index]] === null ? '' : 
                                    values[Object.keys(values)[index]]);
                            });
                            this.handleFormSubmit(formData, this.props.token);
                        } }>
                        <Row
                            type='flex'
                            justify='start'
                            align='middle'
                            gutter={ 20 }>
                            <Col
                                span={ 6 }
                                align='left'>
                                <Form.Item label='Progetti da includere'
                                name='counted'>
                                    <Select 
                                        allowClear={ true }
                                        mode="tags"
                                        tokenSeparators={[',']}
                                        showSearch
                                        filterOption={ (inputValue, option) => {
                                            //Necessario per estendere il supporto a quello schifo di ie, altrimenti sostituire indexOf con includes
                                            return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                        } }>
                                        { projects !== null ? projects.map((x, index) => {
                                            return (
                                                <Select.Option value={ x.id.toString() } key={ x.key }>
                                                    { x.label }
                                                </Select.Option> );
                                        }) : null }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                span={ 6 }
                                align='left'>
                                <Form.Item 
                                    label='Progetti da escludere'
                                    name='notCounted'>
                                    <Select 
                                        allowClear={ true }
                                        mode="tags"
                                        tokenSeparators={[',']}>
                                        { projects !== null ? projects.map((x, index) => {
                                            return (
                                                <Select.Option value={ x.id.toString() } key={ x.key }>
                                                    { x.label }
                                                </Select.Option> );
                                        }) : null }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col
                                span={ 6 }
                                align='left'>
                                <Form.Item label='Data inizio'
                                    name='dateFrom'
                                    rules={[{ required: true, message: 'La data è obbligatoria' }]}>
                                    <DatePicker format='DD/MM/YYYY' allowClear={ true } placeholder='gg/mm/aaaa' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row
                        type='flex'
                        justify='start'
                        align='middle'
                        gutter={ 20 }>
                            <Col
                                span={ 6 }
                                align='left'>
                                    <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                    <span>Aggiungi</span>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form> : null }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        counted: state.issuesWithoutAccount.counted,
        notCounted: state.issuesWithoutAccount.notCounted,
        dateFrom: state.issuesWithoutAccount.dateFrom ? moment(state.issuesWithoutAccount.dateFrom) : null,
        projects: state.issuesWithoutAccount.projects,
        token: state.auth.token,
        isStillLoading: state.issuesWithoutAccount.isStillLoading
    };
}

const mapDispatchToProps = dispatch => {
    const getProjectList = token => {
        dispatch(getProjects(token));
    };

    const updateField = (key, value) => {
        dispatch(updateFormField(key, value));
    };

    const submitData = (formData, token) => {
        dispatch(accountPricingFormSubmit(formData, token));
    };

    const getFilterList = token => {
        dispatch(getCurrentFilters(token));
    };

    return {
        getProjectList,
        updateField,
        submitData,
        getFilterList
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPricingForm)