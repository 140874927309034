import { combineReducers } from 'redux';

//Actions
import {
    MY_DIARY_SUBMIT_FORM_DATA,
    MY_DIARY_SUBMIT_FORM_DATA_SUCCESS
} from '../components/FilterForm/actions/actionTypes';

let initialState = {
    results: [],
    isFetching: null
};

export const results = (state = initialState.results, action) => {
    switch(action.type) {
        case MY_DIARY_SUBMIT_FORM_DATA_SUCCESS:
            return action.results;
        default:
            return state;
    }
};

export const isFetching = (state = initialState.isFetching, action) => {
    switch(action.type) {
        case MY_DIARY_SUBMIT_FORM_DATA_SUCCESS:
            return false;
        case MY_DIARY_SUBMIT_FORM_DATA:
            return true;
        default:
            return state;
    }
}

const myDiary = combineReducers({
    results,
    isFetching
});

export default myDiary;