import React from 'react';
import { 
    ContactsOutlined, 
    BookOutlined, 
    MonitorOutlined, 
    EuroCircleOutlined, 
    LogoutOutlined,
    CalculatorOutlined,
    ControlOutlined
} from '@ant-design/icons';

export const menu = [
{
    "key": 0,
    "icon": <ContactsOutlined />,
    "label": "Presenze",
    "componentId": "attendance-list",
    "authLevel": ["DCG Presenze"],
    "policy": ""
},{
    "key": 1,
    "icon": <BookOutlined />,
    "label": "Il mio diario",
    "componentId": "my-diary",
    "authLevel": ["DCG Users"],
    "policy": ""
},
{
    "key": 2,
    "icon": <MonitorOutlined />,
    "label": "Controllo gestione",
    "componentId": "management",
    "authLevel": ["DCG Controllo Gestione"],
    "policy": ""
},{
    "key": 3,
    "icon": <CalculatorOutlined />,
    "label": "Preventivo",
    "componentId": "cost-calculation",
    "authLevel": ["DCG Costi", "DCG Controllo Gestione"],
    "policy": "OR"
},{
    "key": 4,
    "icon": <EuroCircleOutlined />,
    "label": "Costi",
    "componentId": "pricing",
    "authLevel": ["DCG Costi", "DCG Acquisti"],
    "policy": "OR"
},{
    "key": 5,
    "icon": <ControlOutlined />,
    "label": "Gestione POD.CAMP",
    "componentId": "pod-management",
    "authLevel": ["DCG Operatore Podcamp"],
    "policy": ""
},{
    "key": 10,
    "icon": <LogoutOutlined />,
    "label": "Logout",
    "componentId": "logout",
    "authLevel": ["DCG All"],
    "policy": ""
}]