import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import { errorHandling } from '../../../utils/Errors/actions/index';

//Success functions
import { fetchIssuesSuccess } from '../actions/fetchIssues';

//Actions
import {
    ISSUES_WITHOUT_ACCOUNT_PAGE_FETCH_INITIAL_DATA
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

export const fetchIssues = (action$, state$) =>
    action$.pipe(
        ofType(ISSUES_WITHOUT_ACCOUNT_PAGE_FETCH_INITIAL_DATA),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/issue/without-account`;

            const issues = ajax
                .getJSON(apiUrl, { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        const parsedData = [...data.map((x, index) => {
                            return {
                                key: index,
                                creatorFullName: x.creator.fullName,
                                projectName: x.project.name,
                                epicSummary: x.epic.summary,
                                parsedIssueSummary: x.jiraKey + ' --- ' + x.summary,
                                ...x
                            }
                        })]
                        return fetchIssuesSuccess(parsedData);
                    }),
                    catchError(error => {
                        toast.error("Errore inaspettato");
                        return of(errorHandling(error)) })
                );

            return issues;
        })
    );

export default combineEpics(
    fetchIssues
);