import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import { Card, Row, Col, Spin, Alert } from 'antd';

//Style
import './style.scss';

//Actions
import { loginSubmit } from './actions/loginSubmit';
import { loginSubmitSuccess } from './actions/loginSubmitSuccess';

//Components
import LoginForm from './components/LoginForm/index';
import history from '../../history';

//Misc imports
import logo from '../../assets/images/logoReMedia.png';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmitFormInfo = this.handleSubmitFormInfo.bind(this);
    }

    componentDidMount() {
        if(this.props.token !== '' && localStorage.getItem('rm-dash-token') !== null) {
            history.push('/home');
        } else if(localStorage.getItem('rm-dash-token') !== null) {
            this.props.loginSuccess(localStorage.getItem('rm-dash-token'));
        }
    }
    
    componentDidUpdate() {
        if(this.props.token !== '') {
            history.push('/home');
        }
    }

    handleSubmitFormInfo(formData) {
        this.props.requestLoginSubmit(formData);
    }

    render() {
        const { isLoading } = this.props;
        return (
            <React.Fragment>
                <div id="container">
                    <Row
                        type="flex"
                        justify="space-around"
                        align="middle"
                        className={'height-100'}>
                            <Col
                                type="flex"
                                justify="space-around"
                                align="middle"
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}>
                                    <Card title={<img alt="logo" src={ logo } />} className={'rm-card'}>
                                        { isLoading ? 
                                        <Spin tip="Loading..." className="rm-loader-login">
                                            <Alert 
                                                message=""
                                                description="" 
                                                type="info"
                                                className="rm-message-info-login"
                                            />
                                        </Spin> : 
                                        <LoginForm handleFormSubmit={ this.handleSubmitFormInfo }/>}
                                    </Card>
                                </Col>
                        </Row>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.login.token,
        isLoading: state.login.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    const requestLoginSubmit = formData => {
        dispatch(loginSubmit(formData));
    };

    const loginSuccess = token => {
        dispatch(loginSubmitSuccess(token));
    };

    return {
        requestLoginSubmit,
        loginSuccess
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);