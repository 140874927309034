import {
    ACCORDION_UPDATE_ERP_KEY,
    ACCORDION_UPDATE_DATE,
    ACCORDION_UPDATE_DATE_LAST_UPDATE,
    ACCORDION_UPDATE_DESCRIPTION,
    ACCORDION_UPDATE_COST,
    ACCORDION_UPDATE_DEFAULT
} from './actionTypes';

export function updateFormField(key, value) {
    switch(key) {
        case 'erpKey':
            return {
                type: ACCORDION_UPDATE_ERP_KEY,
                value
            };
        case 'date':
            return {
                type: ACCORDION_UPDATE_DATE,
                value
            };
        case 'dateUpdate':
            return {
                type: ACCORDION_UPDATE_DATE_LAST_UPDATE,
                value
            };
        case 'description':
            return {
                type: ACCORDION_UPDATE_DESCRIPTION,
                value
            };
        case 'cost':
            return {
                type: ACCORDION_UPDATE_COST,
                value
            };
        default:
            return {
                type: ACCORDION_UPDATE_DEFAULT
            };
    }
};