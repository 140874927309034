import { combineReducers } from 'redux';

import {
    CHECKIN_CHECKOUT_CHANGE_USER_STATE,
    CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA_SUCCESS,
    CHECKIN_CHECKOUT_GET_CURRENT_USER_CHECKIN_LIST_SUCCESS,
    CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA,
    CHECKIN_CHECKOUT_GET_ALL_EMPLOYEES_CHECKIN_SUCCESS
} from '../actions/actionTypes';

let initialState = {
    userState: false,
    checkinList: [],
    currentUserId: null,
    employees: [],
    statusList: []
};

const userState = (state = initialState.userState, action) => {
    switch(action.type) {
        case CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA_SUCCESS:
            return action.currentStatus;
        case CHECKIN_CHECKOUT_CHANGE_USER_STATE:
            return action.userState;
        default:
            return state;
    }
};

const checkinList = (state = initialState.checkinList, action) => {
    switch(action.type) {
        case CHECKIN_CHECKOUT_GET_CURRENT_USER_CHECKIN_LIST_SUCCESS:
            return action.parsedCheckinList;
        default:
            return state;
    }
};

const currentUserId = (state = initialState.currentUserId, action) => {
    switch(action.type) {
        case CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA:
            return action.currentUserId;
        default:
            return state;
    }
};

const employees = (state = initialState.employees, action) => {
    switch(action.type) {
        case CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA:
            return action.employees;
        default:
            return state;
    }
};

const statusList = (state = initialState.statusList, action) => {
    switch(action.type) {
        case CHECKIN_CHECKOUT_GET_ALL_EMPLOYEES_CHECKIN_SUCCESS:
            return [...state.map(element => element), action.employee];
        default:
            return state;
    }
}

const checkinCheckout = combineReducers({
    userState,
    checkinList,
    currentUserId,
    employees,
    statusList
});

export default checkinCheckout;