import {
    CHECKIN_CHECKOUT_REQUEST_CHECKIN,
    CHECKIN_CHECKOUT_REQUEST_CHECKOUT
} from './actionTypes';

export function doCheckin(currentUser, latitude, longitude, date) {
    return {
        type: CHECKIN_CHECKOUT_REQUEST_CHECKIN,
        currentUser,
        latitude,
        longitude,
        date
    }
}

export function doCheckout(currentUser, latitude, longitude, date) {
    return {
        type: CHECKIN_CHECKOUT_REQUEST_CHECKOUT,
        currentUser,
        latitude,
        longitude,
        date
    }
}