import {
    ACCOUNT_COST_LIST_TABLE_ROW_UPDATE
} from './actionTypes';

export function rowUpdate(row, token) {
    return {
        type: ACCOUNT_COST_LIST_TABLE_ROW_UPDATE,
        row,
        token
    }
};