import React from 'react';
import { Route, Switch } from 'react-router';

//Actions

//Components
import Login from '../components/Login/index';
import Home from '../components/Home/index';
import Authorize from '../utils/Authorize/index';

//Style
import './App.scss';

class App extends React.Component {

    render() {
        return (
            <Switch>
                <Route exact path='/' component={ Login } />
                <Route exact path='/login' component={ Login } />
                <Route exact path='/home' component={ Authorize(Home) } />
                <Route exact path='/home/:componentId' component={ Authorize(Home) } />
                <Route exact path='/home/:componentId/:accountIds?' component={ Authorize(Home) } />
            </Switch>
        );
    }
}

export default App;