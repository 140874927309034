import {
    PRICING_ACCOUNT_COSTS_FORM_SUBMIT,
    PRICING_ACCOUNT_COSTS_GET_COSTS
} from './actionTypes';

export function accountPricingFormSubmit(formData, token) {
    return {
        type: PRICING_ACCOUNT_COSTS_FORM_SUBMIT,
        formData,
        token
    }
}

export function submitFormSuccess(token) {
    return {
        type: PRICING_ACCOUNT_COSTS_GET_COSTS,
        token
    }
}