import {
    PRICING_GET_USER_RATES,
    PRICING_GET_USER_RATES_SUCCESS
} from './actionTypes';

export function getRates(token) {
    return {
        type: PRICING_GET_USER_RATES,
        token
    }
}

export function getRatesSuccess(rates) {
    return {
        type: PRICING_GET_USER_RATES_SUCCESS,
        rates
    }
}