import React from 'react';

//Componenti indicizzati
import TimeReport from '../TimeReport/index';
import Management from '../Management/index';
import AccountDetail from '../Management/components/AccountDetail/index';
import Pricing from '../Pricing/index';
import IssuesWithoutAccount from '../../../IssuesWithoutAccount/index';
import MyDiary from '../../../MyDiary/index';
import CostCalc from '../../../CostCalc/index';
import PodManagement from '../../../PodManagement';

class ComponentSwitch extends React.Component {

    render() {
        const { componentId } = this.props;
        return <React.Fragment>{ this.renderSwitch(componentId) }</React.Fragment>;
    }

    renderSwitch(name) {
        const components = {
            'attendance-list': (
                <TimeReport { ...this.props } />
            ),
            'management': (
                <Management { ...this.props } />
            ),
            'pricing': (
                <Pricing { ...this.props } />
            ),
            'account-detail': (
                <AccountDetail { ...this.props } />
            ),
            'issues-without-account': (
                <IssuesWithoutAccount { ...this.props } />
            ),
            'my-diary': (
                <MyDiary { ...this.props } />
            ),
            'cost-calculation': (
                <CostCalc { ...this.props } />
            ),
            'pod-management': (
                <PodManagement { ...this.props } />
            )
        }

        return components[name];
    }
}

export default ComponentSwitch;