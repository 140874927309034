import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { errorHandling } from '../../../../../utils/Errors/actions/index';
import { toast } from 'react-toastify';

//Actions

//Success functions
import { updateRowSuccess } from '../actions/updateRow';
import { deleteRowSuccess } from '../actions/rowDelete';

const updateRow = (action$, state$) =>
    action$.pipe(
        ofType("PODCAMP_SETTINGS_SUBMIT_UPDATE_ROW_FORM_DATA"),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.podBaseURL }/${ action.apiKey }`;

            const updatedRow = ajax
                .post(
                    apiUrl,
                    action.formData,
                    {
                        "Content-type": "application/json",
                        "Authorization": "Bearer " + action.token
                    }
                )
                .pipe(
                    map(data => {
                        return updateRowSuccess(action.token, action.formData.facilityId);
                    }),
                    catchError(error => of(errorHandling(error)))
                );

            return updatedRow;
        })
    );


const deleteRow = (action$, state$) => 
    action$.pipe(
        ofType("PODCAMP_SETTINGS_ROW_DELETE"),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.podBaseURL }/${ action.apiKey }/${ action.id }`;

            const deletedRow = ajax({
                url: apiUrl,
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + action.token
                }
            })
            .pipe(
                map(() => {
                        return deleteRowSuccess(action.token, action.facilityId)
                    }
                ),
                catchError(error => of(errorHandling(error)))
            );

            return deletedRow;
        })
    )

export default combineEpics(
    updateRow,
    deleteRow
);