import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import { errorHandling } from '../../../../../../../utils/Errors/actions/index';

//Success functions
import { getCostList } from '../../AccountPricingForm/actions/getCostList';

//Actions
import {
    ACCOUNT_COST_LIST_TABLE_ROW_DELETE
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

const rowDelete = (action$, state$) =>
    action$.pipe(
        ofType(ACCOUNT_COST_LIST_TABLE_ROW_DELETE),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/account/${ action.element.account.jiraKey }/cost/${ action.element.id }`;

            const deletedRow = ajax({
                url: apiUrl,
                method: 'DELETE',
                headers: { 
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + action.token 
                }
            })
            .pipe(
                map(() => {
                    toast.success("Operazione effettuata con successo");
                    return getCostList(action.token);
                }),
                catchError(error => {
                    toast.error("Errore inaspettato");
                    return of(errorHandling(error)) })
            );

            return deletedRow;
        })
    );

export default combineEpics(
    rowDelete
);