import {
    MANAGEMENT_FILTER_SUBMIT_SUCCESS,
    MANAGEMENT_FILTER_SUBMIT_SUCCESS_NO_GROUPING
} from './actionTypes';

export function formSubmitSuccess(results) {
    return {
        type: MANAGEMENT_FILTER_SUBMIT_SUCCESS,
        results
    }
}

export function formSubmitSuccessNoGrouping(results) {
    return {
        type: MANAGEMENT_FILTER_SUBMIT_SUCCESS_NO_GROUPING,
        results
    }
}