import {
    MANAGEMENT_SELECTED_ROW,
    MANAGEMENT_SELECTED_ROW_SUCCESS,
    MANAGEMENT_SELECTED_ROW_BY_ISSUES_SUCCESS
} from './actionTypes';

export function rowSelect(row, token, formData) {
    return {
        type: MANAGEMENT_SELECTED_ROW,
        row,
        token,
        formData
    }
}

export function rowSelectSuccess(row) {
    return {
        type: MANAGEMENT_SELECTED_ROW_SUCCESS,
        row
    }
}

export function getIssuesByUserSuccess(data) {
    return {
        type: MANAGEMENT_SELECTED_ROW_BY_ISSUES_SUCCESS,
        data
    }
}