import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from '../history';

//Reducers
import login from '../components/Login/reducers/index';
import auth from '../utils/Authorize/reducers/index';
import home from '../components/Home/reducers/index';
import managementFilters from '../components/Home/components/Management/components/FiltersForm/reducers/index';
import management from '../components/Home/components/Management/reducers/index';
import accountDetailTable from '../components/Home/components/Management/components/AccountDetail/components/AccountDetailTable/reducers/index';
import accountDetail from '../components/Home/components/Management/components/AccountDetail/reducers/index';
import pricing from '../components/Home/components/Pricing/reducers/index';
import pricingForm from '../components/Home/components/Pricing/components/PricingForm/reducers/index';
import ratingEditForm from '../components/Home/components/Pricing/components/RatingEditForm/reducers/index';
import accountPricingForm from '../components/Home/components/Pricing/components/AccountPricingForm/reducers/index';
import issuesWithoutAccount from '../components/Home/components/Pricing/components/IssuesWithoutAccountFilter/reducers/index';
import issuesWithoutAccountPage from '../components/IssuesWithoutAccount/reducers/index';
import diaryForm from '../components/MyDiary/components/FilterForm/reducers/index';
import myDiary from '../components/MyDiary/reducers/index';
import timeReport from '../components/Home/components/TimeReport/reducers';
import timeReportFilterForm from '../components/Home/components/TimeReport/components/TimeReportFilterForm/reducers/index';
import timeReportTable from '../components/Home/components/TimeReport/components/TimeReportTable/reducers/index';
import timeReportSettingsForm from '../components/Home/components/TimeReport/components/SettingsForm/reducers/index';
import accountPricingAccordion from '../components/Home/components/Pricing/components/AccountPricingAccordion/reducers/index';
import pricingAccordionForm from '../components/Home/components/Pricing/components/AccountPricingAccordion/components/EditAccordionForm/reducers/index';
import resumeForm from '../components/Home/components/TimeReport/components/ResumeForm/reducers/index';
import timeReportResumePresences from '../components/Home/components/TimeReport/components/ResumeTable/reducers/index';
import UpdatePriceForm from '../components/Home/components/Pricing/components/PricingTable/components/updatePricingForm/reducers/index';
import costCalc from '../components/CostCalc/reducers/index';
import costCalcFormAddRow from '../components/CostCalc/components/FormAddRow/reducers/index';
import costCalcTable from '../components/CostCalc/components/CurrentTable/reducers/index';
import stopWatch from '../components/CheckInCheckOut/components/Stopwatch/reducers/index';
import checkinCheckout from '../components/CheckInCheckOut/reducers/index';
import podConfig from '../components/PodManagement/reducers/index';
import customTabBlock from '../components/PodManagement/components/CustomTabForm/reducers/index';

//Epics
import loginEpic from '../components/Login/epics/index';
import tokenCheck from '../utils/Authorize/epics/index';
import homeEpic from '../components/Home/epics/index';
import managementEpic from '../components/Home/components/Management/epics/index';
import managementFiltersEpic from '../components/Home/components/Management/components/FiltersForm/epics/index';
import pricingEpic from '../components/Home/components/Pricing/epics/index';
import pricingFormEpic from '../components/Home/components/Pricing/components/PricingForm/epics/index';
import ratingTable from '../components/Home/components/Pricing/components/RatingsTable/epics/index';
import ratingEditFormEpic from '../components/Home/components/Pricing/components/RatingEditForm/epics/index';
import pricingAccountCostsEpic from '../components/Home/components/Pricing/components/AccountPricingForm/epics/index';
import issuesWithoutAccountEpic from '../components/Home/components/Pricing/components/IssuesWithoutAccountFilter/epics/index';
import accountCostListTable from '../components/Home/components/Pricing/components/AccountPricingAccordion/epics/index';
import issuesWithoutAccountPageEpic from '../components/IssuesWithoutAccount/epics/index';
import diaryFormEpic from '../components/MyDiary/components/FilterForm/epics/index';
import timeReportFilterFormEpic from '../components/Home/components/TimeReport/components/TimeReportFilterForm/epics/index';
import timeReportSettingsFormEpic from '../components/Home/components/TimeReport/components/SettingsForm/epics/index';
import accordionUpdateRowFormEpic from '../components/Home/components/Pricing/components/AccountPricingAccordion/components/EditAccordionForm/epics/index';
import timeReportResumePageEpic from '../components/Home/components/TimeReport/components/ResumeTable/epics/index';
import UpdatePriceFormEpic from '../components/Home/components/Pricing/components/PricingTable/components/updatePricingForm/epics/index';
import costCalcEpic from '../components/CostCalc/epics/index';
import podConfigEpic from '../components/PodManagement/epics/index';
import customTabBlockEpic from '../components/PodManagement/components/CustomTabForm/epics/index';
import checkinCheckoutEpic from '../components/CheckInCheckOut/epics/index';

const initialState = {
    apiUrl: ""
};

const apiUrl = (state = initialState.apiUrl) => {
    return state;
};

export const rootEpic = combineEpics(
    loginEpic,
    tokenCheck,
    homeEpic,
    managementEpic,
    managementFiltersEpic,
    pricingEpic,
    pricingFormEpic,
    ratingTable,
    ratingEditFormEpic,
    pricingAccountCostsEpic,
    issuesWithoutAccountEpic,
    issuesWithoutAccountPageEpic,
    accountCostListTable,
    diaryFormEpic,
    timeReportFilterFormEpic,
    timeReportSettingsFormEpic,
    accordionUpdateRowFormEpic,
    timeReportResumePageEpic,
    UpdatePriceFormEpic,
    costCalcEpic,
    podConfigEpic,
    customTabBlockEpic,
    checkinCheckoutEpic
);

export const rootReducer = combineReducers({
    router: connectRouter(history),
    apiUrl,
    login,
    home,
    auth,
    managementFilters,
    management,
    accountDetailTable,
    pricing,
    accountDetail,
    pricingForm,
    ratingEditForm,
    accountPricingForm,
    issuesWithoutAccount,
    issuesWithoutAccountPage,
    diaryForm,
    myDiary,
    timeReport,
    timeReportFilterForm,
    timeReportTable,
    timeReportSettingsForm,
    accountPricingAccordion,
    pricingAccordionForm,
    resumeForm,
    timeReportResumePresences,
    UpdatePriceForm,
    costCalc,
    costCalcFormAddRow,
    costCalcTable,
    stopWatch,
    checkinCheckout,
    podConfig,
    customTabBlock
});