import { combineReducers } from 'redux';
import moment from 'moment';

//Actions
import {
    UPDATE_RATING_FORM_DATE_FROM,
    UPDATE_RATING_FORM_HOURLY_RATE,
    SUBMIT_RATING_FORM_ROW_UPDATE
} from '../actions/actionTypes';

import {
    PRICING_GET_USER_RATES_SUCCESS
} from '../../../actions/actionTypes';

import {
    RATINGS_TABLE_UPDATE_ROW
} from '../../RatingsTable/actions/actionTypes';

let initialState = {
    dateFrom: null,
    hourlyRate: null,
    isStillLoading: false
};

export const dateFrom = (state = initialState.dateFrom, action) => {
    switch(action.type) {
        case UPDATE_RATING_FORM_DATE_FROM:
            return action.value;
        case RATINGS_TABLE_UPDATE_ROW:
            return moment(action.row.dateFrom);
        default:
            return state
    }
};

export const hourlyRate = (state = initialState.hourlyRate, action) => {
    switch(action.type) {
        case UPDATE_RATING_FORM_HOURLY_RATE:
            return action.value;
        case RATINGS_TABLE_UPDATE_ROW:
            return action.row.hourlyRate;
        default:
            return state;
    }
};

export const isStillLoading = (state = initialState.isStillLoading, action) => {
    switch(action.type) {
        case SUBMIT_RATING_FORM_ROW_UPDATE:
            return true;
        case PRICING_GET_USER_RATES_SUCCESS:
            return false;
        default:
            return state;
    }
}

const ratingForm = combineReducers({
    dateFrom,
    hourlyRate,
    isStillLoading
});

export default ratingForm;