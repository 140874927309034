import {
    POD_CONFIG_GET_FACILITES,
    POD_CONFIG_GET_FACILITES_SUCCESS
} from './actionTypes';

export function facilities(token) {
    return {
        type: POD_CONFIG_GET_FACILITES,
        token
    };
};

export function facilitiesSuccess(facilities) {
    return {
        type: POD_CONFIG_GET_FACILITES_SUCCESS,
        facilities
    };
};