import React from 'react';
import { connect } from 'react-redux';

//Actions
import { startTimer, stopTimer } from './actions/updateTime';

// Helper function that takes store state
// and returns the current elapsed time
function getElapsedTime(baseTime, startedAt) {
    if (!startedAt) {
      return 0;
    } else {
      return startedAt - baseTime;
    }
  }

class Stopwatch extends React.Component {
    componentDidMount() {
        this.props.handleStartTimer(this.props.stoppedAt ? this.props.stoppedAt : this.props.startedAt);
        this.interval = setInterval(this.forceUpdate.bind(this), this.props.updateInterval);
        this.msToTime = this.msToTime.bind(this);
      }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.props.handleStopTimer()
    }

    msToTime(s) {
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;
        hrs = hrs.toString().length < 2 ? "0" + hrs.toString() : hrs;
        mins = mins.toString().length < 2 ? "0" + mins.toString() : mins;
        return hrs + ':' + mins;
    }

    render() {
        const { baseTime } = this.props;
        const elapsed = getElapsedTime(baseTime, Date.now());
        return (
            <h1 style={{ color: 'white' }}>{this.msToTime(elapsed)}</h1>
          );
    }
};

const mapStateToProps = state => {
    return {
        startedAt: state.stopWatch.startedAt,
        stoppedAt: state.stopWatch.stoppedAt,
        baseTime: state.stopWatch.baseTime
    }
};

const mapDispatchToProps = dispatch => {
    const handleStartTimer = elapsed => {
        dispatch(startTimer(elapsed))
    };

    const handleStopTimer = () => {
        dispatch(stopTimer());
    }

    return {
        handleStartTimer,
        handleStopTimer
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Stopwatch);