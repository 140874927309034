import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import moment from 'moment';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, DatePicker, Checkbox, Button, Tooltip, Form } from 'antd';

//Icons

//Style
import './style.scss';

//Actions
import { updateFormField } from './actions/updateFormValue';
import { timeReportFormSubmit } from './actions/submitFormData';

//Components

//Misc imports

class FilterForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleFormSubmit(formData, token) {
        this.props.submitData(formData, token);
    }

    render() {
        const { results } = this.props;
        const options = [ {
            label: 'Solo righe in errore', 
            value:'only-errors'
        } ];

        const CSVdata = results && results.length ? 
        [].concat.apply([], [...results.map(x => {
            return x.diaries.map(y => {
                return {
                    name: x.user.fullName,
                    date: moment(y.date).format('DD/MM/YYYY'),
                    presenceHours: y.presenceHours,
                    overtimeHours: y.overtimeHours,
                    diaryHours: y.diaryHours,
                    alert: y.alert,
                    lastName: x.user.lastName
                };
            });
        })]).sort((a, b) => { 
            if(a.lastName < b.lastName) {
                return -1;
            }
            if(a.lastName > b.lastName) {
                return 1;
            }
            return 0
         }).map(y => {
            return "\"" + y.name + "\";"
             + "\"" + y.date + "\";"
             + "\"" + (y.presenceHours || 0).toString().replace(".", ",") + "\";"
             + "\"" + (y.overtimeHours || 0).toString().replace(".", ",") + "\";"
             + "\"" + (y.diaryHours || 0).toString().replace(".", ",") + "\";"
             + "\"" + (y.alert ? "Si\"\n" : "No\"\n")
         }).join('') : '';

        const CSVinfo = results && results.length ? 
        window.URL.createObjectURL(new Blob(["\"Nome\";\"Data\";\"Ore di presenza\";\"Ore di straordinario\";\"Ore di diario\";\"In errore\"\n"
        + CSVdata], { type: "text/csv;charset=utf-8" })) : 
         null;

         const formLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };
        return (
            <Form 
                {...formLayout}
                className='rm-form'
                name='filter-form'
                onFinish={ values => {
                    const formData = {
                        dateFrom: values.range && values.range.length ? values.range[0].format('DD-MM-YYYY') : '',
                        dateTo: values.range && values.range.length ? values.range[1].format('DD-MM-YYYY') : '',
                        onlyErrors: this.props.onlyErrors
                    };
                    this.handleFormSubmit(formData, this.props.token);
                    
                } }>
                <Row
                    type="flex"
                    justify="start"
                    align="bottom"
                    gutter={ 20 }>
                    <Col
                        span={ 4 }
                        align="left">
                        <Form.Item 
                            label="Data Da ~ A"
                            rules={ [{ required: true, message: 'La data è obbligatoria' }] }
                            name='range'>
                            <DatePicker.RangePicker
                                allowClear={ true }
                                ranges={{
                                    'Oggi': [moment(), moment()],
                                    'Ultimo mese': [moment().startOf('month'), moment().endOf('month')]
                                }}
                                format='DD/MM/YYYY'
                                placeholder={['Da', 'A']} />
                        </Form.Item>
                    </Col>
                    <Col
                        span={ 4 }
                        align="left">
                        <Form.Item 
                            className='rm-label-less-checkbox'
                            name='onlyErrors'>
                            <Checkbox.Group
                                style={{ paddingBottom: '40px' }}
                                options={ options }
                                name={ 'flag' }
                                defaultChecked={ false }
                                checked={ this.props.onlyErrors }
                                onChange={ () => {
                                    this.props.updateField('onlyErrors', !this.props.onlyErrors);
                                } } />
                        </Form.Item>
                    </Col>
                    <Col
                        span={2}
                        align="left">
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                            <span>Cerca</span>
                            </Button>
                        </Form.Item>
                    </Col>
                    {  CSVinfo && CSVinfo.length ? 
                        <Col
                            span={2}
                            align="left">
                            <Form.Item>
                                <a href={ CSVinfo } download={ 'export-timbrature.csv' }>
                                    <Button type="primary">
                                        Esporta tutto
                                    </Button>
                                </a>
                            </Form.Item>                        
                        </Col> :
                        <Col
                        span={4}
                        align="left">
                        <Form.Item>
                            <Tooltip title={ results && results.length ? "Stiamo preparando il file CSV" : "Non ci sono dati da esportare" }>
                                <Button type="primary" disabled={ true }>
                                    Esporta tutto
                                </Button>
                            </Tooltip>
                        </Form.Item>                        
                    </Col> }
                </Row>
            </Form>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        range: state.timeReportFilterForm.range,
        onlyErrors: state.timeReportFilterForm.onlyErrors,
        results: state.timeReport.results
    }
};

const mapDispatchToProps = dispatch => {
    const updateField = (key, value) => {
        dispatch(updateFormField(key, value));
    };

    const submitData = (formData, token) => {
        dispatch(timeReportFormSubmit(formData, token));
    };

    return {
        updateField,
        submitData
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterForm);