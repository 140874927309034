import { combineReducers } from 'redux';

//Actions
import {
    TIME_REPORT_SETTINGS_FORM_TOLLERANCE,
    TIME_REPORT_SUBMIT_SETTINGS_FORM_DATA,
    TIME_REPORT_GET_CURRENT_PARAM_SUCCESS
} from '../actions/actionTypes';

let initialState = {
    tollerance: null,
    isStillLoading: true
};

export const tollerance = (state = initialState.tollerance, action) => {
    switch(action.type) {
        case TIME_REPORT_SETTINGS_FORM_TOLLERANCE:
            return action.value;
        case TIME_REPORT_GET_CURRENT_PARAM_SUCCESS:
            return action.data.find(x => { return x.key === "presences_alert_delta_minutes" }).value;
        default:
            return state;
    }
};
export const isStillLoading = (state = initialState.isStillLoading, action) => {
    switch(action.type) {
        case TIME_REPORT_GET_CURRENT_PARAM_SUCCESS:
            return false;
        case TIME_REPORT_SUBMIT_SETTINGS_FORM_DATA:
            return true;
        default:
            return state;
    }
}

const timeReportSettingsForm = combineReducers({
    tollerance,
    isStillLoading
});

export default timeReportSettingsForm;