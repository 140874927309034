import { combineReducers } from 'redux';

//Actions
import {
    ACCORDION_UPDATE_ERP_KEY,
    ACCORDION_UPDATE_DATE,
    ACCORDION_UPDATE_DATE_LAST_UPDATE,
    ACCORDION_UPDATE_DESCRIPTION,
    ACCORDION_UPDATE_COST,
    ACCORDION_CANCEL_UPDATE
} from '../actions/actionTypes';

import {
    ACCOUNT_COST_LIST_TABLE_ROW_UPDATE
} from '../../../actions/actionTypes';

let initialState = {
    erpKey: null,
    date: null,
    dateUpdate: null,
    description: null,
    cost: null
};

export const erpKey = (state = initialState.erpKey, action) => {
    switch(action.type) {
        case ACCORDION_UPDATE_ERP_KEY:
            return action.value;
        case ACCORDION_CANCEL_UPDATE:
            return null;
        case ACCOUNT_COST_LIST_TABLE_ROW_UPDATE:
            return action.row.erpKey;
        default:
            return state;
    }
};

export const date = (state = initialState.date, action) => {
    switch(action.type) {
        case ACCORDION_UPDATE_DATE:
            return action.value;
        case ACCORDION_CANCEL_UPDATE:
            return null;
        case ACCOUNT_COST_LIST_TABLE_ROW_UPDATE:
            return action.row.costDate;
        default:
            return state;
    }
};

export const dateUpdate = (state = initialState.dateUpdate, action) => {
    switch(action.type) {
        case ACCORDION_UPDATE_DATE_LAST_UPDATE:
            return action.value;
        case ACCORDION_CANCEL_UPDATE:
            return null;
        case ACCOUNT_COST_LIST_TABLE_ROW_UPDATE:
            return action.row.updateDate === null ? null : action.row.updateDate;
        default:
            return state;
    }
};

export const description = (state = initialState.description, action) => {
    switch(action.type) {
        case ACCORDION_UPDATE_DESCRIPTION:
            return action.value;
        case ACCORDION_CANCEL_UPDATE:
            return null;
        case ACCOUNT_COST_LIST_TABLE_ROW_UPDATE:
            return action.row.description;
        default:
            return state;
    }
};

export const cost = (state = initialState.cost, action) => {
    switch(action.type) {
        case ACCORDION_UPDATE_COST:
            return action.value;
        case ACCORDION_CANCEL_UPDATE:
            return null;
        case ACCOUNT_COST_LIST_TABLE_ROW_UPDATE:
            return action.row.cost;
        default:
            return state;
    }
};

const pricingAccordionForm = combineReducers({
    erpKey,
    date,
    dateUpdate,
    description,
    cost
});

export default pricingAccordionForm;