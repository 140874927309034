import {
    ISSUES_WITHOUT_ACCOUNT_PAGE_FETCH_INITIAL_DATA, 
    ISSUES_WITHOUT_ACCOUNT_PAGE_FETCH_INITIAL_DATA_SUCCESS
} from './actionTypes';

export function fetchIssues(token) {
    return {
        type: ISSUES_WITHOUT_ACCOUNT_PAGE_FETCH_INITIAL_DATA,
        token
    }
};

export function fetchIssuesSuccess(issues) {
    return {
        type: ISSUES_WITHOUT_ACCOUNT_PAGE_FETCH_INITIAL_DATA_SUCCESS,
        issues
    }
};