import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

//Components
import { toast } from 'react-toastify';
import { loginErrorHandling } from '../../../utils/Errors/actions/index';

//Success functions
import { loginSubmitSuccess } from '../actions/loginSubmitSuccess';

//Actions
import {
    SUBMIT_LOGIN_INFO
} from '../actions/actionTypes';

const login = (action$, state$) =>
    action$.pipe(
        ofType(SUBMIT_LOGIN_INFO),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.auth }/login/activedirectory`;

            const token = ajax
                .post(
                    apiUrl,
                    {
                        username: action.userInfo.username,
                        password: action.userInfo.password,
                        group: "DCG Users"
                    },
                    { "Content-type": "application/json" }
                ).pipe(
                    map(data => {
                        localStorage.setItem('rm-dash-token', data.response.token);
                        return loginSubmitSuccess(data.response.token);
                    }),
                    catchError(error => {
                        if(error.status && error.status === 401) {
                            toast.error("Utente non autorizzato");
                        } else {
                            toast.error("Errore interno");
                        }
                        return of(loginErrorHandling(error));
                    })
                );
            return token;
        })
    );

export default login;