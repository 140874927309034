import React from 'react';
import { connect } from 'react-redux';
import '@ant-design/compatible/assets/index.css';

//Libraries components
import { Form, Col, Row, DatePicker, Button } from 'antd';
import moment from 'moment';

//Icons

//Style

//Actions
import { submitData } from './actions/submitData';
import { updateFormField } from './actions/updateFormField';

//Components

//Misc imports

class FilterForm extends React.Component {
    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleFormSubmit(formData, token, username) {
        this.props.submitForm(formData, token, username);
    }

    render() {
        const formLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };

        return (
            <Form 
                {...formLayout}
                className='rm-form'
                name='filter-form-diary'
                onFinish={ values => {
                    const formData = {
                        dateFrom: values.range && values.range.length ? values.range[0].format('DD-MM-YYYY') : '',
                        dateTo: values.range && values.range.length ? values.range[1].format('DD-MM-YYYY') : ''
                    };
                    Object.keys(values).forEach((key, index) => {
                        return this.props.updateField(key, 
                            values[Object.keys(values)[index]] === undefined || 
                            values[Object.keys(values)[index]] === null ? '' : 
                            values[Object.keys(values)[index]]);
                    });
                    this.handleFormSubmit(formData, this.props.token, this.props.username);
                }}>
                <Row
                    type='flex'
                    justify='bottom'
                    align='middle'
                    gutter={ 20 }>
                    <Col
                        span={ 4 }
                        align='left'>
                        <Form.Item label='Data Da ~ A'
                        name='range'
                        rules={[{ required: true, message: 'La data è obbligatoria' }]}>
                            <DatePicker.RangePicker
                            allowClear={ true }
                            ranges={{
                                'Oggi': [moment(), moment()],
                                'Ultimo mese': [moment().startOf('month'), moment().endOf('month')]
                            }}
                            format='DD/MM/YYYY'
                            placeholder={['Da', 'A']} />
                        </Form.Item>
                    </Col>
                    <Col
                        span={4}
                        align="left">
                        <Form.Item>
                            <Button 
                                type="primary" 
                                htmlType="submit">
                                <span>Filtra</span>
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }
}

const mapStateToProps = state => {
    return {
        username: state.auth.userInfo.user.username,
        dateRange: state.diaryForm.dateRange,
        rows: state.diaryForm.rows,
        token: state.auth.token
    }
};

const mapDispatchToProps = dispatch => {
    const submitForm = (token, formData, username) => {
        dispatch(submitData(token, formData, username));
    };

    const updateField = (key, value) => {
        dispatch(updateFormField(key, value));
    };

    return {
        submitForm,
        updateField
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterForm);