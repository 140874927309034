import { combineReducers } from 'redux';

//Common components

//Actions
import {
    HOME_CHANGE_DRAWER_STATE,
    HOME_SELECTED_ENTRY,
    HOME_GET_UNASSIGNED_ISSUES_SUCCESS
} from '../actions/actionTypes';

let initialState = {
    drawerState: true,
    selectedEntry: null,
    unassignedIssues: []
};

export const drawerState = (state = initialState.drawerState, action) => {
    switch(action.type) {
        case HOME_CHANGE_DRAWER_STATE:
            return action.state;
        default:
            return state;
    }
};

export const selectedEntry = (state = initialState.selectedEntry, action) => {
    switch(action.type) {
        case HOME_SELECTED_ENTRY:
            return action.key;
        default:
            return state;
    }
};

export const unassignedIssues = (state = initialState.unassignedIssues, action) => {
    switch(action.type) {
        case HOME_GET_UNASSIGNED_ISSUES_SUCCESS:
            return action.issues;
        default:
            return state;
    }
};

const home = combineReducers({
    drawerState,
    selectedEntry,
    unassignedIssues
});

export default home;