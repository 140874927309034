import React from 'react';
import moment from "moment"

export const columns = [{
    title: 'Progetto',
    dataIndex: 'projectName',
    sorter: (a, b) => {
        if(a.projectName < b.projectName) {
            return -1;
        }
        if(a.projectName > b.projectName) {
            return 1;
        }
        return 0;
    },
    width: 200,
    searchable: true
},{
    title: 'Epic',
    dataIndex: 'epicSummary',
    width: 200,
    sorter: (a, b) => {
        if(a.epicSummary < b.epicSummary) {
            return -1;
        }
        if(a.epicSummary > b.epicSummary) {
            return 1;
        }
        return 0;
    },
    searchable: true
},{
    title: 'Issue',
    dataIndex: 'parsedIssueSummary',
    searchable: true
},{
    title: 'Tipo',
    dataIndex: 'type',
    width: 100,
    searchable: true
},{
    title: 'Creata il',
    dataIndex: 'creadtionDate',
    width: 100,
    render: element => {
        return (<span>
            { moment(element).format('DD/MM/YYYY') }
        </span>)
    }
},{
    title: 'Creata da',
    dataIndex: 'creatorFullName',
    width: 200,
    searchable: true
}]