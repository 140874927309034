import React from 'react';
import { connect } from 'react-redux';

import '@ant-design/compatible/assets/index.css';

//Libraries components
import { Row, Col, DatePicker, Form } from 'antd';

//Icons

//Style

//Actions
import { updateFormField } from './actions/fieldUpdate';
import { formSubmit } from './actions/formSubmit';

//Components

//Misc imports

class ResumeForm extends React.Component {
    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleFormSubmit(formData, token) {
        this.props.submitData(formData, token);
    }

    render() {
        return (
            <Form 
                className='rm-form'
                onValuesChange={ (changedValues, allValues) => {
                    const value = changedValues[Object.keys(changedValues)[0]] === undefined ? '' : changedValues[Object.keys(changedValues)[0]];
                    const key = Object.keys(changedValues)[0];
                    this.props.updateField(key, value);
                    const formData = {
                        from: changedValues.date.startOf('month').format('DD-MM-YYYY'),
                        to: changedValues.date.endOf('month').format('DD-MM-YYYY')
                    };
                    this.props.submitData(formData, this.props.token);
                }}
                initialValues={{
                    date: this.props.date
                }}>
                <Row
                    type='flex'
                    justify='end'
                    align='middle'
                    gutter={ 20 }>
                    <Col
                        span={ 12 }
                        align='left'>
                        <Form.Item
                            name='date'
                            style={{ marginBottom: 0 }}
                            rules={[{ required: true, message: 'La data è obbligatoria' }]}>
                            <DatePicker.MonthPicker 
                                allowClear={ false } 
                                placeholder={['Data']}
                                format={ 'MM/YYYY' } />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        date: state.resumeForm.date
    };
};

const mapDispatchToProps = dispatch => {
    const updateField = (key, value) => {
        dispatch(updateFormField(key, value));
    };

    const submitData = (formData, token) => {
        dispatch(formSubmit(formData.from, formData.to, token));
    };

    return {
        updateField,
        submitData
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResumeForm);