import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import { Spin, Alert } from 'antd';

//Style
import './style.scss';

//Actions
import { fetchIssues } from './actions/fetchIssues';

//Components
import DashboardTable from '../../utils/DashboardTable';
import { columns } from './columns';

//Misc imports

class IssuesWithoutAccount extends React.Component {
    componentDidMount() {
        const token = this.props.token;
        this.props.getIssuesWithoutAccount(token);
    }

    render() {
        const { issues, isFetching } = this.props;

        return (
            <React.Fragment>
                { isFetching === true ? 
                    <Spin tip="Loading..." className="rm-loader-management">
                        <Alert 
                            message="Caricamento"
                            description="Sto riorganizzando i dati" 
                            type="info"
                            className="rm-message-info"
                        />
                    </Spin> : 
                <DashboardTable
                    columns={ columns }
                    data={ issues }
                    tableHeight={ '75vh' } /> }
            </React.Fragment>
                
        )
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        issues: state.issuesWithoutAccountPage.issues,
        isFetching: state.issuesWithoutAccountPage.isFetching
    };
};

const mapDispatchToProps = dispatch => {
    const getIssuesWithoutAccount = token => {
        dispatch(fetchIssues(token));
    };

    return {
        getIssuesWithoutAccount
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IssuesWithoutAccount);