import {
    PRICING_BUDGET_UPDATE_FORM_SUBMIT
} from './actionTypes';
import {
    PRICING_GET_INITIAL_DATA
} from '../../../../../actions/actionTypes';

export function pricingFormSubmit(formData, token) {
    return {
        type: PRICING_BUDGET_UPDATE_FORM_SUBMIT,
        formData,
        token
    };
};

export function pricingFormSubmitSuccess(token) {
    return {
        type: PRICING_GET_INITIAL_DATA,
        token
    }
}