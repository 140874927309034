import React from 'react';

export const columns = [{
    title: 'Cliente',
    width: 200,
    dataIndex: 'customerName',
    searchable: true,
    sorter: (a, b) => a.customerName < b.customerName,
    defaultSortOrder: 'descend'
},{
    width: 200,
    title: 'Account',
    dataIndex: 'name',
    searchable: true
},{
    title: 'Codice',
    dataIndex: 'jiraKey',
    width: 200,
    sorter: (a, b) => a.jiraKey - b.jiraKey,
    defaultSortOrder: 'ascend',
    searchable: true
},{
    title: 'Responsabile',
    dataIndex: 'leadName',
    width: 200,
    searchable: true
},{
    title: 'Budget',
    dataIndex: 'budget',
    width: 100,
    sorter: (a, b) => a.budget - b.budget,
    defaultSortOrder: 'ascend',
    render: element => {
        return (
            <span>{ element.toFixed(2).toString().replace('.', ',') }</span>
        )
    }
}];