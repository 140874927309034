import { combineReducers } from 'redux';

//Actions
import {
    TIME_REPORT_FILTER_FORM_UPDATE_DATE_RANGE,
    TIME_REPORT_FILTER_FORM_UPDATE_ONLY_ERRORS
} from '../actions/actionTypes';

let initialState = {
    range: null,
    onlyErrors: false
};

export const range = (state = initialState.range, action) => {
    switch(action.type) {
        case TIME_REPORT_FILTER_FORM_UPDATE_DATE_RANGE:
            return action.value;
        default:
            return state;
    }
};

export const onlyErrors = (state = initialState.onlyErrors, action) => {
    switch(action.type) {
        case TIME_REPORT_FILTER_FORM_UPDATE_ONLY_ERRORS:
            return action.value;
        default:
            return state;
    }
};

const timeReportForm = combineReducers({
    range,
    onlyErrors
});

export default timeReportForm;