import {
    MANAGEMENT_FILTER_FORM_SUBMIT
} from './actionTypes';

export function managementFormSubmit(formData, token) {
    return {
        type: MANAGEMENT_FILTER_FORM_SUBMIT,
        formData,
        token
    }
}