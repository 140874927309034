import React from 'react';
import moment from 'moment';

export const columnsDefault = [{
    title: 'Codice',
    width: 100,
    dataIndex: 'issueJiraKey',
    searchable: true
},{
    width: 200,
    title: 'Segnalazione',
    dataIndex: 'issueSummary',
    searchable: true
},{
    title: 'Utente',
    width: 200,
    dataIndex: 'assignedUser',
    searchable: true
},{
    title: 'Data',
    dataIndex: 'date',
    width: 100,
    sorter: (a, b) => moment(a.date) - moment(b.date),
    defaultSortOrder: 'ascend',
    render: date => {
        return (
            <span>{ moment(date).format('DD/MM/YYYY') }</span>
        )
    }
},{
    title: 'Ore',
    data: 'hours',
    width: 100,
    sorter: (a, b) => a.hours - b.hours,
    defaultSortOrder: 'ascend',
    render: element => {
        return (
            <span>{
                Math.floor(element.hours || 0) + "h " + Math.floor(((element.hours || 0) % 1) * 60) + "m"
            }</span>
        )
    }
},{
    title: 'Costo',
    dataIndex: 'cost',
    width: 200,
    sorter: (a, b) => a.cost - b.cost,
    defaultSortOrder: 'ascend',
    render: (text, record, index) => {
        return (<span>{ record.cost.toFixed(2).toString().replace('.', ',') }</span>)
    }
}];

export const columnsGroupByUser = [{
    width: 200,
    title: 'Utente',
    dataIndex: 'assignedUser',
    searchable: true
},{
    title: 'Ore',
    dataIndex: 'hours',
    width: 200,
    render: element => {
        return (
            <span>{
                Math.floor(element || 0) + "h " + Math.floor(((element || 0) % 1) * 60) + "m"
            }</span>
        )
    }
},{
    title: 'Costo',
    dataIndex: 'cost',
    sorter: (a, b) => a.cost - b.cost,
    defaultSortOrder: 'ascend',
    width: 200,
    render: (text, record, index) => {
        return (<span>{ record.cost.toFixed(2).toString().replace('.', ',') }</span>)
    }
}];

export const columnsGroupByIssues = [{
    title: 'Codice',
    dataIndex: 'issueJiraKey',
    searchable: true,
    width: 100
},{
    width: 400,
    title: 'Segnalazione',
    dataIndex: 'summary',
    searchable: true
},{
    title: 'Ore',
    dataIndex: 'hours',
    width: 200,
    render: element => {
        return (
            <span>{
                Math.floor(element || 0) + "h " + Math.floor(((element || 0) % 1) * 60) + "m"
            }</span>
        )
    }
},{
    title: 'Costo',
    dataIndex: 'cost',
    sorter: (a, b) => a.cost - b.cost,
    defaultSortOrder: 'ascend',
    width: 200,
    render: (text, record, index) => {
        return (<span>{ record.cost.toFixed(2).toString().replace('.', ',') }</span>)
    }
}];

export const columnsGroupByBoth = [{
    title: 'Codice',
    dataIndex: 'issueJiraKey',
    searchable: true,
    width: 100
},{
    width: 200,
    title: 'Segnalazione',
    dataIndex: 'summary',
    searchable: true
},{
    title: 'Utente',
    dataIndex: 'fullName',
    searchable: true,
    width: 200
},{
    title: 'Ore',
    dataIndex: 'hours',
    width: 200,
    render: element => {
        return (
            <span>{
                Math.floor(element || 0) + "h " + Math.floor(((element || 0) % 1) * 60) + "m"
            }</span>
        )
    }
},{
    title: 'Costo',
    dataIndex: 'cost',
    sorter: (a, b) => a.cost - b.cost,
    defaultSortOrder: 'ascend',
    width: 200,
    render: (text, record, index) => {
        return (<span>{ record.cost.toFixed(2).toString().replace('.', ',') }</span>)
    }
}]