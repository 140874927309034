import {
    POD_CONFIG_SUBMIT_FORM_DATA,
    POD_CONFIG_SELECT_FACILITY
} from './actionTypes';

export function saveForm(formData, token, selectedFacility) {
    return {
        type: POD_CONFIG_SUBMIT_FORM_DATA,
        formData,
        token,
        selectedFacility
    }
}

export function saveDataSuccess(token, id) {
    return {
        type: POD_CONFIG_SELECT_FACILITY,
        token,
        id
    }
}