import {
    ACCORDION_SUBMIT_FORM_DATA,
    ACCORDION_INSERT_UPDATED_ROW
} from './actionTypes';

import {
    PRICING_ACCOUNT_COSTS_GET_COSTS
} from '../../../../AccountPricingForm/actions/actionTypes';

export function accountPricingFormSubmit(formData, token) {
    return {
        type: ACCORDION_SUBMIT_FORM_DATA,
        formData,
        token
    };
};

export function accountPricingFormSubmitSuccess(token) {
    return {
        type: PRICING_ACCOUNT_COSTS_GET_COSTS,
        token
    };
};

export function insertUpdatedRow(formData, token) {
    return {
        type: ACCORDION_INSERT_UPDATED_ROW,
        formData,
        token
    }
}