import { combineReducers } from 'redux';

//Actions
import {
    MANAGEMENT_FILTER_SUBMIT_SUCCESS,
    MANAGEMENT_SELECTED_ROW_SUCCESS,
    MANAGEMENT_UPDATE_SELECTED_ROW,
    MANAGEMENT_SELECTED_ROW,
    MANAGEMENT_SELECTED_ROW_BY_ISSUES_SUCCESS,
    MANAGEMENT_FILTER_SUBMIT_SUCCESS_NO_GROUPING
} from '../actions/actionTypes';
import {
    MANAGEMENT_FILTER_FORM_SUBMIT
} from '../components/FiltersForm/actions/actionTypes';
import {
    ACCOUNT_DETAIL_RESET_TO_DEAFULT
} from '../components/AccountDetail/actions/actionTypes';
import {
    HOME_SELECTED_ENTRY
} from '../../../actions/actionTypes';

let initialState = {
    results: [],
    isFetching: null,
    selectedRows: [],
    selectedIssuesByAccount: [],
    CSVinfo: []
};

export const results = (state = initialState.results, action) => {
    switch(action.type) {
        case MANAGEMENT_FILTER_SUBMIT_SUCCESS:
            return action.results;
        default:
            return state;
    }
};

export const isFetching = (state = initialState.isFetching, action) => {
    switch(action.type) {
        case MANAGEMENT_FILTER_FORM_SUBMIT:
            return true;
        case MANAGEMENT_SELECTED_ROW:
            return true;
        case MANAGEMENT_FILTER_SUBMIT_SUCCESS:
            return false;
        case MANAGEMENT_SELECTED_ROW_SUCCESS:
            return false;
        default:
            return state;
    }
};

export const selectedRows = (state = initialState.selectedRows, action) => {
    switch(action.type) {
        case MANAGEMENT_SELECTED_ROW_SUCCESS:
            return action.row;
        case MANAGEMENT_UPDATE_SELECTED_ROW:
            return action.rows;
        case ACCOUNT_DETAIL_RESET_TO_DEAFULT:
            return [];
        case HOME_SELECTED_ENTRY:
            return [];
        default:
            return state;
    }
}

export const selectedIssuesByAccount = (state = initialState.selectedIssuesByAccount, action) => {
    switch(action.type) {
        case MANAGEMENT_SELECTED_ROW_BY_ISSUES_SUCCESS:
            return action.data;
        case ACCOUNT_DETAIL_RESET_TO_DEAFULT:
            return [];
        default:
            return state;
    }
};

export const CSVinfo = (state = initialState.CSVinfo, action) => {
    switch(action.type) {
        case MANAGEMENT_FILTER_SUBMIT_SUCCESS_NO_GROUPING:
            return action.results;
        default:
            return state;
    }
}

const management = combineReducers({
    results,
    isFetching,
    selectedRows,
    selectedIssuesByAccount,
    CSVinfo
});

export default management;