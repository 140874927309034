import {
    UPDATE_MY_DIARY_FILTER_RANGE,
    UPDATE_MY_DIARY_FILTER_DEFAULT
} from './actionTypes';

export function updateFormField(field, value) {
    switch(field) {
        case 'range':
            return {
                type: UPDATE_MY_DIARY_FILTER_RANGE,
                value
            };
        default:
            return {
                type: UPDATE_MY_DIARY_FILTER_DEFAULT
            };
    }
}