import {
    TIME_REPORT_GET_CURRENT_PARAM,
    TIME_REPORT_GET_CURRENT_PARAM_SUCCESS
} from './actionTypes';

export function currentParams(token) {
    return {
        type: TIME_REPORT_GET_CURRENT_PARAM,
        token
    }
};

export function currentParamsSuccess(data) {
    return {
        type: TIME_REPORT_GET_CURRENT_PARAM_SUCCESS,
        data
    }
}