import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import Clock from 'react-live-clock';
import { Row, Col, Button, Spin, Alert } from 'antd';
import moment from 'moment';
import { geolocated } from 'react-geolocated';

//Style

//Actions
import { changeUserState } from './actions/changeUserState';
import { fetchCompanyData } from './actions/fetchCompanyData';
import { userPresenceData } from './actions/getCurrentUserData';
import { doCheckin, doCheckout} from './actions/employeeActions';

//Components
import Stopwatch from './components/Stopwatch/index';
import DashboardTable from '../../utils/DashboardTable/index';

class CheckInCheckOut extends React.Component {
    constructor(props) {
        super(props);

        this.changeUserState = this.changeUserState.bind(this);
        this.fetchUserPresenceData = this.fetchUserPresenceData.bind(this);
    }

    componentDidMount() {
        const { currentUser }  = this.props;
        this.props.checkUserState(currentUser);
    }

    changeUserState() {
        const { userState, coords, currentUserId } = this.props;
        if(!userState === false) {
            this.props.handleCheckout(currentUserId, coords.latitude, coords.longitude, moment().format('YYYY-MM-DD HH:mm:ss'));
        } else {
            this.props.handleCheckin(currentUserId, coords.latitude, coords.longitude, moment().format('YYYY-MM-DD HH:mm:ss'));
        }

        this.props.handleChangeUserState(!userState);
    }

    fetchUserPresenceData(employeeCode) {
        this.props.requestFetchUserPresenceData(employeeCode);
    }

    render() {
        const { 
            userState, 
            currentUserCheckins, 
            employees,
            statusList,
        } = this.props;
        
        const today = new Date();
        const parsedToday = moment(today).format('dddd D MMMM YYYY')
                            .replace("Monday", "Lunedì")
                            .replace("Tuesday", "Martedì")
                            .replace("Wednesday", "Mercoledì")
                            .replace("Thursday", "Giovedì")
                            .replace("Friday", "Venerdì")
                            .replace("Saturday", "Sabato")
                            .replace("Sunday", "Domenica")
                            .replace("January", "Gennaio")
                            .replace("February", "Febbraio")
                            .replace("March", "Marzo")
                            .replace("April", "Aprile")
                            .replace("May", "Maggio")
                            .replace("June", "Giugno")
                            .replace("July", "Luglio")
                            .replace("August", "Agosto")
                            .replace("September", "Settembre")
                            .replace("October", "Ottobre")
                            .replace("November", "Novembre")
                            .replace("December", "Dicembre");

        const columns = [{
            title: 'Data',
            width: 300,
            dataIndex: 'parsedDate',
            filters: [{
                text: 'Oggi',
                value: 'TODAY'
            },{
                text: 'Tutte',
                value: 'ALL'
            }],
            onFilter: (value, record) => {
                if(value === 'TODAY') {
                    return record.parsedDate === moment().format('DD/MM/YYYY');
                } else {
                    return true;
                }
            },
            filterMultiple: false,
            defaultFilteredValue: ['TODAY']
        },{
            title: 'Orario checkin',
            width: 300,
            dataIndex: 'parsedCheckin'
        },{
            title: 'Orario checkout',
            width: 300,
            dataIndex: 'parsedCheckout'
        }];

        if(employees.length && !statusList.length) {
            employees.map(dude => {
                this.fetchUserPresenceData(dude.id);
            });
        }

        const parsedStatusList = employees.length && statusList.length ? statusList.sort((a, b) => {
            if(a.employeeLastname.toLowerCase() < b.employeeLastname.toLowerCase()) {
                return -1;
            }
            if(a.employeeLastname.toLowerCase() > b.employeeLastname.toLowerCase()) {
                return 1;
            }
            return 0;
        }) : [];

        const statusColumns = [{
            title: 'Nome cognome',
            dataIndex: 'employee',
            searchable: true,
            onCell: record => {
                return !record.isAvailable ? { style: { backgroundColor: 'rgb(249, 207, 197)' } } : { style: { backgroundColor: 'rgb(206, 238, 206)' } }
            }
        },{
            title: 'Disponibile',
            dataIndex: 'isAvailable',
            filters: [{
                text: 'Disponibile',
                value: true
            },{
                text: 'Non disponibile',
                value: false
            }],
            onFilter: (value, record) => value === record.isAvailable,
            filterMultiple: false,
            render: (text, record, index) => {
                return (
                    <Col 
                        align="middle">
                        { record.isAvailable ? '' : record.lastCheckout ? record.lastCheckout : '' }
                    </Col>
                )
            },
            onCell: record => {
                return !record.isAvailable ? { style: { backgroundColor: 'rgb(249, 207, 197)' } } : { style: { backgroundColor: 'rgb(206, 238, 206)' } }
            }
        }];


        return (
            <React.Fragment>
                <Row
                    type='flex'
                    justify='center'
                    align='top'>
                    <Col 
                        span={ 24 }
                        align="middle">
                        <h1 className="rm-checkin-clock">
                            <span>{ parsedToday }</span><br />
                            <span><Clock format={ 'HH:mm:ss' } timezone={ 'Europe/Rome' } interval={ 1000 } /></span><br />
                        </h1>
                    </Col>
                </Row>
                { employees.length && (!statusList.length || statusList.length !== employees.length) ? 
                    <Spin tip="Loading..." className="rm-loader-management">
                        <Alert 
                            type="info"
                            className="rm-message-info"
                        />
                    </Spin> : 
                    <React.Fragment>
                        <Row
                            type='flex'
                            justify='center'
                            align='top'
                            style={{ marginBottom: '20px' }}>
                            <Col 
                                span={ 24 }
                                align="middle">
                                <Button 
                                    type="primary" 
                                    shape="circle" 
                                    size="large" 
                                    style={{ minWidth: '150px', height: '150px' }}
                                    disabled={ this.props.isGeolocationAvailable === false || this.props.isGeolocationEnabled === false ? true : false  }
                                    onClick={ () => { this.changeUserState() } }>
                                        { userState ? 
                                            <div>
                                                <span style={{ fontSize: '1.5em' }}>Check out</span><br />
                                                <Stopwatch updateInterval={ 33 } />
                                            </div> : 
                                            <span style={{ fontSize: '1.5em' }}>Check in</span> }
                                </Button>
                            </Col>
                        </Row>
                        <Row
                            type='flex'
                            justify='start'
                            align='top'
                            gutter='20'>
                            <Col
                                xs={ 24 } 
                                sm={ 24 }
                                md={ 24 }
                                lg={ 12 }
                                xl={ 12 }
                                xxl={ 12 }
                                align="left">
                                <Row
                                    type='flex'
                                    justify='start'
                                    align='top'
                                    gutter='20'>
                                    <Col align="left">
                                        <h2>
                                            <span>Le mie timbrature</span>
                                        </h2>
                                    </Col>
                                </Row>
                                <Row
                                    type='flex'
                                    justify='center'
                                    align='top'
                                    gutter='20'>
                                    <Col align="left">
                                        <DashboardTable 
                                            tableHeight={ '35vh' }
                                            data={ currentUserCheckins } 
                                            columns={ columns } 
                                            pagination={{ 
                                                hideOnSinglePage: true,
                                                pageSize: 50 
                                            }} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                xs={ 24 } 
                                sm={ 24 }
                                md={ 24 }
                                lg={ 12 }
                                xl={ 12 }
                                xxl={ 12 }
                                align="left">
                                <Row
                                    type='flex'
                                    justify='start'
                                    align='top'
                                    gutter='20'>
                                    <Col align="left">
                                        <h2>
                                            <span>Presenti</span>
                                        </h2>
                                    </Col>
                                </Row>
                                <Row
                                    type='flex'
                                    justify='center'
                                    align='top'
                                    gutter='20'>
                                    <Col align="left">
                                        <DashboardTable 
                                            tableHeight={ '35vh' }
                                            data={ parsedStatusList } 
                                            columns={ statusColumns } 
                                            pagination={{ 
                                                hideOnSinglePage: true,
                                                pageSize: 50 
                                            }} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </React.Fragment> }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        startedAt: state.stopWatch.startedAt,
        userState: state.checkinCheckout.userState,
        currentUser: state.auth.userInfo.user.fullName,
        currentUserId: state.checkinCheckout.currentUserId,
        currentUserCheckins: state.checkinCheckout.checkinList,
        employees: state.checkinCheckout.employees,
        statusList: state.checkinCheckout.statusList
    }
};

const mapDispatchToProps = dispatch => {
    const checkUserState = currentUser => {
        dispatch(fetchCompanyData(currentUser));
    };

    const handleChangeUserState = userState => {
        dispatch(changeUserState(userState))
    };

    const handleCheckout = (currentUser, latitude, longitude, date) => {
        dispatch(doCheckout(currentUser, latitude, longitude, date));
    };

    const handleCheckin = (currentUser, latitude, longitude, date) => {
        dispatch(doCheckin(currentUser, latitude, longitude, date));
    };

    const requestFetchUserPresenceData = (employeeCode) => {
        dispatch(userPresenceData(employeeCode));
    };

    return {
        checkUserState,
        handleChangeUserState,
        handleCheckout,
        handleCheckin,
        requestFetchUserPresenceData
    }
}

const wrappedComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckInCheckOut);

export default geolocated({
    positionOptions: {
        enableHighAccuracy: true
    }
})(wrappedComponent);