import {
    CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA,
    CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA_SUCCESS,
    CHECKIN_CHECKOUT_GET_CURRENT_USER_CHECKIN_LIST_SUCCESS,
    CHECKIN_CHECKOUT_GET_ALL_EMPLOYEES_CHECKIN,
    CHECKIN_CHECKOUT_GET_ALL_EMPLOYEES_CHECKIN_SUCCESS
} from './actionTypes';

export function getCurrentUserData(currentUserId, employees) {
    return {
        type: CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA,
        currentUserId,
        employees
    }
}

export function userPresenceData(currentEmployeeId) {
    return {
        type: CHECKIN_CHECKOUT_GET_ALL_EMPLOYEES_CHECKIN,
        currentEmployeeId
    }
}

export function getSingleEmployeeCheckin(employee) {
    return {
        type: CHECKIN_CHECKOUT_GET_ALL_EMPLOYEES_CHECKIN_SUCCESS,
        employee
    }
}

export function getCurrentUserDataSuccess(currentStatus, parsedCheckin) {
    return {
        type: CHECKIN_CHECKOUT_GET_CURRENT_USER_DATA_SUCCESS,
        currentStatus,
        parsedCheckin
    }
}

export function getCurrentUserCheckins(parsedCheckinList) {
    return {
        type: CHECKIN_CHECKOUT_GET_CURRENT_USER_CHECKIN_LIST_SUCCESS,
        parsedCheckinList
    }
}