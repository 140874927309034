import { combineReducers } from 'redux';

const initialState = {
    selectedRow: null
};

const selectedRow = (state = initialState.selectedRow, action) => {
    switch(action.type) {
        case "PODCAMP_SETTINGS_EDIT_COMPONENT":
            return { id: action.id, row: action.row };
        case "POD_CONFIG_SELECT_FACILITY":
            return null;
        case "PODCAMP_SETTINGS_RESET_SELECT_ROW":
            return null;
        default:
            return state;
    }
};

const customTabBlock = combineReducers({
    selectedRow
});

export default customTabBlock;