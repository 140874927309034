import React from 'react';
import { connect } from 'react-redux';
import '@ant-design/compatible/assets/index.css';

//Libraries components
import { Form, Row, Col, Input, Button, Spin, Alert } from 'antd';

//Icons

//Style

//Actions
import { updateFormField } from './actions/updateFormField';
import { submitFormData } from './actions/submitFormData';
import { currentParams } from './actions/currentParams';

//Components

//Misc imports

class SettingsForm extends React.Component {
    componentDidMount() {
        const token = this.props.token;
        this.props.getCurrentParams(token);
    }

    handleFormSubmit(formData, token) {
        this.props.submitData(formData, token);
    }

    render() {
        const { tollerance, isStillLoading } = this.props;
        const formLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        };

        return (
            <React.Fragment>
                { isStillLoading === true ? 
                    <Spin tip="Loading..." className="rm-loader-management">
                        <Alert 
                            type="info"
                            className="rm-message-info"
                        />
                    </Spin> : 
                null }
                { isStillLoading === false ? 
                    <Form 
                        {...formLayout}
                        className='rm-form'
                        name='settings-form'
                        initialValues={{
                            tollerance: tollerance
                        }}
                        onFinish={ values => {
                            const formData = {
                                tollerance: this.props.tollerance
                            };
                            Object.keys(values).forEach((key, index) => {
                                return this.props.updateField(key, 
                                    values[Object.keys(values)[index]] === undefined || 
                                    values[Object.keys(values)[index]] === null ? '' : 
                                    values[Object.keys(values)[index]]);
                            });
                            this.handleFormSubmit(formData, this.props.token);                    
                        } }>
                        <Row
                            type="flex"
                            justify="start"
                            align="bottom"
                            gutter={ 20 }>
                            <Col
                                span={ 4 }
                                align="left">
                                <Form.Item label="Minuti di tolleranza"
                                name='tollerance'>
                                    <Input type='number' />
                                </Form.Item>
                            </Col>
                            <Col
                                span={4}
                                align="left">
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                    <span>Conferma</span>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form> :
                null }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return { 
        token: state.auth.token,
        tollerance: state.timeReportSettingsForm.tollerance,
        isStillLoading: state.timeReportSettingsForm.isStillLoading
    }
};

const mapDispatchToProps = dispatch => {
    const updateField = (key, field) => {
        dispatch(updateFormField(key, field));
    };

    const submitData = (formData, token) => {
        dispatch(submitFormData(formData, token));
    };

    const getCurrentParams = token => {
        dispatch(currentParams(token));
    }

    return {
        updateField,
        submitData,
        getCurrentParams
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsForm);