import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import { 
    Row, 
    Col, 
    Form, 
    Select, 
    Input, 
    Checkbox, 
    Radio, 
    Button,
    Popover,
    DatePicker,
    Popconfirm
} from 'antd';

import {
    QuestionCircleOutlined
} from '@ant-design/icons';

import moment from 'moment';

//Styles

//Actions
import { selectRow } from './actions/selectRow';
import { updateRow } from './actions/updateRow';
import { resetRowSelect } from './actions/resetRowSelect';
import { rowDelete } from './actions/rowDelete';

//Components
import DashboardTable from '../../../../utils/DashboardTable/index';

//Misc imports

class CustomTabForm extends React.Component {
    constructor(props) {
        super(props);

        this.checkboxChange = this.checkboxChange.bind(this);
        this.customValidation = this.customValidation.bind(this);
        this.radioChange = this.radioChange.bind(this);
        this.rowSelect = this.rowSelect.bind(this);
        this.updateSelectedRow = this.updateSelectedRow.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.addSelectedRow = this.addSelectedRow.bind(this);
        this.resetSelectRow = this.resetSelectRow.bind(this);
    }

    checkboxChange(checkedList) {
        console.log(checkedList);
    }

    radioChange(e) {
        console.log(e);
    }

    customValidation(rule, value) {

        const specialChars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if(rule.required && (value === null || value === undefined || value === '' || value === ' ')) return Promise.reject('Il campo è obbligatorio')
        if(rule.specialCharacter && specialChars.test(value)) return Promise.reject('I caratteri speciali non sono ammessi')
        if(rule.maxLength !== null && rule.maxLength !== undefined && value !== null && value !== undefined && value.length > rule.maxLength) return Promise.reject('Al massimo ' + rule.maxLength + ' caratteri')
        if(rule.minLength !== null && rule.minLength !== undefined && value !== null && value !== undefined && value.length < rule.minLength) return Promise.reject('Al minimo ' + rule.minLength + ' caratteri')
        if(rule.maxValue !== null && rule.maxValue !== undefined && value > rule.maxValue) return Promise.reject('Il valore non può essere maggiore di ' + rule.maxValue)
        if(rule.minValue !== null && rule.minValue !== undefined && value < rule.minValue) return Promise.reject('Il valore non può essere inferiore a ' + rule.minValue)
        return Promise.resolve();
    }

    rowSelect(id, targetRow) {
        const callerTab = this.props.tabName.toUpperCase().replace(/ /g, "_");
        this.props.handleEditFormShow(id, targetRow, callerTab);
    }

    updateSelectedRow(data) {
        this.props.rowUpdate(data.formData, data.token, data.apiKey)
    }

    handleDelete(row, facilityId, apiKey, token) {
        this.props.handleRowDelete(row.databaseId, facilityId, apiKey, token);
    }

    addSelectedRow(data) {
        this.props.rowUpdate(data.formData, data.token, data.apiKey)
    }

    resetSelectRow() {
        this.props.handleResetRowSelect();
    }

    render() {
        const { 
            formStructure, 
            formName, 
            tabName, 
            allTabs, 
            handleSave,
            blocks,
            selectedRow
        } = this.props;

        const formLayout = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24, align: 'left' }
        };
        
        let support = {};
        for(let i in formStructure.map(x => x.name)) {
            support[formStructure.map(x => x.fieldName)[i]] = formStructure[i].type === 'date' ?
                moment(formStructure[i].value, 'DD/MM/YYYY') : formStructure[i].value;
        }
        
        let actualField = null;

        /** Ricostruisco la struttura dei blocchi per il rendering */
        const parsedBlocks = blocks === null || blocks === undefined || blocks.length === 0 ?
            null :
            blocks.map(block => {
                return block.type === "simple-table" ? {
                    ...block,
                    columns:  block.data.find(x => x.needsEdit) ? [block.columns.map((col, index) => {
                        if(!col.hasFilter) {
                            delete col.filters
                        }
                        return col.hasFilter ? {
                            ...col,
                            width: index + 1 === block.columns.length ? '' : 200,
                            onFilter: (value, record) => {
                                return record[col.dataIndex].indexOf(value) === 0
                            },
                            onCell: index ? record => {
                                return {
                                    onClick: e => {
                                        this.rowSelect(record.key, record);
                                    }
                                }
                            } : null
                        } : {
                            ...col,
                            width: index + 1 === block.columns.length ? '' : 200,
                            onCell: index ? record => {
                                return {
                                    onClick: e => {
                                        this.rowSelect(record.key, record);
                                    }
                                }
                            } : null
                        };
                    }), block.data.find(x => x.needsDelete) ? {
                            title: '',
                            render: (text, record, index) => {
                                return (
                                    <Col
                                        span={ 24 }
                                        align="center">
                                        <Popconfirm 
                                            title="Sei sicuro di voler eliminare questa riga?"
                                            onConfirm={ () => this.handleDelete(record, this.props.facilityId, block.apiKeywordId, this.props.token) }
                                            okText="Si"
                                            cancelText="No">
                                            <Button type='danger'>Elimina</Button>
                                        </Popconfirm>
                                    </Col>
                                )
                            }
                        } : null].flat(1).filter(el => el !== null && el !== undefined) : [block.columns.map((col, index) => {
                        if(!col.hasFilter) {
                            delete col.filters
                        }
                        return col.hasFilter ? {
                            ...col,
                            width: index + 1 === block.columns.length ? '' : 200,
                            onFilter: (value, record) => {
                                return record[col.dataIndex].indexOf(value) === 0
                            }
                        } : {
                            ...col,
                            width: index + 1 === block.columns.length ? '' : 300
                        };
                    }), block.data.find(x => x.needsDelete) ? {
                            title: '',
                            render: (text, record, index) => {
                                return (
                                    <Col
                                        span={ 24 }
                                        align="center">
                                        <Popconfirm 
                                            title="Sei sicuro di voler eliminare questa riga?"
                                            onConfirm={ () => this.handleDelete(record, this.props.facilityId, block.apiKeywordId, this.props.token) }
                                            okText="Si"
                                            cancelText="No">
                                            <Button type='danger'>Elimina</Button>
                                        </Popconfirm>
                                    </Col>
                                )
                            }
                        } : null].flat(1).filter(el => el !== null && el !== undefined)
                } : block
            });
            
        const keys = 
            blocks !== null && 
            blocks !== undefined &&
            blocks.find(block => block.type === 'simple-table') !== null && 
            blocks.find(block => block.type === 'simple-table') !== undefined ?
            blocks.find(block => block.type === 'simple-table').editFields.map(field => {
                return field.fieldName
            }) : [];
        let parsedInitialValues = {};
        keys.map(key => {
            /**
             * 1° Check
             * Esistenza effetiva del campo
             * 2° Check
             * Il campo deve essere compreso nei blocchi
             * 3° Check
             * Il campo deve essere una data
             */
            parsedInitialValues[key] = selectedRow === null || 
                                        selectedRow === undefined ? null :
                                        blocks.find(x => x.editFields.length)
                                        .editFields.find(x => x.fieldName === key) === null || 
                                        blocks.find(x => x.editFields.length)
                                        .editFields.find(x => x.fieldName === key) === undefined ?
                                        selectedRow.row[key] : 
                                        blocks.find(x => x.editFields.length)
                                        .editFields.find(x => x.fieldName === key).type === 'date' ?
                                        moment(selectedRow.row[key], 'DD/MM/YYYY') :
                                        selectedRow.row[key];
            return true;
        });

        return parsedBlocks === null || parsedBlocks === undefined || parsedBlocks.length === 0 ? (
            <Form
                name={ formName }
                className='rm-form'
                { ...formLayout }
                initialValues={ support }
                onFinish={ values => {
                    const parsedFormData = allTabs.map(tab => {
                        return tab.nomeTab === tabName ? {
                            nomeTab: tab.nomeTab,
                            fields: [
                                ...Object.keys(values).map(key => {
                                    return {
                                        ...tab.fields.find(x => x.fieldName === key),
                                        fieldName: key,
                                        value: values[key]
                                    }
                                })]
                        } :null
                    }).filter(function (el) {
                        return el !== null && el !== undefined;
                    });

                    return handleSave(parsedFormData[0]);
                } }>
                <Row
                    type="flex"
                    justify="start"
                    gutter={ 20 }>
                    { formStructure.map((field, index) => {
                        if(field.type === 'text') {
                            actualField = <Input type="text" disabled={ field.isEditable ? false : true } />;
                        } else if(field.type === 'number') {
                            actualField = <Input type="number" disabled={ field.isEditable ? false : true } />;
                        } else if(field.type === 'select') {
                            actualField = <Select filterOption={ field.options && field.options.length > 10 ? 
                                (inputValue, option) => {
                                    //Necessario per estendere il supporto a quello schifo di ie, altrimenti sostituire indexOf con includes
                                    return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 }
                                : false }
                                showSearch={ field.options.length > 10 ? true : false }
                                optionFilterProp="children">
                                { field.options.map((option, index) => {
                                    return (
                                        <Select.Option key={ index } value={ option.value }>
                                            { option.label }
                                        </Select.Option>
                                    );
                                }) }
                            </Select>;
                        } else if(field.type === 'checkbox') {
                            actualField = <Checkbox.Group
                                options={ field.options }
                                onChange={ this.checkboxChange } />;
                        } else if(field.type === 'radio') {
                            actualField = <Radio.Group>
                                { field.options.map((option, index) => {
                                    return (
                                        <Radio value={ option.value } key={ index }>{ option.label }</Radio>
                                    );
                                }) }
                            </Radio.Group>;
                        } else if(field.type === 'password') {
                            actualField = <Input.Password />;
                        } else if(field.type === 'text-area') {
                            actualField = <Input.TextArea rows={ 4 } autoSize={{ minRows: 4, maxRows: 4 }} />;
                        } else if(field.type === 'date') {
                            actualField = <DatePicker
                                style={{ width: '100%' }}
                                format='DD/MM/YYYY'
                                allowClear={ false } />
                        }

                        return (
                            <Col
                                xl={ 8 }
                                lg={ 10 }
                                md={ 12 }
                                sm={ 24 }
                                xs={ 24 }
                                align="center"
                                key={ index }>
                                <Form.Item 
                                    /** Quello spazio vuoto è qualcosa di orrendo, andrebbe sostituito con qualcosa di più pratico */
                                    label={ 
                                        <React.Fragment>
                                            <div style={ { 'overflow': 'hidden', 'textOverflow': 'ellipsis', marginRight: '10px' } }>
                                                {field.label ? field.label : '        ' }
                                            </div>
                                            { field.description !== null && field.description !== undefined ? 
                                                <Popover
                                                    content={<span>{ field.description }</span>}
                                                    title="Help"
                                                    trigger="click">
                                                    <QuestionCircleOutlined />
                                                </Popover> : null }
                                        </React.Fragment> }
                                    name={ field.fieldName } 
                                    rules={
                                        field.rules ? 
                                        [{
                                            required: field.rules.find(x => x.rule_type === 'required') ? true : false,
                                            validator: this.customValidation,
                                            specialChar: field.rules.find(x => x.rule_type === 'special-char') ? true : false,
                                            maxLength: field.rules.find(x => x.rule_type === 'max-length') ? field.rules.find(x => x.rule_type === 'max-length').value : null,
                                            minLength: field.rules.find(x => x.rule_type === 'min-length') ? field.rules.find(x => x.rule_type === 'min-length').value : null,
                                            maxValue: field.rules.find(x => x.rule_type === 'max-value') ? field.rules.find(x => x.rule_type === 'max-value').value : null,
                                            minValue: field.rules.find(x => x.rule_type === 'min-value') ? field.rules.find(x => x.rule_type === 'min-value').value : null
                                        }]
                                    : [] }>
                                    { actualField }
                                </Form.Item>
                            </Col>
                        );
                    }) }
                </Row>
                <Row
                    type="flex"
                    justify="start"
                    align="middle"
                    gutter={ 20 }>
                    <Col
                        xl={ 6 }
                        lg={ 6 }
                        md={ 12 }
                        sm={ 12 }
                        xs={ 24 }
                        align='left'>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" span={ 24 }>
                                <span>Salva le informazioni per { tabName }</span>
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        ) : (
            <React.Fragment>
                { parsedBlocks.map((block, index) => {
                    return (
                        <React.Fragment key={ index }>
                            <Row
                                type='flex'
                                justify='start'
                                align='top'
                                gutter={ 20 }>
                                <Col
                                    span={ 16 }>
                                    { block.type === 'simple-table' ? (
                                        <DashboardTable
                                            data={ block.data.map((row, index) => {
                                                return { ...row, key: index } 
                                            }) }
                                            columns={ block.columns } />
                                    ) : null }
                                </Col>
                                { selectedRow !== null && selectedRow !== undefined ? (
                                    <Col
                                        span={ 8 }>
                                        <Form
                                            name={ blocks.type + "-edit-form-" + index }className='rm-form'
                                            { ...formLayout }
                                            onFinish={ values => {
                                                /** Detect se ci sono o meno date */
                                                let parsedSubmitData = {
                                                    formData: [...Object.keys(values).map(key => {
                                                            return moment.isMoment(values[key]) ? { [key]: values[key].format('DD-MM-YYYY') } : { [key]: values[key] }
                                                        })
                                                    ].reduce(
                                                        (obj, item) => Object.assign(obj, { [Object.keys(item)[0]]: item[Object.keys(item)[0]] }), {}),
                                                    apiKey: block.apiKeywordId,
                                                    token: this.props.token,
                                                };
                                                parsedSubmitData['formData'] = {
                                                    ...selectedRow.row,
                                                    ...parsedSubmitData.formData,
                                                    facilityId: selectedRow.row.facilityId
                                                }
                                                
                                                this.updateSelectedRow(parsedSubmitData);
                                            } }
                                            initialValues={ parsedInitialValues }>
                                            <Row
                                                type="flex"
                                                justify="start"
                                                gutter={ 20 }>
                                                { block.editFields.map((field, index) => {
                                                    if(field.type === 'text') {
                                                        actualField = <Input type="text" disabled={ field.isEditable ? false : true } />;
                                                    } else if(field.type === 'number') {
                                                        actualField = <Input type="number" disabled={ field.isEditable ? false : true } />;
                                                    } else if(field.type === 'select') {
                                                        actualField = <Select filterOption={ field.options && field.options.length > 10 ? 
                                                            (inputValue, option) => {
                                                                //Necessario per estendere il supporto a quello schifo di ie, altrimenti sostituire indexOf con includes
                                                                return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 }
                                                            : false }
                                                            showSearch={ field.options.length > 10 ? true : false }
                                                            optionFilterProp="children">
                                                            { field.options.map((option, index) => {
                                                                return (
                                                                    <Select.Option key={ index } value={ option.value }>
                                                                        { option.label }
                                                                    </Select.Option>
                                                                );
                                                            }) }
                                                        </Select>;
                                                    } else if(field.type === 'checkbox') {
                                                        actualField = <Checkbox.Group
                                                            options={ field.options }
                                                            onChange={ this.checkboxChange } />;
                                                    } else if(field.type === 'radio') {
                                                        actualField = <Radio.Group>
                                                            { field.options.map((option, index) => {
                                                                return (
                                                                    <Radio value={ option.value } key={ index }>{ option.label }</Radio>
                                                                );
                                                            }) }
                                                        </Radio.Group>;
                                                    } else if(field.type === 'password') {
                                                        actualField = <Input.Password />;
                                                    } else if(field.type === 'text-area') {
                                                        actualField = <Input.TextArea rows={ 4 } autoSize={{ minRows: 4, maxRows: 4 }} />;
                                                    } else if(field.type === 'date') {
                                                        actualField = <DatePicker
                                                            style={{ width: '100%' }}
                                                            format='DD/MM/YYYY'
                                                            allowClear={ false } />
                                                    }

                                                    return (
                                                        <Col
                                                            xl={ 16 }
                                                            lg={ 16 }
                                                            md={ 16 }
                                                            sm={ 24 }
                                                            xs={ 24 }
                                                            align="center"
                                                            key={ index }>
                                                            <Form.Item 
                                                                /** Quello spazio vuoto è qualcosa di orrendo, andrebbe sostituito con qualcosa di più pratico */
                                                                label={ 
                                                                    <React.Fragment>
                                                                        <div style={ { 'overflow': 'hidden', 'textOverflow': 'ellipsis', marginRight: '10px' } }>
                                                                            {field.label ? field.label : '        ' }
                                                                        </div>
                                                                        { field.description !== null && field.description !== undefined ? 
                                                                            <Popover
                                                                                content={<span>{ field.description }</span>}
                                                                                title="Help"
                                                                                trigger="click">
                                                                                <QuestionCircleOutlined />
                                                                            </Popover> : null }
                                                                    </React.Fragment> }
                                                                name={ field.fieldName } 
                                                                rules={
                                                                    field.rules ? 
                                                                    [{
                                                                        required: field.rules.find(x => x.rule_type === 'required') ? true : false,
                                                                        validator: this.customValidation,
                                                                        specialChar: field.rules.find(x => x.rule_type === 'special-char') ? true : false,
                                                                        maxLength: field.rules.find(x => x.rule_type === 'max-length') ? field.rules.find(x => x.rule_type === 'max-length').value : null,
                                                                        minLength: field.rules.find(x => x.rule_type === 'min-length') ? field.rules.find(x => x.rule_type === 'min-length').value : null,
                                                                        maxValue: field.rules.find(x => x.rule_type === 'max-value') ? field.rules.find(x => x.rule_type === 'max-value').value : null,
                                                                        minValue: field.rules.find(x => x.rule_type === 'min-value') ? field.rules.find(x => x.rule_type === 'min-value').value : null
                                                                    }]
                                                                : [] }>
                                                                { actualField }
                                                            </Form.Item>
                                                        </Col>
                                                    );
                                                }) }
                                            </Row>
                                            <Row
                                                type="flex"
                                                justify="start"
                                                gutter={ 20 }>
                                                <Col
                                                    xl={ 6 }
                                                    lg={ 6 }
                                                    md={ 12 }
                                                    sm={ 12 }
                                                    xs={ 24 }
                                                    align='left'>
                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit" span={ 24 }>
                                                            <span>Aggiorna</span>
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                                <Col
                                                    xl={ 6 }
                                                    lg={ 6 }
                                                    md={ 12 }
                                                    sm={ 12 }
                                                    xs={ 24 }
                                                    align='left'>
                                                    <Form.Item>
                                                        <Button type="danger" onClick={ () => this.resetSelectRow() } span={ 24 }>
                                                            <span>Annulla</span>
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                ) : null }
                            </Row>
                            { block.addFields !== null &&
                                block.addFields !== undefined &&
                                block.addFields.length ? (
                                <Row
                                    type='flex'
                                    justify='start'
                                    align='top'
                                    gutter={ 20 }>
                                    <Col
                                        span={ 12 }>
                                        <Form
                                            name={ blocks.type + "-add-form-" + index }className='rm-form'
                                            { ...formLayout }
                                            onFinish={ values => {
                                                /** Detect se ci sono o meno date */
                                                let parsedSubmitData = {
                                                    formData: [...Object.keys(values).map(key => {
                                                            return moment.isMoment(values[key]) ? { [key]: values[key].format('DD-MM-YYYY') } : { [key]: values[key] }
                                                        })
                                                    ].reduce(
                                                        (obj, item) => Object.assign(obj, { [Object.keys(item)[0]]: item[Object.keys(item)[0]] }), {}),
                                                    apiKey: block.apiKeywordId,
                                                    token: this.props.token,
                                                };
                                                parsedSubmitData['formData'] = {
                                                    ...parsedSubmitData.formData,
                                                    facilityId: block.addFields.find(x => x.fieldName === "facilityId") !== null && 
                                                        block.addFields.find(x => x.fieldName === "facilityId") !== undefined ?
                                                        block.addFields.find(x => x.fieldName === "facilityId").value :
                                                        null
                                                }
                                                
                                                this.addSelectedRow(parsedSubmitData);
                                            } }
                                            initialValues={ parsedInitialValues }>
                                            <Row
                                                type="flex"
                                                justify="start"
                                                gutter={ 20 }>
                                                { block.editFields.map((field, index) => {
                                                    if(field.type === 'text') {
                                                        actualField = <Input type="text" disabled={ field.isEditable ? false : true } />;
                                                    } else if(field.type === 'number') {
                                                        actualField = <Input type="number" disabled={ field.isEditable ? false : true } />;
                                                    } else if(field.type === 'select') {
                                                        actualField = <Select filterOption={ field.options && field.options.length > 10 ? 
                                                            (inputValue, option) => {
                                                                //Necessario per estendere il supporto a quello schifo di ie, altrimenti sostituire indexOf con includes
                                                                return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 }
                                                            : false }
                                                            showSearch={ field.options.length > 10 ? true : false }
                                                            optionFilterProp="children">
                                                            { field.options.map((option, index) => {
                                                                return (
                                                                    <Select.Option key={ index } value={ option.value }>
                                                                        { option.label }
                                                                    </Select.Option>
                                                                );
                                                            }) }
                                                        </Select>;
                                                    } else if(field.type === 'checkbox') {
                                                        actualField = <Checkbox.Group
                                                            options={ field.options }
                                                            onChange={ this.checkboxChange } />;
                                                    } else if(field.type === 'radio') {
                                                        actualField = <Radio.Group>
                                                            { field.options.map((option, index) => {
                                                                return (
                                                                    <Radio value={ option.value } key={ index }>{ option.label }</Radio>
                                                                );
                                                            }) }
                                                        </Radio.Group>;
                                                    } else if(field.type === 'password') {
                                                        actualField = <Input.Password />;
                                                    } else if(field.type === 'text-area') {
                                                        actualField = <Input.TextArea rows={ 4 } autoSize={{ minRows: 4, maxRows: 4 }} />;
                                                    } else if(field.type === 'date') {
                                                        actualField = <DatePicker
                                                            style={{ width: '100%' }}
                                                            format='DD/MM/YYYY'
                                                            allowClear={ false } />
                                                    }

                                                    return (
                                                        <Col
                                                            xl={ 7 }
                                                            lg={ 7 }
                                                            md={ 7 }
                                                            sm={ 24 }
                                                            xs={ 24 }
                                                            align="center"
                                                            key={ index }>
                                                            <Form.Item 
                                                                /** Quello spazio vuoto è qualcosa di orrendo, andrebbe sostituito con qualcosa di più pratico */
                                                                label={ 
                                                                    <React.Fragment>
                                                                        <div style={ { 'overflow': 'hidden', 'textOverflow': 'ellipsis', marginRight: '10px' } }>
                                                                            {field.label ? field.label : '        ' }
                                                                        </div>
                                                                        { field.description !== null && field.description !== undefined ? 
                                                                            <Popover
                                                                                content={<span>{ field.description }</span>}
                                                                                title="Help"
                                                                                trigger="click">
                                                                                <QuestionCircleOutlined />
                                                                            </Popover> : null }
                                                                    </React.Fragment> }
                                                                name={ field.fieldName } 
                                                                rules={
                                                                    field.rules ? 
                                                                    [{
                                                                        required: field.rules.find(x => x.rule_type === 'required') ? true : false,
                                                                        validator: this.customValidation,
                                                                        specialChar: field.rules.find(x => x.rule_type === 'special-char') ? true : false,
                                                                        maxLength: field.rules.find(x => x.rule_type === 'max-length') ? field.rules.find(x => x.rule_type === 'max-length').value : null,
                                                                        minLength: field.rules.find(x => x.rule_type === 'min-length') ? field.rules.find(x => x.rule_type === 'min-length').value : null,
                                                                        maxValue: field.rules.find(x => x.rule_type === 'max-value') ? field.rules.find(x => x.rule_type === 'max-value').value : null,
                                                                        minValue: field.rules.find(x => x.rule_type === 'min-value') ? field.rules.find(x => x.rule_type === 'min-value').value : null
                                                                    }]
                                                                : [] }>
                                                                { actualField }
                                                            </Form.Item>
                                                        </Col>
                                                    );
                                                }) }
                                            </Row>
                                            <Row
                                                type="flex"
                                                justify="start"
                                                gutter={ 20 }>
                                                <Col
                                                    xl={ 6 }
                                                    lg={ 6 }
                                                    md={ 12 }
                                                    sm={ 12 }
                                                    xs={ 24 }
                                                    align='left'>
                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit" span={ 24 }>
                                                            <span>Aggiungi</span>
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>)
                                : null }
                        </React.Fragment>
                    )
                }) }
            </React.Fragment>
        );
    };
}

const mapStateToProps = state => {
    return {
        selectedRow: state.customTabBlock.selectedRow,
        token: state.auth.token,
        facilityId: state.podConfig.selectedFacility
    };
}

const mapDispatchToProps = dispatch => {
    const handleEditFormShow = (id, row, callerTab) => {
        dispatch(selectRow(id, row, callerTab));
    };

    const rowUpdate = (formData, token, apiKey) => {
        dispatch(updateRow(formData, token, apiKey))
    };

    const handleResetRowSelect = () => {
        dispatch(resetRowSelect());
    };

    const handleRowDelete = (id, facilityId, apiKey, token) => {
        dispatch(rowDelete(id, facilityId, apiKey, token));
    };

    return {
        handleEditFormShow,
        rowUpdate,
        handleResetRowSelect,
        handleRowDelete
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomTabForm);