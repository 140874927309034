import React from 'react';
import { connect } from 'react-redux';

//Libraries components
import { Layout, Menu, Skeleton } from 'antd';

//Style
import './style.scss';

//Actions
import { unassignedIssues } from './actions/unassignedIssues';
import { handleChange } from './actions/changeDrawerState';
import { entrySelect } from './actions/entrySelect';
import { logout } from './actions/handleLogout';

//Components
import { menu } from './menuEntry/menu';
import logo from '../../assets/images/logo-small.png'
import ComponentSwitch from './components/ComponentSwitch/index';
import CheckInCheckOut from '../CheckInCheckOut/index';
import history from '../../history';

//Misc imports

class Home extends React.Component {
    constructor() {
        super();

        this.onCollapse = this.onCollapse.bind(this);
        this.selectedEntry = this.selectedEntry.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    componentDidMount() {
        if(!this.props.unassignedIssues.length && 
            (this.props.authorizations.includes('DCG Costi') || 
            this.props.authorizations.includes('DCG Controllo Gestione') || 
            this.props.authorizations.includes('DCG Presenze'))) {
            this.props.getUnassignedIssues(this.props.token);
        }
    }

    onCollapse() {
        this.props.drawerChangeState(this.props.drawerState);
    }

    selectedEntry(key, componentId) {
        if(componentId === 'logout') {
            localStorage.removeItem('rm-dash-token');
            this.props.handleLogout();
        } else {
            this.props.handleEntrySelect(key, componentId);
        }
    }

    changePage() {
        history.push('/home/issues-without-account');
    }

    render() {
        const { drawerState, unassignedIssues, authorizations } = this.props;
        const componentId = this.props.match && this.props.match.params && this.props.match.params.componentId ? this.props.match.params.componentId : '';
        const selectedEntry = menu.filter(x => x.componentId === componentId).map(x => x.key);
        let issueCount = 0;
        if(unassignedIssues.length) {
            issueCount = unassignedIssues.reduce((a, b) => ({ 'count': parseInt(a.count, 10) + parseInt(b.count, 10) }) )
        }

        return (
            <Layout className='rm-layout'>
                <Layout.Sider collapsible collapsed={ drawerState } onCollapse={ this.onCollapse }>
                    <div className='rm-logo'>
                        <img alt='logo-remedia' src={ logo } className='rm-logo-small' onClick={ () => { history.push('/home') } } />
                    </div>
                    <Menu theme='dark' mode='inline' selectedKeys={['' + selectedEntry[0] + '']}>
                        { menu.map((element, index) => {
                            if(element.authLevel[0] === 'DCG All') {
                                return (
                                    <Menu.Item key={ index } onClick={ () => { this.selectedEntry(index, element.componentId) } }>
                                        { element.icon }<span>{ element.label }</span>
                                    </Menu.Item>
                                );
                            }
                            if(element.policy === '' || element.policy === 'OR') {
                                return element.authLevel.some(x => { return authorizations.includes(x); }) ? (
                                    <Menu.Item key={ index } onClick={ () => { this.selectedEntry(index, element.componentId) } }>
                                        { element.icon }<span>{ element.label }</span>
                                    </Menu.Item> ) : null;
                            }
                            if(element.policy === 'AND') {
                                return element.authLevel.every(x => { return authorizations.includes(x); }) ? (
                                    <Menu.Item key={ index } onClick={ () => { this.selectedEntry(index, element.componentId) } }>
                                        { element.icon }<span>{ element.label }</span>
                                    </Menu.Item> ) : null;
                            }
                        }) }
                    </Menu>
                </Layout.Sider>
                <Layout>{ unassignedIssues.length && componentId !== 'issues-without-account' && (authorizations.includes('DCG Costi') || authorizations.includes('DCG Controllo Gestione') || authorizations.includes('DCG Presenze')) ?
                    <Layout.Header className='rm-issue-banner' onClick={ () => this.changePage() }>
                        <span>Ci sono ancora { issueCount.count } issue senza un Account assegnato, divise in { unassignedIssues.length } progetti</span>
                    </Layout.Header> : null }
                    { !unassignedIssues.length && componentId !== 'issues-without-account' ?                    
                    <Layout.Header style={{ background: '#001529' }}>
                    </Layout.Header> : null }
                    { componentId === 'issues-without-account' ?
                    <Layout.Header style={{ background: '#001529' }}>
                    </Layout.Header> :
                    null }
                    <Layout.Content className='rm-layout-content'>
                        <div className='child'>
                            { componentId !== '' &&
                                <ComponentSwitch componentId={ componentId } />
                            }
                            { componentId === '' &&
                                // <Skeleton avatar paragraph={{ rows: 20 }} />
                                <CheckInCheckOut />
                            }
                        </div>
                    </Layout.Content>
                </Layout>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        drawerState: state.home.drawerState,
        selectedEntry: state.home.selectedEntry,
        token: state.auth.token,
        authorizations: state.auth.userInfo.authorizations ? state.auth.userInfo.authorizations : [],
        unassignedIssues: state.home.unassignedIssues
    }
};

const mapDispatchToProps = dispatch => {
    const getUnassignedIssues = token => {
        dispatch(unassignedIssues(token));
    };

    const drawerChangeState = currentState => {
        dispatch(handleChange(!currentState));
    };

    const handleEntrySelect = (key, componentId) => {
        dispatch(entrySelect(key, componentId));
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    return {
        getUnassignedIssues,
        drawerChangeState,
        handleEntrySelect,
        handleLogout
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);