import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import moment from 'moment';

//Components
import { errorHandling } from '../../../../../../../utils/Errors/actions/index';

//Success functions
import { getClientsSuccess } from '../actions/loadInitialData';
import { formSubmitSuccess, formSubmitSuccessNoGrouping } from '../../../actions/formSubmitSuccess';

//Actions
import {
    MANAGEMENT_FILTER_GET_CLIENTS,
    MANAGEMENT_FILTER_FORM_SUBMIT
} from '../actions/actionTypes';
import { toast } from 'react-toastify';

export const getClients = (action$, state$) =>
    action$.pipe(
        ofType(MANAGEMENT_FILTER_GET_CLIENTS),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/customer/`;

            const users = ajax.getJSON(apiUrl, { 'Authorization': 'Bearer ' + action.token })
                .pipe(
                    map(data => {
                        const parsedData = [...data.map(x => {
                            return {
                                label: x.name,
                                value: x.jiraKey,
                                key: x.id
                            };
                        })].sort((a, b) => {
                            if (a.label < b.label)
                                return -1;
                            if ( a.label > b.label)
                                return 1;
                            return 0;
                        });
                        console.log(parsedData);
                        return getClientsSuccess(parsedData);
                    }),
                    catchError(error => { 
                        toast.error("Impossibile recuperare i customer");
                        return of(errorHandling(error)) 
                    })
                );

            return users;
        })
    );

export const formSubmit = (action$, state$) =>
    action$.pipe(
        ofType(MANAGEMENT_FILTER_FORM_SUBMIT),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/cost?group=${ true }&customerKey=${ action.formData.clientJiraKey }&status=${ action.formData.status }&dateFrom=${ action.formData.dateFrom }&dateTo=${ action.formData.dateTo }`;

            const records = ajax.getJSON(apiUrl, {
                    'Authorization': 'Bearer ' + action.token
                }).pipe(
                    map(data => {
                        const parsedData = data.map((x, index) => {
                            return {
                                key: index,
                                customerName: x.customer.name,
                                leadName: x.lead.fullName,
                                percentageCost: {
                                    cost: x.issueCost + x.accountCost,
                                    parsedCost: (x.issueCost + x.accountCost).toFixed(2).toString().replace('.', ','),
                                    // percentage: x.budget > 0 ? (((x.issueCost + x.accountCost) / x.budget) * 100).toFixed(2).toString().replace('.', ',') : null,
                                    percentage: x.budget > 0 ? (Math.floor((x.issueCost || 0) / x.budget * 10000) / 100).toString().replace('.', ',') : null,
                                    budget: x.budget
                                },
                                accountTitle: x.jiraKey + ' -- ' + x.account,
                                parsedIssueCost: x.issueCost.toString().replace('.', ','),
                                ...x
                            }
                        });
                        return formSubmitSuccess(parsedData);
                    }),
                    catchError(error => { 
                        toast.error("Errore interno");
                        return of(errorHandling(error)) })
                );

            return records;
        })
    );

export const formSubmitNoGrouping = (action$, state$) =>
    action$.pipe(
        ofType(MANAGEMENT_FILTER_FORM_SUBMIT),
        mergeMap(action => {
            const apiUrl = `${ state$.value.apiUrl.services }/cost?group=${ false }&customerKey=${ action.formData.clientJiraKey }&status=${ action.formData.status }&dateFrom=${ action.formData.dateFrom }&dateTo=${ action.formData.dateTo }`;

            const records = ajax.getJSON(apiUrl, {
                    'Authorization': 'Bearer ' + action.token
                }).pipe(
                    map(data => {
                        const parsedData = {
                            actualCsv: window.URL.createObjectURL(
                                new Blob(
                                    ["\"Customer\";\"Account\";\"Account key\";\"Account status\";\"Responsabile\";\"Budget\";\"Issue key\";\"Issue\";\"User\";\"Data\";\"Ore\";\"Costo\"\n"
                                    + [...data.map(row => {
                                        return "\"" + row.issue.account.customer.name + "\";"
                                            + "\"" + row.issue.account.name + "\";"
                                            + "\"" + row.issue.account.jiraKey + "\";"
                                            + "\"" + row.issue.account.status + "\";"
                                            + "\"" + row.issue.account.lead.fullName + "\";"
                                            + "\"" + row.issue.account.budget + "\";"
                                            + "\"" + row.issue.jiraKey + "\";"
                                            + "\"" + row.issue.summary + "\";"
                                            + "\"" + row.user.fullName + "\";"
                                            + "\"" + moment(row.date).format('DD-MM-YY') + "\";"
                                            /** In caso si voglia tornare alla dicitura decimale, scommentare questo */
                                            + "\"" + (row.hours || 0).toString().replace(".", ",") + "\";"
                                            /** RESTITUISCE IL CALCOLO DEI MINUTI TOTALI, VIENE TRONCATO IL DECIMALE */
                                            // (Number(parseInt(row.hours)) * 60 + Number((row.hours % 1).toFixed(2)) * 60).toFixed(),
                                            /** In caso si voglia tornare alla dicitura col punto anzichè la virgola, scommentare */
                                            // row.cost
                                            + "\"" + (row.cost || 0).toString().replace(".", ",") + "\"\n"
                                        })
                                    ].join('').normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                ], { type: "text/csv;charset=utf-8" })),
                            csvData: [...data.map(row => {
                                return {
                                    customer: row.issue.account.customer.name,
                                    account: row.issue.account.name,
                                    accountKey: row.issue.account.jiraKey,
                                    accountStatus: row.issue.account.status,
                                    budget: row.issue.account.budget,
                                    issueKey: row.issue.jiraKey,
                                    issue: row.issue.summary,
                                    user: row.user.fullName,
                                    date: moment(row.date).format('DD-MM-YY'),
                                    /** In caso si voglia tornare alla dicitura decimale, scommentare questo */
                                    hours: row.hours,
                                    /** RESTITUISCE IL CALCOLO DEI MINUTI TOTALI, VIENE TRONCATO IL DECIMALE */
                                    // hours: (Number(parseInt(row.hours)) * 60 + Number((row.hours % 1).toFixed(2)) * 60).toFixed(),
                                    /** In caso si voglia tornare alla dicitura col punto anzichè la virgola, scommentare */
                                    // cost: row.cost
                                    cost: row.cost,
                                    lead: row.issue.account.lead.fullName
                                } })]};
                        return formSubmitSuccessNoGrouping(parsedData);
                    }),
                    catchError(error => { 
                        toast.error("Errore interno");
                        return of(errorHandling(error)) })
                );

            return records;
        })
    );

export default combineEpics(
    getClients,
    formSubmit,
    formSubmitNoGrouping
);