import {
    MY_DIARY_GET_DIARY
} from './actionTypes';

export function getDiary(token, dateFrom, dateTo) {
    return {
        type: MY_DIARY_GET_DIARY,
        token,
        dateFrom,
        dateTo
    }
}