import {
    COST_CALC_GET_RATINGS,
    COST_CALC_GET_RATINGS_SUCCESS
} from './actionTypes';

export function fetchRatings(token) {
    return {
        type: COST_CALC_GET_RATINGS,
        token
    };
}

export function fetchRatingsSuccess(data) {
    return {
        type: COST_CALC_GET_RATINGS_SUCCESS,
        data
    };
}