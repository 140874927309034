import {
    DEFAULT_ERROR,
    LOGIN_DEFAULT_ERROR
} from './actionTypes';

export function errorHandling(error) {
    return {
        type: DEFAULT_ERROR,
        error: error.message
    }
}

export function loginErrorHandling(error) {
    return {
        type: LOGIN_DEFAULT_ERROR,
        error: error.message
    }
}